import React from "react";
import { Divider } from "antd";
import { IconDetailRArrow } from "../../images/indexSvg"
import { SeparateParagraph } from "../../utilities/utilities";

const CollectionArtistMobile = (props) => {
    const {
      artist = {}
    } = props;
  return (
    <>
      <div className="collections_divider lh100"></div>
      <div className="collections_artist artist_info_mobile">
        <div className="artist_view_info">
          <h1 className="m_t">{artist.name}</h1>
          <div className="info_left">
            <div className="info_img">
              <img src={artist.content.imageUrls[0].coverImageNormal} alt={artist.content.name} />
            </div>
            <div className="info_name">{artist.content.name}</div>
          </div>
          <div className="info_right">{SeparateParagraph(artist.content.biography)}</div>
          <Divider />
          <div className="info_link">
              <a href={`http://${window.location.host}${artist.content.uri}`}>
              <span className="text">More about the Artist</span>
              <span className="icon">
                <IconDetailRArrow />
              </span>
            </a>
              <a href={`http://${window.location.host}${artist.uriFromArtist}`}>
              <span className="text">{artist.seeAllFromArtist}</span>
              <span className="icon">
                <IconDetailRArrow />
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
};
export default CollectionArtistMobile;
