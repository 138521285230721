import React, { useState } from "react";
import VrPopupDesktop from "./VrPopupDesktop";
import { IconPlayDesktop } from "../Icons";

const VrDialogDesktop = (props) => {
  const { linkVr } = props;
  const [showPop, setShowPop] = useState(false);

  const closePopup = () => {
    setShowPop(false);
  };
  const showPopup = () => {
    setShowPop(true);
  };

  return (
    <React.Fragment>
      <VrPopupDesktop
        linkVr={linkVr}
        showPop={showPop}
        closePopup={closePopup}
      />
      <div className="VrDialog">
        <div className="vr_c">
          <div
            className="vr_left"
            onClick={(e) => {
              showPopup();
            }}
          >
            <IconPlayDesktop />
          </div>
          <div className="vr_right">
            <h1>Take a virtual tour</h1>
            <p>Introduction to the VR technoloy</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VrDialogDesktop;
