import React, { useState, Fragment } from "react";
import DropDownMenu from "./DropDownMenu_Ver2"
import MobileFilter from "../MobileFilter_Ver2";
import { IconArtDownArrow } from "../../images/indexSvg"
import "./Styled/styled_option.css";

const FilterView = (props) => {
  const {
    info = [],
    marked = [],
    setMarked = () => {},
    handleReset = () => {},
    handleConfirm = () => {},
    preTitleArtist = 'All'
  } = props;
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false)
  }
  const handleOpen = () => {
    setVisible(true)
  }

  const handleResetFilter = () => {
    handleReset();
  }

  const handleConfirmFilter = () => {
    handleConfirm();
  }

  const setMarkedFilter = (value, isMarked) => {
    setMarked(value, isMarked);
  }

  return (
    <Fragment>
      <div className="Large_Screen_Frame">
        <div className="filter_select">
          <DropDownMenu
            info={info.contents}
            preTitleArtist={preTitleArtist}
            marked={marked}
            setMarked={setMarkedFilter}
            handleReset={handleResetFilter}
            handleConfirm={handleConfirmFilter}
          />
        </div>
      </div>
      <div className="Small_Screen_Frame">
        <div className="mobile_filter_btn" onClick={() => handleOpen()}>
          <span>{preTitleArtist} {info.name}</span>
          <IconArtDownArrow />
        </div>
      </div>
      <MobileFilter
        info={info} 
        visible={visible}
        handleClose={handleClose}
        marked={marked}
        setMarked={setMarkedFilter}
        handleReset={handleResetFilter}
        handleConfirm={handleConfirmFilter} 
      />
    </Fragment>
  )

}

export default FilterView;