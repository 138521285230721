import React from "react";
import { Checkbox, Row, Col } from "antd";
import _get from "lodash/get";

const NameOptions = ({ items = [], markedItems = [], setMarkedItems = () => { }, }) => {
  const handleOnClick = (e) => {
    const checked = _get(e, "target.checked", "NULL");
    const checkedValue = _get(e, "target.value", "NULL");
    if (checked) {
      setMarkedItems([...markedItems, checkedValue]);
    } else {
      const checkedValues = markedItems.filter((item) => item !== checkedValue);
      setMarkedItems(checkedValues);
    }
  };

  // const getChildren = (id, name, checked) => {
  //   return <Col className="c_StyledCol_ver" key={id} span={8}>
  //     {/* 一列选项里的一行 */}
  //     <Row className="c_StyledRow_ver" span={24}>
  //       <Col span={3}>
  //         <Checkbox
  //           value={name}
  //           onClick={handleOnClick}
  //           checked={checked}
  //           style={{ textAlign: "left" }}
  //         />
  //       </Col>
  //       <Col span={21}>
  //         <span className="c_StyledText_span_ver">{name}</span>
  //       </Col>
  //     </Row>
  //   </Col>
  // }

  // const result = [];
  // for (var i = 0; i < items.length; i += 3) {
  //   result.push(items.slice(i, i + 3));
  // }

  // return result.map((items, key) => {
  //   return (
  //     <Row key={key}>
  //       {
  //         items.map((name, id) => {
  //           let checked = markedItems.reduce((isMarked, markedItem) => {
  //             if (!isMarked) {
  //               return name === markedItem;
  //             } else return isMarked;
  //           }, false);
  //           return getChildren(id, name, checked)
  //         })
  //       }
  //     </Row>
  //   )
  // })

  return items.map((name, id) => {
    let checked = markedItems.reduce((isMarked, markedItem) => {
      if (!isMarked) {
        return name === markedItem;
      } else return isMarked;
    }, false);

    return (
      // 选项面板的一列选项
      <Col className="c_StyledCol_ver" key={id} xs={24} sm={24} lg={8} xl={8}>
        {/* 一列选项里的一行 */}
        <Row className="c_StyledRow_ver" span={24}>
          <Col span={3}>
            <Checkbox
              value={name}
              onClick={handleOnClick}
              checked={checked}
              style={{ textAlign: "left" }}
            />
          </Col>
          <Col span={21}>
            <span className="c_StyledText_span_ver">{name}</span>
          </Col>
        </Row>
      </Col>
    );
  });
};

export default NameOptions;
