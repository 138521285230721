/**
 *
 * @param {*} array
 * @param {*} s 三维数组
 */
// function solution(array, s, n, sum) {
//   for (let i = 0; i < sum / 2 + 1; i++) {
//     s[0][0][i] = 0;
//     s[1][0][i] = 0;
//   }
//   for (let i = 0; i < n / 2 + 1; i++) {
//     s[0][i][0] = 0;
//     s[1][i][0] = 0;
//   }
//   for (let i = 1; i < n + 1; i++) {
//     let maxj = Math.min(i, n / 2);
//     for (let j = 1; j <= maxj; j++) {
//       for (let k = 1; k < sum / 2 + 1; k++) {
//         let skipi = s[(i - 1) % 2][j][k];
//         let takei = k < array[i - 1] ? 0 :
//           s[(i - 1) % 2][j - 1][k - array[i - 1]] + array[i - 1];
//         s[i % 2][j][k] = Math.max(takei, skipi);
//       }
//     }
//   }
// }

export const getUUID = (len = 8, radix = 2) => {
  var chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  var uuid = [],
    i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join("");
};

const columns4 = (array, index) => {
  // wpr wprl wprl wpl
  if (index === 0) {
    array.push("wp4_r");
  } else if (index === 1) {
    array.push("wp4_c1");
  } else if (index === 2) {
    array.push("wp4_c2");
  } else if (index === 3) {
    array.push("wp4_l");
  }
  return array
}
const columns3 = (array, index) => {
  // wpr wprl wpl
  if (index === 0) {
    array.push("wp3_r");
  } else if (index === 1) {
    array.push("wp3_c1");
  } else if (index === 2) {
    array.push("wp3_l");
  }
  return array
}

/**
 * List
 * @param {*} array
 * @param {*} index
 * @param {*} size
 * @returns
 */
export const setWapperStyle = (array, index, size) => {
  if (size === 4) {
    return columns4(array, index)
  }
  //
  else if (size === 3) {
    return columns3(array, index)
  }
  //
  else if (size === 2) {
    if (index === 0) {
      array.push("wt50 wpr");
    } else if (index === 1) {
      array.push("wt50 wpl");
    }
  } else {
    if (index === 0) {
      array.push("wt100");
    }
  }
  return array;
};

/**
 *
 * @param {*} start
 * @param {*} end
 * @returns
 */
export const numberToMonth = (start, end) => {
  const [sYear, sMonth, sDate] = start.split("-");
  const [eYear, eMonth, eDate] = end.split("-");
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    sYear
  ];

  const mouthText1 = months[sMonth - 1];
  const mouthText2 = months[eMonth - 1];
  return `${mouthText1} ${sDate} - ${mouthText2} ${eDate}, ${eYear}`;
};

/**
 * 获取容器的Rect
 * @param {*} obj 
 * @returns 
 */
export function getElementRect(element, isTrust = false) {
  if ("string" == typeof element) {
    element = document.getElementById(element);
  }
  if (!element && isTrust) return null;
  if (!element) return {};
  var docH = document.documentElement.clientHeight || document.body.clientHeight;
  var ro = element.getBoundingClientRect();
  //document.documentElement.clientTop 在IE67中始终为2，其他高级点的浏览器为0
  var top = ro.top - document.documentElement.clientTop;
  // ro.top = top
  ro.topC = top;
  //document.documentElement.clientLeft 在IE67中始终为2，其他高级点的浏览器为0
  // ro.left = ro.left - document.documentElement.clientLeft;
  ro.Width = ro.width || ro.right - ro.left;
  ro.Height = 0;
  if (ro.height || ro.bottom - ro.top) {
    ro.Height = ro.height || ro.bottom - ro.top
  }
  ro.docH = docH;
  if (!ro.originalTop) {
    ro.originalTop = top;
  }
  return ro;
}

/**
 * 获取可视区域高度
 * @returns 
 */
export function getClientHeight() {
  var clientHeight = 0;
  const _clientHeight = document.body.clientHeight;
  const _elementHeight = document.documentElement.clientHeight;
  if (_clientHeight && _elementHeight) {
    clientHeight =
      _clientHeight < _elementHeight ? _clientHeight : _elementHeight;
  } else {
    clientHeight =
      _clientHeight > _elementHeight ? _clientHeight : _elementHeight;
  }
  return clientHeight;
}