import React from "react";
import { DownloadImage, DownloadFiles } from "../../components/DownloadImage_Ver2";
import { DownLoadIconV2 } from "../../components/Icons";


const ArtDownload = (props) => {
  const {
    name,
    images = [],
    files = []
  } = props;
  const imageUrls = images.map(item => item.imageUrls[0].imageNormal);
  const downloadImage = () => {
    DownloadImage(imageUrls, name);
  };

  const downloadFile = () => {
    DownloadFiles(files, name);
  };

  return (
    <div className="D_download">
      <div className="D_cv" onClick={downloadFile}>
        <DownLoadIconV2 type="vertical-align-bottom" />
        <span>Artist Biography/ CV</span>
      </div>
      <div className="D_images" onClick={downloadImage}>
        <DownLoadIconV2 type="vertical-align-bottom" />
        <span>All Images</span>
      </div>
    </div>
  )
}

export default ArtDownload;
