import React, { useEffect, useState } from "react";
import PressesCategory from "../../views_components/Presses/Category_Ver2";
import Axios from "axios";
// import { StyledLoadMore, StyledLoadMoreButton} from "../../views_components/LoadMoreButton_Ver2";

const Presses = (props) => {
  const {
    location = {},
  } = props;
  let searchedArtistId = undefined;
  let searchedExhibitionId = undefined;
  if (location.search.split('=')[0] === '?artistId') {
    searchedArtistId = location.search.split('=')[1];
  } else if (location.search.split('=')[0] === '?exhibitionId') {
    searchedExhibitionId = location.search.split('=')[1];
  }

  const [Presses, setPresses] = useState([]);

  const numberOfItem = 24;
  const [Page, setPage] = useState(0);
  const [LoadMore, setLoadMore] = useState(true);

  // 懒加载
  const toggleLoadMore = () => {
    var scrollTop = document.documentElement.scrollTop;
    var clientHeight = document.documentElement.clientHeight;
    var scrollHeight = document.documentElement.scrollHeight;
    if (LoadMore) {
      if(scrollTop+clientHeight >= scrollHeight){
        // 异步操作
        setTimeout(setPage(Page+1),0);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleLoadMore);
    return () => {
      window.removeEventListener("scroll", toggleLoadMore);
    }
  }, [Page]);
  // 数据请求
  const getPressFromArtist = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebPresses",
      {
        artistId: searchedArtistId,
        page: Page
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }
  const getPressFromExhibition = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebPresses",
      {
        exhibitionId: searchedExhibitionId,
        page: Page
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }
  const getPresses = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebPresses", 
      {
        page: Page
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }
  if (searchedArtistId !== undefined) {
    useEffect(() => {
      getPressFromArtist().then(result => {
        if (result.data.result.presses.contents.length !== 0) {
          let tempPresses = [...Presses];
          result.data.result.presses.contents.forEach(item => {
            tempPresses.push(item);
          });
          setPresses(tempPresses);
          if (result.data.result.presses.contents.length < numberOfItem) {
            setLoadMore(false);
          }
        } else {
          setLoadMore(false);
        }
      })
    }, [Page])
  } else if (searchedExhibitionId !== undefined) {
    useEffect(() => {
      getPressFromExhibition().then(result => {
        if (result.data.result.presses.contents.length !== 0) {
          let tempPresses = [...Presses];
          result.data.result.presses.contents.forEach(item => {
            tempPresses.push(item);
          });
          setPresses(tempPresses);
          if (result.data.result.presses.contents.length < numberOfItem) {
            setLoadMore(false);
          }
        } else {
          setLoadMore(false);
        }
      })
    }, [Page])
  } else {
    useEffect(() => {
      getPresses().then(result => {
        if (result.data.result.presses.contents.length !== 0) {
          let tempPresses = [...Presses];
          result.data.result.presses.contents.forEach(item => {
            tempPresses.push(item);
          });
          setPresses(tempPresses);
          if (result.data.result.presses.contents.length < numberOfItem) {
            setLoadMore(false);
          }
        } else {
          setLoadMore(false);
        }
      });
    }, [Page])
  }

  
  return (
    <div>
      <PressesCategory
        presses={Presses}
      />
      {/* {
        LoadMore &&
          <StyledLoadMoreButton
            onClick={() => setPage(Page + 1)}
          >
            <StyledLoadMore>LOAD MORE</StyledLoadMore>
          </StyledLoadMoreButton>
      } */}
    </div>
  )
};

export default Presses;
