import React from "react";
import CreateVideoBody from "../CreateYoutube/index";

const WorkDetailVideo = (props) => {
  const {
    artwork = {}
  } = props;
  let youtubeLink = artwork.linkVideo;
  const youtubeLinkArr = (youtubeLink && youtubeLink.split(" ")) || [];
  
  const videArr = [];
  youtubeLinkArr.forEach(item => {
    const realLink = item.replace("watch?", "embed/") || "";
    videArr.push(realLink)
  })

  const isSize = videArr.length;
  const title = `Video${isSize > 1 ? "s" : ""}`
  
  return (
    <React.Fragment>
      {isSize > 0 && (
        <div className="wm_Video">
          <div className="title">{title}</div>
          <CreateVideoBody videList={youtubeLinkArr} />
        </div>
      )}
    </React.Fragment>
  );
};

export default WorkDetailVideo;
