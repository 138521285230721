import React from "react";
import CategoryMondrianRows from "./CategoryMondrianRows_Ver2";
import ScrollToTopBtn from "../ScrollToTopBtn";

const PressesCategory = (props) => {
  const {
    presses=[]
  } = props;

  return (
    <div className="c_page">
      <div className="header_box">
        <div className="common_title">Press</div>
      </div>
      <CategoryMondrianRows presses={presses} />
      <ScrollToTopBtn />
    </div>
  );
};

export default PressesCategory;
