import React from "react";
import { DownLoadIconV2 } from "../../components/Icons";
import { PrintPDF } from "../../components/PrintPDF";
import { DownloadImage } from "../../components/DownloadImage";
import { forEach } from "lodash";

const ExhibitionInfo = (props) => {
  const {
    collection = [],
    images = {}
  } = props;
  const collectionImageUrls = [];
  forEach(images, function(image) {
    collectionImageUrls.push(image.imageUrls[0].imageNormal);
  });
  const handleClick = () => {
    DownloadImage(collectionImageUrls, collection.name);
  };
  return (
    <div className="c_DetailWrap_pc">
      <div className="group c_d_name_pc">
        <div className="pc_subTitle">{collection.artistName}</div>
        <div className="pc_title">{collection.name}</div>
      </div>
      <div className="group pc_Materials">
        <div className="view_content">
          <div className="c__content">
            <span className="text">Materials</span>
          </div>
        </div>
        <div className="view_content">
          <div className="c__content">
            <span className="text">{collection.material}</span>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="c__download">
          <div className={` pc__download`} onClick={PrintPDF}>
            <span className="download_icon">
              <DownLoadIconV2 />
            </span>
            <span className="download_text">Press Release (PDF)</span>
          </div>
          <div className={` pc__download`} onClick={handleClick}>
            <span className="download_icon">
              <DownLoadIconV2 />
            </span>
            <span className="download_text">All Images</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionInfo;
