import React, { useState, useEffect } from "react";
import Masonry from "react-masonry-component";
import { LazyImg } from "../../components/LazyImg";
import "../Version2/CategoryMondrian.css";
import "../Version2/Wrapper.css";

import { WhiteArrowSvg } from "../../images/indexSvg";
import useObserver from "../ResizeObserver";
import { setWapperStyle } from "../../utilities/utils";
import { toUrl } from "../../utilities/utilities";

function getColNum(w) {
  if (w >= 1280) return 4;
  if (w >= 1024) return 3;
  if (w >= 744) return 2;
  return 1;
}

const CategoryMondrian = (props) => {
  const {
    collections = [],
    realWidth = false
  } = props;
  const [size, SetSize] = useState(0);
  const measuredRef = React.useRef(null);

  useObserver({
    element: measuredRef,
    callback: (element) => {
      const target = element[0].target;
      const offsetWidth = target.offsetWidth;
      let cn = getColNum(offsetWidth);
      if (realWidth) {
        cn = 2
      }
      SetSize(cn);
    },
  });

  useEffect(() => {
    return () => {};
  }, [size]);

  const renderCards = () => {
    let output = [];
    collections.forEach((item) => {
      let ui = (
        <div key={item.id} className="column_item">
          <a href={toUrl(item.uri)}>
            {item.imageUrls[0]?
            <LazyImg src={item.imageUrls[0].coverImageNormal} key={item.id} alt={item.name} />:
            ''
            }
            <div className="popup_group">
              <div className="t_body">
                <div className="t_author">{item.artistName}</div>
                <div className="t_title">{item.name}</div>
                <div className="t_icon"><WhiteArrowSvg /></div>
              </div>
            </div>
          </a>
        </div>
      );

      output.push(ui);
    });
    return output;
  };

  const renderColumns = (nums, artists) => {
    const columns = [];
    for (let i = 0; i < nums; i++) {
      columns.push([]);
    }

    artists.map((item, id) => {
      const current = (id + nums) % nums;
      columns[current].push(item);
      return "finished";
    });

    const columnsSize = columns.length;

    return columns.map((column, id) => {
      let array = ["common_column"];
      array = setWapperStyle(array, id, columnsSize);
      const _className = array.join(" ");

      return (
        <div className={_className} key={id}>
          {column}
        </div>
      );
    });
  };

  const masonryOptions = {
    itemSelector: ".never",
    columnWidth: ".never",
    isOriginLeft: true,
  };

  return (
    <div className="common_wrapper collections_wrapper" ref={measuredRef}>
      <Masonry className="c_Masonry" options={masonryOptions}>
        {size > 0 && renderColumns(size, renderCards())}
      </Masonry>
    </div>
  );
};

export default CategoryMondrian;
