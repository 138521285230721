import React from "react";
import { Col, Row } from "antd";
import { CollectionWorksArt } from "../Enum"
import "../../../scss/HomeNav.css";
import { toUrl } from "../../../utilities/utilities";

function getImgAndLink(item) {
  return (
    <a key={item.id} href={toUrl(item.uri)} className="collocation-link">
      <div className="collection_group">
        <div className="collection_group_image">
          <img src={item.imageUrls[0].coverImageNormal} alt={item.name} />
        </div>
        <div className="collection_name_group">
          <div className="pub_name name1">{item.artistsName}</div>
          <div className="pub_name name2">{item.name}</div>
          {/* 向左箭头 */}
          <div className="pub_name name3">
            <span>
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.00009 7.89452C0.452987 7.89727 0.00612136 7.50745 0 7.02211C0.00612136 6.53677 0.452987 6.14696 1.00009 6.14971L12.4922 6.14971L7.5695 1.52708C7.39365 1.36814 7.29281 1.14787 7.29004 0.916641C7.29004 0.697922 7.38319 0.480439 7.5695 0.26172C7.74043 0.0943689 7.97485 0 8.21964 0C8.46442 0 8.69884 0.0943689 8.86977 0.26172L14.2572 5.32192C14.7188 5.79847 14.9964 6.36047 14.9998 7.01036C14.9998 7.01432 14.9998 7.01828 14.9998 7.02224C14.9998 7.02953 14.9998 7.03682 14.9997 7.04411C14.9931 7.64723 14.7161 8.24832 14.2572 8.67932L8.86977 13.7383C8.69884 13.9056 8.46442 14 8.21964 14C7.97485 14 7.74043 13.9056 7.5695 13.7383C7.39173 13.5807 7.29071 13.3595 7.29071 13.1278C7.29071 12.8962 7.39173 12.675 7.5695 12.5174L12.4925 7.89452L1.00009 7.89452Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </a>
  )
};

const CollectionContainer = (props) => {
  const {collections} = props;

  // Collection 左右上下展示
  const listData = [collections.contents.slice(0, 2), collections.contents.slice(2, 4)];

  // 点击 Collection || Works || Artists 去往指定位置
  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
  return (
    <div className="home_nav">
      <div id="sectionNavs2" className="left_nav"></div>
      <div id="sectionNavs" className="left_nav">
        <div className="nav_group">
          <div className="nav_text">
            {CollectionWorksArt}
          </div>
          <ul id="nav_btn" className="nav_btn">
            <li className="active" onClick={() => scrollToAnchor("screenCollection")}>Collections</li>
            <li onClick={() => scrollToAnchor("screenWorks")}>Works</li>
            <li onClick={() => scrollToAnchor("screenArtists")}>Artists</li>
          </ul>
        </div>
      </div>
      <div className="right_content">
        <div className="both">
          {listData.map((item, index) =>{
            return (
              <Row key={index} type="flex" justify="space-around" align="middle">
                <Col className="pr20" span={12}>{getImgAndLink(item[0])}</Col>
                <Col className="pl20" span={12}>{getImgAndLink(item[1])}</Col>
              </Row>
            )
          })}
        </div>
        <div className="more-btn">
          {
            <div className="more_group">
              <a href={toUrl(collections.uri)}>
                <span>{collections.seeAll}</span>
                <svg
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.33333 7.85204C0.812275 7.85466 0.386689 7.48341 0.380859 7.02117C0.386689 6.55894 0.812275 6.18769 1.33333 6.19031L12.2785 6.19031L7.5901 1.78769C7.42262 1.63632 7.32658 1.42654 7.32395 1.20632C7.32395 0.99802 7.41267 0.790894 7.5901 0.582589C7.75289 0.423207 7.97615 0.333332 8.20928 0.333332C8.4424 0.333332 8.66566 0.423207 8.82845 0.582589L13.9593 5.40182C14.4017 5.85845 14.6666 6.39744 14.6666 7.02117C14.6666 7.02118 14.6666 7.02118 14.6666 7.02118C14.6666 7.60255 14.4017 8.18392 13.9593 8.59935L8.82845 13.4174C8.66566 13.5768 8.4424 13.6667 8.20928 13.6667C7.97615 13.6667 7.75289 13.5768 7.5901 13.4174C7.42079 13.2673 7.32459 13.0567 7.32459 12.836C7.32459 12.6154 7.42079 12.4048 7.5901 12.2547L12.2786 7.85204L1.33333 7.85204Z"
                    fill="black"
                  />
                </svg>
              </a>
            </div>
          }
        </div>
      </div>
    </div>
  )
};

export default CollectionContainer;
