import React from "react";
import { Row, Col } from "antd";
import Slider from "react-slick";
import "./HeroCarouselCol.css";

const HeroCarousel = (props) => {
  const {
    contents = [], 
    prevClass = "" 
  } = props;
  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };
  const settingSmall = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 8000,
  };

  const _className = prevClass;

  return (
    <div className={_className}>
      {contents.length > 0 && (
        <Row>
          <Col md={24} sm={0} xs={0}>
            <Slider {...settings}>
              {contents.map((image, index) => {
                return (
                  <div className="carouselImg_col" key={index}>
                    <img
                      src={image.imageNormal}
                      alt={'Collection'}
                      height="640px"
                      width="auto"
                    />
                  </div>
                );
              })}
            </Slider>
          </Col>
          <Col md={0} sm={24} xs={24}>
            <Slider {...settingSmall}>
              {contents.map((image, index) => {
                return (
                  <div
                    className="carouselImg_col carouselImg_col_small"
                    key={index}
                  >
                    <img
                      src={image.imageNormal}
                      alt={'Collection'}
                      height="249px"
                      width="100%"
                    />
                  </div>
                );
              })}
            </Slider>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default HeroCarousel;
