import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Col, Row } from "antd";
import { IconWhiteRArrow } from "../../../images/indexSvg";
import { toUrl } from "../../../utilities/utilities";

function getImgAndLink(item) {
  return (
    <a href={toUrl(item.uri)}>
      <div className="workImg">
        <img src={item.imageUrls[0].coverImageNormal} alt={item.name} />
        <div className="workPop">
          <div className="workPopContent">
            <h2>{item.name}</h2>
            <p><IconWhiteRArrow /></p>
          </div>
        </div>
      </div>
    </a>
  );
}

function createRow2(index, length, artworks = [], imagesSizes = [], round = 0) {
  let leftImage = length - index;
  if (leftImage === 0) {
    return null;
  }

  let size = Math.ceil(length / 2);

  const resArr = [];
  
  for (let i = 0; i < size; i++) {
    var prve = i * 2;
    var next = prve + 2;
    resArr.push(artworks.slice(prve, next));
  }

  return resArr.map((item, index) => {
    if (item.length === 2) {
      return (
        <Row key={index} type="flex" justify="space-around" align="middle">
          <Col span={11}>{getImgAndLink(item[0])}</Col>
          <Col span={11}>{getImgAndLink(item[1])}</Col>
        </Row>
      );
    } else {
      return (
        <Row key={index} type="flex" justify="start" align="middle">
          <Col span={11}>{getImgAndLink(item[0])}</Col>
        </Row>
      );
    }
  });
}

const WorksGridLayout = (props) => {

  const {artworks} = props;
  
  let sizes = new Array(artworks.length);
  const [mounted, setMounted] = useState(false);
  const [imageSize, setImageSize] = useState(new Array(artworks.length));

  useEffect(() => {
    if (!mounted) {
      artworks.forEach((artwork, index) => {
        var img = new Image();
        img.src = artwork.imageUrls[0].coverImageNormal;
        img.onload = function () {
          sizes[index] = {
            height: this.height,
            width: this.width,
          };
          if (_.indexOf(sizes, undefined) < 0) {
            setImageSize(sizes);
            setMounted(true);
          }
        };
      });
    }
  }, [artworks]);

  if (mounted) {
    return createRow2(0, artworks.length, artworks, imageSize);
  } else {
    return <div>{"Loading..."}</div>;
  }
};

export default WorksGridLayout;