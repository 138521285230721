import React from "react";
import { Row, Col } from "antd";
import "../../views/Home/Home.css";
import CategoryMondrian from "../Collection/CategoryMondrian_Ver2"
import SmallSlider from "../ver2/work/SmallSlider_Ver2";
import HeaderNav from "../HeaderNav_Ver2";

// import GridLayout from "./GridLayout_Ver2";

const RenderMoreWorkCollection = (props) => {
  const {
    artworks
  } = props;

  // const _className = `wk_collections_container ${
  //   exhibitions.length === 0 ? "s_border" : ""
  // }`;

  return (
    <>
      {artworks.contents.length > 0 && (
        <div className={`wk_collections_container`}>
          <Row>
            <Col xs={0} lg={24}>
              <HeaderNav
                info={artworks}
              />
              {/* <GridLayout images={worksInSameCollection} /> */}
              <CategoryMondrian collections={artworks.contents} />
            </Col>
            <Col xs={24} sm={24} lg={0}>
              <SmallSlider
                artworks={artworks}
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default RenderMoreWorkCollection;
