import React from "react";
import "../SmallSlider.css";
import "../SmallSliderPublic.css";
import { IconSliderRArrow } from "../../../images/indexSvg"
import { toUrl } from "../../../utilities/utilities";

const SmallSlider = (props) => {
  const {artists} = props;
  return (
    <div className="public_v2 artists_v2">
      <div className="v2Header">
        <div className="v2Title">
          <span>{artists.name}</span>
        </div>
        <div className="v2More">
          <a href={toUrl(artists.uri)}>
            <span>{artists.seeAll}</span>
            <span className="icon">
              <IconSliderRArrow />
            </span>
          </a>
        </div>
      </div>
      <div className="v2Slide">
        {artists.contents.map((artist) => {
          return (
            <a key={artist.id} href={toUrl(artist.uri)}>
              <div className="v2Card">
                <div className="v2Image">
                  <img src={artist.imageUrls[0].coverImageNormal} alt={artist.name} />
                </div>
                <div className="v2ArtNameBox">
                  <div className="v__artName">{artist.name}</div>
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SmallSlider;