import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout, Row, Col, Button } from "antd";
import { Menulogo, GalleryallLogoBlk } from "../../../components/Icons";
import "../../../scss/LayoutVer2.css";
import SmallNav from "./SmallNav_Ver2";
import PhoneNav from "./phoneNav_Ver2";
import { initPage } from "./ScrollMagic";
import useWindowWidth from "../../../components/useWindowWidth";
import Axios from 'axios'
import { toUrl } from "../../../utilities/utilities";


const GalleryHeader = () => {
  // Data
  const [Artists, setArtists] = useState([]);
  const [Artworks, setArtworks] = useState([]);
  const [RestNavbar, setRestNavbar] = useState([]);
  // Style
  const [visible, setVisible] = useState(false);
  const [scrollingUp, setScrollingUp] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [navHover, setNavHover] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // useSelector get state
  const CurrentNav = useSelector((state) => state.currentNav);
  const CurrentPathName = useSelector((state) => state.currentPathName);

  // 数据请求
  useEffect(() => {
    Axios.post(
      "https://api.galleryall.com/parse/functions/getWebNavbar", {},
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    ).then(result => {
      // console.log(result.data.result.navbar)
      setArtworks(result.data.result.navbar[0]);
      setArtists(result.data.result.navbar[1]);
      setRestNavbar(result.data.result.navbar.slice(2));
    });
  }, []);

  // 移动从透明改变白色背景
  useEffect(() => {
    window.addEventListener("scroll", trackScrolling);
    return () => {
      window.removeEventListener("scroll", trackScrolling);
    };
  }, [scrollingUp, lastScrollTop, CurrentPathName]);

  // 'home', 'about', 'privacy' Transparent header at top
  const { pathname } = window.location;
  useEffect(() => {
    const whiteArray = ["", "/", "/about", "/privacy"]
    const isUri = whiteArray.includes(pathname)
    if (pathname !== "" && pathname !== "/" && !isUri) {
      setRefresh(true);
    } else {
      setRefresh(false);
    }
    return () => { }
  }, [pathname]);

  // Mobile 缩放菜单
  const handleMobileButton = (event) => {
    const val = !visible;
    setScrollingUp(val);
    setVisible(val);
    window.isMenu = val;
  };

  // Gallery Logo Click
  const handleLogoClick = (event) => {
    setRefresh(false)
    setNavHover(false);
    setScrollingUp(false);
    setVisible(false);
    window.isMenu = false;
  };

  // Nav Hover
  const changeHover = (ite) => {
    if (ite.length > 0) {
      setNavHover(true);
    } else {
      setNavHover(false);
    }
  };

  
  const clientWidth = useWindowWidth(100);
  const clientWarp = () => {
    return clientWidth < 1024 ? true : false;
  };

  // 浏览器滚动
  const trackScrolling = () => {
    if (CurrentPathName) {
      document.querySelector(".desktop-navigation").style.top = "0px";
      return;
    }
    var pageYOffset = window.pageYOffset || document.documentElement.scrollTop;
    const isMb = clientWarp();
    if (isMb && pageYOffset > 20) {
      !scrollingUp && setScrollingUp(isMb);
      return;
    }
    let desktop = "0px";
    // let navtop = "0px";
    if (pageYOffset >= lastScrollTop && pageYOffset > 320) {
      setScrollingUp(!1);
      setLastScrollTop(pageYOffset);
      desktop = "-102px";
      // navtop = "-48px";
    } else if (pageYOffset <= 0) {
      setLastScrollTop(0);
      setScrollingUp(!1);
    } else {
      setScrollingUp(!0);
      setLastScrollTop(pageYOffset);
    }
    document.querySelector(".desktop-navigation").style.top = desktop;
    // document.querySelector(".mobile-navtop").style.top = navtop;
    initPage();
  };

  const isDesktop = (refresh || navHover || scrollingUp || CurrentPathName);

  if (Artworks.length !== 0 && Artists.length !== 0 && RestNavbar.length !== 0) {
    const activeClass = isDesktop ? "desktop-active" : ""
    return (
      <div className="layout-header">
        <PhoneNav 
          handler={handleLogoClick}
          visible={visible}
          artists={Artists}
          artworks={Artworks}
          restNavbar={RestNavbar}
        />
        <Layout.Header
          className={`desktop-navigation ${activeClass}`}
          data-html2canvas-ignore="true"
          >
          <Row className="wrap">
            {/* Phone Nav Logo */}
            <Col md={0} xs={4} className="xsNav">
              <Button className="xsNavBtn" onClick={(e) => { handleMobileButton() }}>
                <Menulogo />
              </Button>
            </Col>
            {/* Galleryall Logo */}
            <Col md={6} xs={16} className="xsLogo">
              <a href={toUrl('/')} onClick={(e) => { handleLogoClick(); }}>
                <p id="logo">
                  <GalleryallLogoBlk />
                </p>
              </a>
            </Col>
            <Col md={18} xs={0} className="xsNavDesktop">
              <SmallNav 
                id="nav"
                mode="horizontal"
                cNav={CurrentNav}
                changeHover={changeHover}
                artworks={Artworks}
                artists={Artists}
                restNavbar={RestNavbar}
              />
            </Col>
          </Row>
          <div id="midlayer" />
        </Layout.Header>
        {/* search bar */}
        <Row className="hiddenSearchBar" id="hiddenSearchBar">
          <div className="search-container">
            <form action="/" method="get">
              <img
                id="search"
                src="/images/topbar/fill-1.png"
                srcSet="/images/topbar/fill-1@2x.png 2x, /images/topbar/fill-1@3x.png 3x"
                alt="search"
              />
              <input
                type="text"
                placeholder="Search by keyword"
                name="search"
              />
            </form>
          </div>
        </Row>
      </div>
    );
  } else {
    return <div />;
  }
};

export default GalleryHeader;
