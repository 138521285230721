import React, { Fragment } from "react";
import SmallSliderPress from "./SmallSliderPress_Ver2";

const RenderMobilePresses = (props) => {
  const {
    presses = {}
  } = props;
  return (
    <Fragment>
      {presses.contents.length > 0 && (
        <div className="render_press_container">
          <SmallSliderPress
            presses={presses}
          />
        </div>
      )}
    </Fragment>
  );
};

export default RenderMobilePresses;
