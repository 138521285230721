import React from "react";
import { IconVrClose } from "../Icons";

const VrPopupDesktop = (props) => {
  const { linkVr } = props;
  const { showPop, closePopup } = props;

  return (
    <React.Fragment>
      <div
        className="VrPopupPc"
        style={{ display: showPop ? "block" : "none" }}
      >
        <div className="popup_c">
          <iframe
            title="artistVideo"
            width="100%"
            height="100%"
            src={linkVr}
            // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
        <div
          className="closeBtn"
          onClick={(e) => {
            closePopup();
          }}
        >
          <IconVrClose />
        </div>
      </div>
    </React.Fragment>
  );
};

export default VrPopupDesktop;
