import jsPDF from "jspdf"
import html2canvas from "html2canvas"

export const PrintPDF = () => {
    html2canvas(document.body,{allowTaint: false, useCORS: true}).then((canvas)=>{
      const imgData = canvas.toDataURL('image/jpeg', 0.1);

      var imgWidth = 210; 
      var pageHeight = 295;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm', 'a4', true);
      var position = 0;

      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, '',"FAST");
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, '',"FAST");
        heightLeft -= pageHeight;
      }
      doc.save("download.pdf");

    })
};