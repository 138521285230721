import React from "react";
import { TransferYoutubeLink } from "../../utilities/utilities";
import "./video_style.css"

const CreateIframe = (youtubeLink, key) => {
  if (!youtubeLink) {
    return null;
  }

  youtubeLink = TransferYoutubeLink(youtubeLink);

  return (
    <div className="video" key={key}>
      <iframe
        title="WorkDetailVideo"
        width="100%"
        height="401"
        src={youtubeLink}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}

const CreateYoutube = ({ videList = [] }) => {
  const isSize = videList.length
  const isMore = isSize > 1 ? "list_video more" : 'list_video'
  return (
    <div className={isMore}>
      {
        videList.map((item, index) => {
          return CreateIframe(item, index)
        })
      }
    </div>
  )
}

export default CreateYoutube