import React, { Component } from "react";
import handleViewport from "react-in-viewport";
import './LazyImg.css'

class ImgToLazy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.inViewport && !this.loaded) {
      this.setState({
        loaded: true,
      });
    }
  }

  render() {
    
    const { forwardedRef } = this.props;
    const {
      src = "",
      className = "",
    } = this.state.loaded ? this.props : {};

    const Component = this.state.loaded && src ? "img" : "div";

    const style = src ? {} : {
      backgroundColor: 'rgb(240,240,240)',
      width: '100%',
      height: '150px',
    }
    return (
      <div className="lazyimg">
        <Component
          className={className}
          style={style}
          src={src} 
          ref={forwardedRef}
        />
      </div>
    )
  }
}
export const LazyImg = handleViewport(ImgToLazy, {}, { disconnectOnLeave: true });
