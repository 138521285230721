import React from "react";
import CreateVideoBody from "../CreateYoutube/index"

const RenderVideoDesktop = ({ youtubeLink, data }) => {
  const youtubeLinkArr = (youtubeLink && youtubeLink.split(" ")) || [];
  const playArr = youtubeLinkArr.map(item => {
    return item.replace("watch?", "embed/") || "";
  })

  return (
    <div className="content" id="VideosPc">
      {
        youtubeLink && (
          <React.Fragment>
            <div className="pb100" />
            <CreateVideoBody videList={playArr} />
            <div className="mb100" />
          </React.Fragment>
        )
      }
    </div>
  );
};

export default RenderVideoDesktop;
