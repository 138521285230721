import React from "react";
import { toUrl } from "../../utilities/utilities";


const ArtistSlider = (props) => {
  const {
    info = {}
  } = props;
  const getImgAndLink = (item) => {
    return (
      <a href={toUrl(item.uri)} key={item.id}>
        <div className="v2Card">
          <div className="v2Image">
            <img src={item.imageUrls[0].coverImageNormal} alt={item.name} />
          </div>
          <div className="v2ArtNameBox">
            <div className="v__artName">{item.name}</div>
          </div>
        </div>
      </a>
    );
  }

  return (
    <div className="horizontal_Slide artist_slide">
      {
        info.contents.map(item => {
          return getImgAndLink(item)
        })
      }
    </div>
  )
}

export default ArtistSlider
