import React from "react";
import CreateVideoBody from "../CreateYoutube/index";

const CollectionVideo = (props) => {
  const {
    linkVideo = ""
  } = props;
  const youtubeLinkArr = (linkVideo && linkVideo.split(" ")) || [];
  
  const videArr = [];
  youtubeLinkArr.forEach(item => {
    const realLink = item.replace("watch?", "embed/") || "";
    videArr.push(realLink)
  })

  const isSize = videArr.length;
  const title = `Video${isSize > 1 ? "s" : ""}`

  return (
    <div className="collections_video c__video">
      <div className="title">{title}</div>
      <CreateVideoBody videList={videArr} />
      {/* <Divider /> */}
    </div>
  );
};

export default CollectionVideo;
