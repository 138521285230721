import React from "react";
import { toUrl } from "../../utilities/utilities";

const PressesSlider = (props) => {
  const {
    info = {}
  } = props;

  const getImgAndLink = (item) => {
    return (
      <div key={item.id} className="column_item">
        <a href={toUrl(item.uri)}>
          <div className="lazyimg_image" >
            <img src={item.imageUrls[0].coverImageNormal} alt={item.name} />
          </div>
        </a>
        <div className="c_text_div">
          <div className="c_card_name">{item.name}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="horizontal_Slide presses_slide">
      {
        info.contents.map(item => {
          return getImgAndLink(item)
        })
      }
    </div>
  )
}

export default PressesSlider