import React, { useState, Fragment } from "react";
import DropDownMenu from "./DropDownMenu_Ver2"
import MobileFilter from "../MobileFilter_Ver2";
import { IconArtDownArrow } from "../../images/indexSvg"

import "../Version2/FilterBar.css";
import "../Collection/FilterBar.css";

const FilterBar = (props) => {
  const {
    info = [],
    marked = [],
    setMarked = () => {},
    handleReset = () => {},
    handleConfirm = () => {},
    pre_title = 'All',
    currentType = 0,
    setFilterType = () => {},
  } = props;

  const [visible, setVisible] = useState(false);
  
  const handleOpen = () => {
    setVisible(true)
  }

  const handleClose = () => {
    setVisible(false)
  }
  
  const setMarkedFilter = (value, isMarked) => {
    setMarked(value, isMarked);
  }

  const handleResetFilter = () => {
    handleReset();
  }

  const handleConfirmFilter = () => {
    handleConfirm();
  }

  // 筛选
  const onFilterType = (type) => {
    setFilterType(type);
  };

  return (
    <Fragment>
      <div className="Large_Screen_Frame">
        <div className="s_textFilter_list">
          <span
            className={`s_textFilter ${currentType === 0 ? "active" : ""}`}
            onClick={(e) => { onFilterType(0); }}
          >
            All
          </span>
          <span
            className={`s_textFilter ${currentType === 1 ? "active" : ""}`}
            onClick={(e) => { onFilterType(1); }}
          >
            Visual Art
          </span>
          <span
            className={`s_textFilter ${currentType === 2 ? "active" : ""}`}
            onClick={(e) => { onFilterType(2); }}
          >
            Functional Art
          </span>
        </div>
        <div className="filter_select">
            <DropDownMenu
              info={info.contents}
              pre_title={pre_title}
              post_title={info.name}
              marked={marked}
              setMarked={setMarkedFilter}
              handleReset={handleResetFilter}
              handleConfirm={handleConfirmFilter} 
            />
          </div>
      </div>
      <div className="Small_Screen_Frame">
        <div className="mobile_filter_btn" onClick={() => handleOpen()}>
          <span>{pre_title} {info.name}</span>
          <IconArtDownArrow />
        </div>
      </div>
      <MobileFilter
        info={info} 
        visible={visible}
        handleClose={handleClose}
        marked={marked}
        setMarked={setMarkedFilter}
        handleReset={handleResetFilter}
        handleConfirm={handleConfirmFilter} 
      />
    </Fragment>
  )
};

export default FilterBar;