import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { LocaleProvider, Layout } from "antd";
import GalleryHeader from "./headerVer2/GalleryHeader_Ver2";
import GalleryFooter from "./footerVer2/GalleryFooterVer2.js";

const { Content } = Layout;

const Decorator = ({ children, location: { pathname } }) => {
  const [opacity, setOpacity] = useState(0);

  const promise3 = new Promise((resolve, reject) => {
    setTimeout(resolve, 1500, 1);
  });

  useEffect(() => {
    setOpacity(0)
    React.NProgress.start();
    Promise.all([promise3]).then((values) => {
      React.NProgress.done();
      setOpacity(1)
    });
  }, [pathname])

  return (
    <LocaleProvider>
      <Layout style={{ height: "100%" }}>
        <Helmet>
          {/* <title>Gallery ALL - Los Angeles - Beijing</title> */}
          <title>Gallery All - Shanghai - Los Angeles</title>
          <meta
            name="description"
            content="Founded by Yu Wang and Xiao Lu in 2014, Gallery ALL is considered one of the most important design galleries that dedicates to commissioning and showcasing contemporary limited-edition designs. "
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
        <Layout>
          <GalleryHeader />
          <Content style={{ opacity: opacity }}>{children}</Content>
          <GalleryFooter style={{ opacity: opacity }} />
        </Layout>
      </Layout>
    </LocaleProvider>
  );
};

export default Decorator;
