import React from "react";
import { Row, Col } from "antd";
import HeaderNav from "../HeaderNav_Ver2";
import CategoryMondrian from "../Works/CategoryMondrian_Ver2";
import SmallSlider from "../ver2/work/SmallSlider_Ver2";

const CollectionMoreWork = (props) => {
  const {
    artworks = {}
  } = props;
  return (
    <>
      <div className="collections_divider"></div>
      <div className="collections_works_container">
        <Row>
          <Col xs={0} lg={24}>
            <HeaderNav
              info={artworks}
            />
            <CategoryMondrian artworks={artworks.contents}/>
          </Col>
          <Col xs={24} sm={24} lg={0}>
            <SmallSlider
              artworks={artworks}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CollectionMoreWork;
