import React from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import useWindowWidth from "../../components/useWindowWidth";
import { numberToMonth } from "../../utilities/utils";
import { IconTime, IconAddress, IconMore } from "../../images/indexSvg";
import "./swiper.css";
import { toUrl } from "../../utilities/utilities"

const createSlider = (props) => {
  const {images, uri, settings} = props;
  return (
    <div className="swiper_slide_info">
      <Slider {...settings}>
        {images.map((item, index) => {
          return (
            <a href={toUrl(uri)} key={index}>
              <div>
                <img width="100%" src={item.imageNormal} alt="" />
              </div>
            </a>
          );
        })}
      </Slider>
    </div>
  );
};

const createBannerLink = (item, settings) => {
  const images = item.imageUrls;
  const uri = item.uri;
  const props = { images, uri, settings };
  const startDate = item.startDate.iso.split("T")[0];
  const endDate = item.endDate.iso.split("T")[0];
  return (
    <div className="swiper_info" key={item.id}>
      <a href={toUrl(item.uri)}>
        <div className="subtitle mb20">{item.statusText}</div>
        <h1 className="title mb20">{item.name}</h1>
        <div className="time mb12">
          <span className="icon">
            <IconTime />
          </span>
          <span>{numberToMonth(startDate, endDate)}</span>
        </div>
        {item.location ? (
          <div className="address mb20">
            <span className="icon">
              <IconAddress />
            </span>
            <span>{item.location}</span>
          </div>
        ) : (
          <div />
        )}
        {item.description && <div className="desc mb20">{item.description}</div>}
        <div className="more mb20">
          <span>Learn More</span>
          <span className="icon">
            <IconMore />
          </span>
        </div>
      </a>
      {createSlider(props)}
    </div>
  );
};

const ImgSwiper = (props) => {
  const {
    exhibitions = []
  } = props;

  let windowWidth = useWindowWidth(100);
  let cn = getColNum(windowWidth);
  function getColNum(w) {
    if (w <= 1023) return true;
    return false;
  }

  // Settings for slider
  const settings = {
    dots: cn,
    arrows: cn ? false : true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="ImgSwiper">
      {exhibitions.map((item) => {
        return createBannerLink(item, settings);
      })}
    </div>
  );
};

export default ImgSwiper;
