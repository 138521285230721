import React from "react";
import SmallSliderWorks from "./SmallSliderWorks_Ver2";

const RenderMobileWorks = (props) => {
  const {
    artworks = {}
  } = props;
  return (
    <div className="render_works_container" id="mb_works_exhibition">
      {artworks.contents.length > 0 && (
        <SmallSliderWorks
          artworks={artworks}
        />
      )}
    </div>
  );
};

export default RenderMobileWorks;
