import React from "react";
import { SeparateParagraph } from "../../utilities/utilities";

const RenderMobileDescription = (props) => {
  const { exhibition = {} } = props;
  return (
    <React.Fragment>
      {exhibition.content.description && (
        <div className="c_Description">
          <div className="s_Overview">Overview</div>
          <div className="s_Description">
            <div className="content">{SeparateParagraph(exhibition.content.description)}</div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default RenderMobileDescription;
