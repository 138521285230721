import React from "react";
import SVG from "react-inlinesvg";

const IconPrev = ()=>(<SVG className="iconMenulogo" src={"/icon/icon-prev.svg"} cacheGetRequests />)
const IconNext = ()=>(<SVG className="iconMenulogo" src={"/icon/icon-next.svg"} cacheGetRequests />)
const IconDownLoad = () => (<SVG className="iconMenulogo" src={"/icon/icon-download.svg"} cacheGetRequests />);
const IconPlay = () => (<SVG className="iconMenulogo" src={"/icon/icon-play.svg"} cacheGetRequests />);
const IconPlayDesktop = () => (<SVG className="iconMenulogo" src={"/icon/icon-play-desktop.svg"} cacheGetRequests />);
const IconScrollTop = () => (<SVG className="iconMenulogo" src={"/icon/icon-scroll-top.svg"} cacheGetRequests />);
const IconVrClose = () => (<SVG className="iconMenulogo" src={"/icon/icon-vr-close.svg"} cacheGetRequests />);

export {
  IconPrev,
  IconNext,
  IconDownLoad,
  IconPlay,
  IconPlayDesktop,
  IconScrollTop,
  IconVrClose
}