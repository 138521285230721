import React from "react";
import { Row, Col, Layout, Typography } from "antd";
import { Link } from "react-router-dom";
import Contact from "./Contact";
import NewsLetter from "./NewsLetter";
import Code from "./Code";
import styled from "styled-components";
// import "../Layout.css";
import "../../../scss/footer.css";
const { Footer } = Layout;
const { Text } = Typography;

const StyledText = styled(Text)`
  && {
    margin-top: auto;
    width: 207px;
    height: 14px;
    font-family: MaisonNeue-Book;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark);
  }
`;

const StyledTextUnderLink = styled(Text)`
  && {
    margin-top: auto;
    width: 207px;
    height: 14px;
    font-family: MaisonNeue-Book;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark);
    text-decoration: underline;
  }
`;

const CompanyInfo = "2022 Gallery All Limited"

// 2020 Design at ALL LLC
// const CopyRight = () => {
//   return (
//     <div className="copyright">
//       <StyledText>© {CompanyInfo}.&nbsp;All Rights Reserved.&nbsp;</StyledText>
//       <PrivacyLink />
//     </div>
//   );
// };

const AddressAndCopyRight = () => {
  return (
    <div className="lg_address">
      <StyledText>
        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 6.15234C4.379 6.15234 3.875 5.64834 3.875 5.02734C3.875 4.40634 4.379 3.90234 5 3.90234C5.621 3.90234 6.125 4.40634 6.125 5.02734C6.125 5.64834 5.621 6.15234 5 6.15234ZM5 0.527344C2.51487 0.527344 0.5 2.54222 0.5 5.02734C0.5 8.40234 5 11.7773 5 11.7773C5 11.7773 9.5 8.40234 9.5 5.02734C9.5 2.54222 7.48513 0.527344 5 0.527344ZM5 7.27734C6.24088 7.27734 7.25 6.26822 7.25 5.02734C7.25 3.78647 6.24088 2.77734 5 2.77734C3.75912 2.77734 2.75 3.78647 2.75 5.02734C2.75 6.26822 3.75912 7.27734 5 7.27734ZM5 1.65234C6.86131 1.65234 8.375 3.16603 8.375 5.02734C8.375 6.94941 6.32806 9.15666 5 10.3233C3.67138 9.15609 1.625 6.94884 1.625 5.02734C1.625 3.16603 3.13869 1.65234 5 1.65234Z" fill="white"/>
        </svg>
        <span>上海市静安区余姚路66号  No.66 Yuyao Road, Jing’an District, Shanghai</span>
      </StyledText>
      <span className="copyright">
        <StyledText className="sdfsdf">© {CompanyInfo}.&nbsp;All Rights Reserved.&nbsp;</StyledText>
        <PrivacyLink />
      </span>
    </div>
  );
};

const PrivacyLink = () => { 
  return (
      <Link to="/privacy">
        <StyledTextUnderLink>Privacy Policy</StyledTextUnderLink>
        <StyledText>.</StyledText>
      </Link>
  );
}

const StyledCode = styled(Code)`
  && {
    height: 130px;
  }
`;

const StyledContact = styled(Contact)`
  && {
    height: 100px;
  }
`;
const StyledNewsLetter = styled(NewsLetter)`
  && {
    height: 100px;
  }
`;

const GalleryFooter = ({style}) => {
  return (
    <Footer id="footerBox" className="gallery_footer" style={style}>
      <Row>
        <Col xs={0} lg={24}>
          <div className="pub_footer lg_footer">
            <div className="lg_footer_item">
              <StyledCode />
              <StyledContact />
              <StyledNewsLetter />
            </div>
          </div>
          <AddressAndCopyRight />
        </Col>
        <Col xs={24} sm={24} lg={0}>
          <div className="sm_footer">
            <div className="small_item">
              <StyledCode />
              <StyledContact />
            </div>
            <StyledNewsLetter />
          </div>
          <div className="sm_address">
            <div className="pc744">
              <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 6.15234C4.379 6.15234 3.875 5.64834 3.875 5.02734C3.875 4.40634 4.379 3.90234 5 3.90234C5.621 3.90234 6.125 4.40634 6.125 5.02734C6.125 5.64834 5.621 6.15234 5 6.15234ZM5 0.527344C2.51487 0.527344 0.5 2.54222 0.5 5.02734C0.5 8.40234 5 11.7773 5 11.7773C5 11.7773 9.5 8.40234 9.5 5.02734C9.5 2.54222 7.48513 0.527344 5 0.527344ZM5 7.27734C6.24088 7.27734 7.25 6.26822 7.25 5.02734C7.25 3.78647 6.24088 2.77734 5 2.77734C3.75912 2.77734 2.75 3.78647 2.75 5.02734C2.75 6.26822 3.75912 7.27734 5 7.27734ZM5 1.65234C6.86131 1.65234 8.375 3.16603 8.375 5.02734C8.375 6.94941 6.32806 9.15666 5 10.3233C3.67138 9.15609 1.625 6.94884 1.625 5.02734C1.625 3.16603 3.13869 1.65234 5 1.65234Z" fill="white"/>
              </svg>
              <span>上海市静安区余姚路66号</span>&nbsp;
              <span>No.66 Yuyao Road, Jing’an District, Shanghai</span>
            </div>
            <div className="pc743">
              <p>
                <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 6.15234C4.379 6.15234 3.875 5.64834 3.875 5.02734C3.875 4.40634 4.379 3.90234 5 3.90234C5.621 3.90234 6.125 4.40634 6.125 5.02734C6.125 5.64834 5.621 6.15234 5 6.15234ZM5 0.527344C2.51487 0.527344 0.5 2.54222 0.5 5.02734C0.5 8.40234 5 11.7773 5 11.7773C5 11.7773 9.5 8.40234 9.5 5.02734C9.5 2.54222 7.48513 0.527344 5 0.527344ZM5 7.27734C6.24088 7.27734 7.25 6.26822 7.25 5.02734C7.25 3.78647 6.24088 2.77734 5 2.77734C3.75912 2.77734 2.75 3.78647 2.75 5.02734C2.75 6.26822 3.75912 7.27734 5 7.27734ZM5 1.65234C6.86131 1.65234 8.375 3.16603 8.375 5.02734C8.375 6.94941 6.32806 9.15666 5 10.3233C3.67138 9.15609 1.625 6.94884 1.625 5.02734C1.625 3.16603 3.13869 1.65234 5 1.65234Z" fill="white"/>
                </svg>
                <span>上海市静安区余姚路66号</span>&nbsp;
              </p>
              <p>
                <span>No.66 Yuyao Road, Jing’an District, Shanghai</span>
              </p>
            </div>
          </div>
          <div className="footer-text-style sm_copyright">
            <span>© {CompanyInfo}.&nbsp;All Rights Reserved.&nbsp;</span>
            <PrivacyLink />
          </div>
        </Col>
      </Row>
    </Footer>
  );
};

export default GalleryFooter;
