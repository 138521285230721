import React from "react";
import { DownloadImage, DownloadFiles } from "../../components/DownloadImage_Ver2";
import { DownLoadIconV2 } from "../../components/Icons";
import { numberToMonth } from "../../utilities/utils";

const ExhibitionInfoMobile = (props) => {
  const {
    exhibition = [],
    images = [],
    files = []
  } = props;

  var fileClass = `${files.length > 0 ? "downloadIcon" : "downloadIcon-disable"} m__download`;
  var imageClass = `${images.length > 0 ? "downloadIcon" : "downloadIcon-disable"} m__download`;

  const downloadImage = () => {
    DownloadImage(images, exhibition.content.name);
  };

  const downloadFile = () => {
    DownloadFiles(files, exhibition.content.name);
  };
  const startDate = exhibition.content.startDate.iso.split("T")[0];
  const endDate = exhibition.content.endDate.iso.split("T")[0];

  return (
    <React.Fragment>
      <div className="exhibitionDetailWrap">
        <div className="exhibitionDetailInfo_sub_title">{exhibition.content.statusText}</div>
        <div className="exhibitionDetailInfo_title">{exhibition.content.name}</div>
        <div className="exhibitionDetailInfo_content">
          <div className="content">
            <span className="icon">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.1201 5.7201V12.1201H1.8801V5.7201H12.1201ZM10.8401 9.5601H9.5601V10.8401H10.8401V9.5601ZM8.2801 9.5601H3.1601V10.8401H8.2801V9.5601ZM4.4401 7.0001H3.1601V8.2801H4.4401V7.0001ZM10.8401 7.0001H5.7201V8.2801H10.8401V7.0001ZM12.1201 1.8801H10.8401V0.600098H9.5601V1.8801H4.4401V0.600098H3.1601V1.8801H1.8801C1.17354 1.8801 0.600098 2.45354 0.600098 3.1601V12.1201C0.600098 12.8267 1.17354 13.4001 1.8801 13.4001H12.1201C12.8267 13.4001 13.4001 12.8267 13.4001 12.1201V3.1601C13.4001 2.45354 12.8267 1.8801 12.1201 1.8801Z" fill="black" />
              </svg>
            </span>
            <span>{numberToMonth(startDate, endDate)}</span>
          </div>
        </div>
        {exhibition.content.location ? <div className="exhibitionDetailInfo_content last_content">
          <div className="content">
            <span className="icon">
              <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 8C5.172 8 4.5 7.328 4.5 6.5C4.5 5.672 5.172 5 6 5C6.828 5 7.5 5.672 7.5 6.5C7.5 7.328 6.828 8 6 8ZM6 0.5C2.6865 0.5 0 3.1865 0 6.5C0 11 6 15.5 6 15.5C6 15.5 12 11 12 6.5C12 3.1865 9.3135 0.5 6 0.5ZM6 9.5C7.6545 9.5 9 8.1545 9 6.5C9 4.8455 7.6545 3.5 6 3.5C4.3455 3.5 3 4.8455 3 6.5C3 8.1545 4.3455 9.5 6 9.5ZM6 2C8.48175 2 10.5 4.01825 10.5 6.5C10.5 9.06275 7.77075 12.0058 6 13.5613C4.2285 12.005 1.5 9.062 1.5 6.5C1.5 4.01825 3.51825 2 6 2Z" fill="black" />
              </svg>
            </span>
            <span>{exhibition.content.location}</span>
          </div>
        </div> : ""}
        <div className="downloadIconWrap">
          <div className={fileClass} onClick={downloadFile}>
            <span className="download_icon">
              <DownLoadIconV2 />
            </span>
            <span className="download_text">Press Release (PDF)</span>
          </div>
          <div className={imageClass} onClick={downloadImage}>
            <span className="download_icon">
              <DownLoadIconV2 />
            </span>
            <span className="download_text">All Images</span>
          </div>
        </div>
        {/* <Divider />
        <div className="socialIcon">
          <InstagramIcon />
          <FacebookIcon />
          <EmailIcon />
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default ExhibitionInfoMobile;
