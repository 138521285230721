import React from "react";
import { Row, Col } from "antd";
// import "./Home.css";
import CollectionContainer from "../../views_components/ver2/collection/CollectionContainer_Ver2";
import SmallSlider from "../../views_components/ver2/collection/SmallSlider_Ver2";
import { CollectionWorksArt } from "../../views_components/ver2/Enum"

const CollectionsView = (props) => {

  const {collections} = props;
  
  return (
    <div id="Collection" className="v2-Collections">
      <Row>
        <Col xs={0} lg={24}>
          <CollectionContainer
            collections = {collections}
          />
        </Col>
        <Col xs={24} sm={24} lg={0}>
          <div className="collections_info">{CollectionWorksArt}</div>
          <SmallSlider
            collections = {collections}
          />
        </Col>
      </Row>
    </div>
  )
};

export default CollectionsView;