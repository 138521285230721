import React from "react";
import { toUrl } from "../../utilities/utilities";
// import Item from "antd/lib/list/Item";

const ExhibitionsRow = (props) => {
  const {
    item = {}
  } = props;

  return (
    <a href={toUrl(item.uri)}>
      <div key={item.id} className="column_item">
        <div className="lazyimg_image">
          <img src={item.imageUrls[0].coverImageNormal} alt={item.name} />
        </div>
        <div className="popup_group modelsEx">
          <div className="t_body">
            <div className="t_author">{item.statusText}</div>
            <div className="t_title">{item.name}</div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default ExhibitionsRow;
