import React from "react";
import "../SmallSlider.css";
import "../SmallSliderPublic.css";
import { IconMoreRightArrow, IconSliderMoreArrow } from "../../../images/indexSvg"
import { toUrl } from "../../../utilities/utilities";

const SmallSlider = (props) => {
  const {collections} = props;
  return (
    <div className="public_v2 collection_v2">
      <div className="v2Header">
        <div className="v2Title">
          <span>{collections.name}</span>
        </div>
        <div className="v2More">
          <a href={toUrl(collections.uri)}>
            <span>{collections.seeAll}</span>
            <span className="icon">
              <IconMoreRightArrow />
            </span>
          </a>
        </div>
      </div>
      <div className="v2Slide">
        {collections.contents.map((item) => {
          return (
            <a key={item.id} href={toUrl(item.uri)}>
              <div className="v2Card">
                <div className="v2Image">
                  <img src={item.imageUrls[0].coverImageNormal} alt={item.name} />
                </div>
                <div className="v2NameBox">
                  <div className="v__author">{item.artistsName}</div>
                  <div className="v__name">{item.name}</div>
                  <div className="v__more">
                    <IconSliderMoreArrow />
                  </div>
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SmallSlider;
