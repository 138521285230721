import React, { useState, useEffect } from "react";
import Masonry from "react-masonry-component";
import { LazyImg } from "../../components/LazyImg";
import "../Version2/CategoryMondrian.css";
import "../Version2/Wrapper.css";
import useObserver from "../ResizeObserver";
import { setWapperStyle } from "../../utilities/utils";
import { toUrl } from "../../utilities/utilities";

function getColNum(w) {
  const scoll = 16;
  if (w >= 1280 - scoll) return 4;
  if (w >= 1024 - scoll) return 3;
  if (w >= 743 - scoll) return 2;
  return 1;
}

const CategoryMondrian = (props) => {
  const {
    presses = {},
    realWidth = false
  } = props;
  const [size, SetSize] = useState(0);
  const measuredRef = React.useRef(null);

  useObserver({
    element: measuredRef,
    callback: (element) => {
      const target = element[0].target;
      const offsetWidth = target.offsetWidth;
      let cn = getColNum(offsetWidth);
      if (realWidth) {
        cn = 2
      }
      SetSize(cn);
    },
  });

  useEffect(() => {
    return () => { };
  }, [size]);

  const renderCards = () => {
    let output = [];
    presses.forEach((item, id) => {
      let ui = (
        <div key={id} className="column_item">
          <a href={toUrl(item.uri)}>
            <LazyImg className="presses_thumb" src={item.imageUrls[0].coverImageNormal} key={id} />
          </a>
          <div className="c_text_div">
            <div className="card_name">{item.name}</div>
          </div>
        </div>
      );
      output.push(ui);
    });
    return output;
  };

  const renderColumns = (nums, artists) => {
    const columns = [];
    for (let i = 0; i < nums; i++) {
      columns.push([]);
    }

    artists.map((item, id) => {
      const current = (id + nums) % nums;
      columns[current].push(item);
      return "finished";
    });

    const columnsSize = columns.length;

    return columns.map((column, id) => {
      let array = ["common_column"];
      array = setWapperStyle(array, id, columnsSize);
      const _className = array.join(" ");

      return (
        <div className={_className} key={id}>
          {column}
        </div>
      );
    });
  };

  const masonryOptions = {
    itemSelector: ".never",
    columnWidth: ".never",
    isOriginLeft: true,
  };

  return (
    <div className="common_wrapper presses_wrapper" ref={measuredRef}>
      {presses.length > 0 &&
        <Masonry className="c_Masonry" options={masonryOptions}>
          {size > 0 && renderColumns(size, renderCards())}
        </Masonry>
      }
    </div>
  );
};

export default CategoryMondrian;
