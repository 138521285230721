import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { updateCurrentPathName, updateNavigation } from "../redux/actions";

const ScrollToTop = ({ children, location: { pathname } }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    let flag = false;
    const whiteArray = ["", "/", "/about", "/privacy"]
    const isUri = whiteArray.includes(pathname)
    if (pathname !== "/" && pathname !== "" && !isUri) {
      flag = true;
    }

    let _pathname = ""
    if (pathname !== "/") {
      let navName = pathname.split("/") || [];
      _pathname = navName[1]
    }

    dispatch(updateCurrentPathName(flag));
    dispatch(updateNavigation(_pathname));
  }, [pathname]);

  return children || null;
};

export default withRouter(ScrollToTop);
