import React from "react";
import CategoryMondrian from "./CategoryMondrian_Ver2";
import { IconMoreRightArrow } from "../../images/indexSvg"

const RenderDesktopPresses = (props) => {
  const {
    presses = {}
  } = props;
  return (
    <div className="content" id="SelectedPressPc">
      {presses.contents.length > 0 ? (
        <React.Fragment>
          <div className="pb100" />
          <CategoryMondrian presses={presses.contents} realWidth={true}/>

          <div className="icon_more_box">
            <a href={`http://${window.location.host}${presses.uri}`}>
              {presses.contents.length > 1 &&
              <span>{presses.seeAll}</span>
              }
              {presses.contents.length === 1 &&
              <span>{'View all Press about the Exhibition'}</span>
              }
              <IconMoreRightArrow />
            </a>
          </div>
          <div className="mb100" />
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default RenderDesktopPresses;
