import { useState } from "react";

/**
 *  @param {name}, is a filter condition such as work type, artist name
 */
const useFilterBar = (
  name = "", items = [], initial_markedItem = []
) => {
  const [markedItems, setMarkedItems] = useState(initial_markedItem);

  // temp: 目前设计不大合理
  //多选过滤，每一次只根据一组过滤条件 markedItems 过滤；
  const filterInput = ({ list = [], field = ""  }) => {
    // 无过滤条件则返回原内容
    if (markedItems.length === 0) {
      return { data: [...list] };
    }
    const filteredList = [];
    return { data: filteredList };
  };
  
  return {
    name: name,
    items: items,
    unique_items: getUniqueItems(items),
    markedItems: markedItems,
    setMarkedItems: setMarkedItems,
    filterInput: filterInput,
  };
};

const getUniqueItems = (items) => {
  const array = [...new Set(items)];
  const unEmpty = array.filter((item) => item !== "");
  return unEmpty;
};

export default useFilterBar;
