import React from "react";
import ExhibitionsRow from "./ExhibitionsRow_Ver2";

const ExhibitionSlider = (props) => {
  const {
    info = {}
  } = props;

  return (
    <div className="horizontal_Slide exhibition_slide">
      {info.contents.map(item => {
        return (
          <ExhibitionsRow
            key={item.id}
            item={item}
          />
        );
      })}
    </div>
  );
};

export default ExhibitionSlider;
