import React from "react";
import HeaderNav from "../HeaderNav_Ver2";
import { IconDetailRArrow } from "../../images/indexSvg";
import { SeparateParagraph } from "../../utilities/utilities";

const CollectionArtist = (props) => {
  const {
    artist = {}
  } = props;

  return (
    <>
      <div className="collections_divider"></div>
      <div className="collections_artist artist_info_pc">
        <HeaderNav
          info = {artist}
        />
        <div className="artist_view_info">
          <div className="info_left">
            <div className="info_img">
              <img src={artist.content.imageUrls[0].coverImageNormal} alt={artist.content.name} />
            </div>
            <div className="info_name">{artist.content.name}</div>
            <div className="info_link">
              <a href={`http://${window.location.host}${artist.content.uri}`}>
                <span className="text">More about the Artist</span>
                <span className="icon">
                  <IconDetailRArrow />
                </span>
              </a>
              <a href={`http://${window.location.host}${artist.uriFromArtist}`}>
                <span className="text">{artist.seeAllFromArtist}</span>
                <span className="icon">
                  <IconDetailRArrow />
                </span>
              </a>
            </div>
          </div>
          <div className="info_right">{SeparateParagraph(artist.content.biography)}</div>
        </div>
      </div>
    </>
  );
};
export default CollectionArtist;
