import React from "react";
import _get from "lodash/get";
import { Checkbox, Row } from "antd";
import { CloseIcon } from "../../components/Icons";

import "./style.css";

/**
 * mobile 全屏Filter
 * @param {*} param0 
 * @returns 
 */
const MobileFilter = ({
  items = [],
  name = "Artists",
  markedItems = [],
  setMarkedItems = () => { },
  visible = false,
  handleClose = () => { },
  handleReset = () => { },
  handleConfirm = () => { },
}) => {

  const handleOnClick = (e) => {
    const checked = _get(e, "target.checked", "NULL");
    const checkedValue = _get(e, "target.value", "NULL");
    if (checked) {
      setMarkedItems([...markedItems, checkedValue]);
    } else {
      const checkedValues = markedItems.filter((item) => item !== checkedValue);
      setMarkedItems(checkedValues);
    }
  };

  const onReset = () => {
    setMarkedItems([]);
    handleReset();
  }

  // const handleConfirm = () => {
  //   // setMarkedItems([]);
  //   handleClose();
  // }

  return (
    <div className="m_filter" style={{ display: visible ? 'block' : 'none' }}>
      <div className="f_top">
        <span className="f_title">{name} Filter</span>
        <span className="f_close" onClick={() => handleClose()}>
          <CloseIcon />
        </span>
      </div>
      <div className="f_body">
        <div className="f_content">
          <Row className="f_checked m_filter_list" span={24}>
            {
              items.map((name, id) => {
                let checked = markedItems.reduce((isMarked, markedItem) => {
                  if (!isMarked) {
                    return name === markedItem;
                  } else return isMarked;
                }, false);

                return (
                  <div className="m_filter_item" key={id}>
                    <Checkbox
                      value={name}
                      onClick={handleOnClick}
                      checked={checked}
                      style={{ textAlign: "left" }}
                    />
                    <span className="m_styled_span">{name}</span>
                  </div>
                );
              })
            }
          </Row>
        </div>
        <div className="f_btn_group">
          <div className="t__text reset" onClick={() => { onReset() }}>Reset</div>
          <div className="t__text confirm" onClick={() => { handleConfirm() }}>Confirm</div>
        </div>
      </div >
    </div >
  )
}

export default MobileFilter;