import React, { useState, Fragment } from "react";
import _get from "lodash/get";
import DropDownMenu from "./DropDownMenu"
import MobileFilter from "../MobileFilter";
import { IconArtDownArrow } from "../../images/indexSvg"

import "../Collection/Styled/styled_option.css";


// Collections
const FilterView = ({
  filters = {},
  uniqueItems = [],
  filterType = () => { },
  filterConfirm = () => { }
}) => {

  const [visible, setVisible] = useState(false);
  const [selectedText, setSelectText] = useState("All")

  const name = _get(filters, "name", "");
  const markedItems = _get(filters, "markedItems", [])
  const setMarkedItems = _get(filters, "setMarkedItems", () => { });

  const handleClose = () => {
    if (markedItems.length > 0) {
      setSelectText("Selected")
    } else {
      setSelectText("All")
    }
    setVisible(false)
  }
  const handleOpen = () => {
    setVisible(true)
  }

  const handleReset = () => {
    setSelectText("All")
    setVisible(false)
    filterConfirm(false);
  }

  const handleConfirm = () => {
    if (markedItems.length > 0) {
      setSelectText("Selected")
    } else {
      setSelectText("All")
    }
    setVisible(false)
    filterConfirm(true);
  }
  const menu_params = {
    name: name,
    items: uniqueItems,
    markedItems: markedItems,
    setMarkedItems: setMarkedItems,
  };

  return (
    <Fragment>
      <div className="Large_Screen_Frame">
        <div className="filter_select">
          <DropDownMenu
            text={selectedText}
            handleReset={handleReset}
            handleConfirm={handleConfirm}
            {...menu_params}
          />
        </div>
      </div>
      <div className="Small_Screen_Frame">
        <div className="mobile_filter_btn" onClick={() => handleOpen()}>
          <span>{selectedText} {name}</span>
          <IconArtDownArrow />
        </div>
      </div>
      <MobileFilter
        visible={visible}
        name={name}
        handleClose={handleClose}
        handleReset={handleReset}
        handleConfirm={handleConfirm}
        {...menu_params}
      />
    </Fragment>
  )

}

export default FilterView;