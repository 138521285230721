import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

export const DownloadImage = (urls, saveName) => {
  if (urls.length === 1) {
    fetch(urls[0]).then(function(t) {
      return t.blob().then(b => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", `${saveName}.jpg`);
        a.click();
      });
    });
  } else {
    let zip = new JSZip();
    let count = 0;
    let name = saveName + "_images.zip";
    urls.forEach(url => {
      JSZipUtils.getBinaryContent(url, function(err, data) {
        if (err) {
          throw err;
        }
        zip.file(`${count}.jpg`, data, { binary: true });
        count++;
        if (count === urls.length) {
          zip.generateAsync({ type: "blob" }).then(function(content) {
            saveAs(content, name);
          });
        }
      });
    });
  }
};

export const DownloadFiles = (files, saveName) => {
  if (files.length === 1) {
    fetch(files[0].file.url).then(function(t) {
      return t.blob().then(b => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", files[0].fileName);
        a.click();
      });
    });
  } else {
    let zip = new JSZip();
    let count = 0;
    let name = saveName + "_files.zip";
    files.forEach(fileInfo => {
      var url = fileInfo.file.url;
      var fileName = fileInfo.fileName;
      JSZipUtils.getBinaryContent(url, function(err, data) {
        if (err) {
          throw err;
        }
        zip.file(`${fileName}`, data, { binary: true });
        count++;
        if (count === files.length) {
          zip.generateAsync({ type: "blob" }).then(function(content) {
            saveAs(content, name);
          });
        }
      });
    });
  }
};
