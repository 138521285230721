import React, { useEffect, useState } from "react";
import { IconScrollTop } from "../Icons";
import "./styleds.css";

const ScrollToTopBtn = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollTop, setScrollTop] = useState(false);

  const toggleVisibility = () => {
    // if (window.isMenu) return;
    const stateY = window && window.pageYOffset;
    setScrollTop(stateY);
    if (stateY > 400) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, [scrollTop]);

  return (
    <div
      className="ScrollToTopPc"
      style={{ display: isVisible ? "block" : "none" }}
      onClick={(e) => {
        // let userAgent = window.navigator.userAgent.toLowerCase();
        // let vendor = window.navigator.vendor.toLowerCase();
        let target = document.getElementById("root");
        // if (
        //   userAgent.indexOf("safari") >= 0 &&
        //   vendor.indexOf("apple") >= 0
        // ) {
        //   scrollTo(document.body, target.offsetTop, 100);
        // } else {
        //   target.scrollIntoView({
        //     behavior: "smooth",
        //   });
        // }
        target.scrollIntoView({
          behavior: "smooth",
        });
      }}
    >
      <IconScrollTop />
    </div>
  );
};

export default ScrollToTopBtn;
