import React from "react";
import Slider from "react-slick";
import "./HeroCarousel.css";
import useWindowWidth from "../../components/useWindowWidth";

const HeroCarousel = (props) => {
  const { contents = [], prevClass = "" } = props;
  const settingSmall = {
    arrows: false,
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 8000,
    variableWidth: false
  };
  const windowWidth = useWindowWidth(100);
  const imgHeight = (windowWidth < 744) ? "250px" : ((windowWidth < 1024) ? "440px" : "372px")

  const _className = `heroCarousel2 ${prevClass}`

  return (
    <div className={_className}>
      {contents.length > 0 && (
        <Slider {...settingSmall}>
          {contents.map((image, index) => {
            return (
              <div className="carouselImg2" key={index}>
                <img src={image.imageUrls[0].imageNormal} alt='' height={imgHeight} width="100%" />
              </div>
            );
          })}
        </Slider>
      )}
    </div>
  );
};
export default HeroCarousel;
