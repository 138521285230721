import React, { Fragment, useState, useEffect } from "react";
import Masonry from "react-masonry-component";
import { Typography, Button } from "antd";
import _get from "lodash/get";
import * as Axios from "axios";
import "./Instagram.css";
import styled from "styled-components";

const { Title, Text } = Typography;
const InsWrap = styled.div`
  width: 100%;
  // margin-top: 98px;
  @media (max-width: 576px) {
    margin-top: 10px;
  }
`;
const StyledTitle = styled(Title)`
  && {
    position: relative;
    // font-family: MaisonNeue-Bold;
    font-family: MaisonNeue-Demi;
    // font-size: 36px;
    font-size: 24px;
    // font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: left;
    // color: var(--dark);
    color: #000000;
    // margin-left: 40px;
    // margin-right: 40px;
    // margin-bottom: 16px;
    margin-bottom: 0px;
    padding-bottom: 24px;

    p {
      margin: 0;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      border-bottom: 1px solid #ebebeb;
    }

    @media (max-width: 576px) {
      // font-size: 21px;
      line-height: 1.14;
      margin-left: 16px;
      // margin-bottom: 2px;
    }
    @media (max-width: 1023px) {
      margin-left: 40px;
      margin-right: 40px;
      padding-bottom: 0px;

      &::after {
        visibility: hidden;
      }
    }
    @media (max-width: 743px) {
      margin-left: 24px;
      margin-right: 24px;
    }
    @media (min-width: 1024px) {
      margin-left: 60px;
      margin-right: 60px;
    }
    @media (min-width: 1280px) {
      margin-left: 140px;
      margin-right: 140px;
    }
  }
`;
const StyledText = styled(Text)`
  && {
    width: 137px;
    height: 40px;
    font-family: MaisonNeue-Medium;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.9;
    letter-spacing: normal;
    text-align: center;
    // color: var(--dark);
    color: #979797;
    // opacity: 0.5;

    @media (max-width: 1023px) {
      font-size: 14px;
      line-height: 1.43;
      display: block;
      margin-top: 16px;
      text-align: left;
      height: auto;
    }
  }
`;
const StyledLoadMore = styled(Text)`
  && {
    font-family: MaisonNeue-Demi;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 40px;
    letter-spacing: 2px;
    color: var(--dark);
    text-align: center;
    position: relative !important;

    @media (max-width: 576px) {
      font-size: 14px;
      line-height: 1;
      color: var(--dark);
    }
  }
`;

const StyledLoadMoreButton = styled(Button)`
  && {
    width: 161px;
    height: 48px;
    border-radius: 40px;
    border: solid 1px var(--dark);
    background-color: var(--white);
    margin-right: 24px;
    margin-bottom: 72px;
    padding-top: 5px;
    text-align: center;
    position: relative !important;

    @media (max-width: 576px) {
      width: 143px;
      height: 40px;
      margin-bottom: 24px;
    }
  }
  display: ${props => (props.visible ? "none" : "inline")};
`;

const masonryOptions = {
  transitionDuration: 0,
  itemSelector: ".item",
  columnWidth: ".sizer",
  percentPosition: true,
  gutter: 0,
  isOriginLeft: true
};

// 创建Video
const renderVideo = (uri, key) => {
  // autoplay; 
  return (
    <div className="Instagram-video" key={key}>
      <video controls autoplay src={uri} style={{width:'100%',height:'100%'}}></video>
      {/* <iframe
        title="InstagramVideo"
        width="100%"
        height="100%"
        src={uri}
        frameBorder="0"
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      /> */}
    </div>
  );
}

// 资源解码
const renderItem = (data) => {
  // video-ams2 video-ams4
  // const videoReg = new RegExp("video-ams2-1.cdninstagram.com", "gi");
  const videoReg = new RegExp("video-", "gi");
  const image = data.map((pic, index) => {
    const media_url = pic.media_url;
    const isVideo = videoReg.test(media_url)
    return (
      <div className="item" key={index}>
        {
          !isVideo ? <img src={media_url} alt="img" /> : renderVideo(media_url, index)
        }
      </div>
    );
  });
  return image;
}

const Instagram = () => {
  const [pics, setPics] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  var access_token = "IGQVJWWU8xMzczcFZASZAnVtUXJscm1GOHY3c1NSaG1OT3FwaUp5ZAWdHV1BHSUE2ekVJTUs5S0N2YmNVSVJzT1FEVVlVWWp2TjRiVHJJWHJUTmlJX3Y2ZADZAkMWVwYVNjaHpTNHJMZAUFkUFdrc2JRanNnawZDZD";
  useEffect(() => {
    Axios.get(
      "https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=" + access_token
    ).then(res => {
      access_token = _get(res, "data.access_token");
      Axios.get(
        "https://graph.instagram.com//me/media?fields=media_url,permalink&access_token=" + access_token
      ).then(res => {
        const data = _get(res, "data.data", []);
        // const image = data.map((pic, index) => {
        //   return (
        //     <div className="item" key={index}>
        //       <img src={pic.media_url} alt="img" />
        //     </div>
        //   );
        // });
        const image = renderItem(data);
        setPics(image);
      });
    })
  }, []);

  const handleOnClick = () => {
    setLoadMore(true);
  };
  const displayTotal =
    window.innerWidth >= 1440 ? 10 : window.innerWidth >= 1024 ? 8 : 6;
  const showPocs = loadMore ? pics : pics.slice(0, displayTotal);

  return (
    <Fragment>
      {showPocs.length > 0 && (
        <InsWrap>
          <StyledTitle>
            <p>Find Us On Instagram <StyledText>@galleryallofficial</StyledText></p>
            <div
              className="follow-us"
              onClick={params =>
                window.location.assign("https://www.instagram.com/galleryallofficial/")
              }
            >
              <span>Follow us</span>
              <span className="icon">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M1.52394 6.68179C1.1071 6.68389 0.766627 6.38689 0.761963 6.0171C0.766627 5.64732 1.1071 5.35032 1.52394 5.35241L10.2801 5.35241L6.52936 1.83032C6.39537 1.70922 6.31854 1.5414 6.31643 1.36522C6.31643 1.19858 6.38741 1.03288 6.52936 0.866235C6.65959 0.738729 6.83819 0.666829 7.0247 0.666829C7.2112 0.666829 7.3898 0.738729 7.52004 0.866235L11.6247 4.72162C11.9786 5.08692 12.1905 5.51812 12.1905 6.0171C12.1905 6.4822 11.9786 6.9473 11.6247 7.27964L7.52004 11.1341C7.3898 11.2616 7.2112 11.3335 7.0247 11.3335C6.83819 11.3335 6.65959 11.2616 6.52936 11.1341C6.39391 11.014 6.31695 10.8455 6.31695 10.669C6.31695 10.4925 6.39391 10.324 6.52936 10.2039L10.2801 6.68179L1.52394 6.68179Z" fill="black" />
                </svg>
              </span>
            </div>
          </StyledTitle>
          <Masonry
            className={"layout layoutv2"} // default ''
            options={masonryOptions} // default {}
            disableImagesLoaded={false} // default false
            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
          >
            <div className="sizer">{showPocs}</div>
          </Masonry>
          <StyledLoadMoreButton
            onClick={handleOnClick}
            visible={loadMore ? 1 : 0}
          >
            <StyledLoadMore>LOAD MORE</StyledLoadMore>
          </StyledLoadMoreButton>
          {/* <a>
            <StyledFollowButton
              onClick={params =>
                window.location.assign("https://www.instagram.com/galleryallofficial/")
              }
            >
              <StyledFollow>FOLLOW US ON INSTAGRAM</StyledFollow>
            </StyledFollowButton>
          </a> */}
        </InsWrap>
      )}
    </Fragment>
  );
};

export default Instagram;
