import React, { Fragment } from "react";
import { IconPlay } from "../Icons";

const VrDialog = ({ linkVr }) => {
  return (
    <Fragment>
      <div className="sm_VrDialog">
        <a href={linkVr} target="_blank" rel="noopener noreferrer">
          <IconPlay />
        </a>
      </div>
    </Fragment>
  );
};

export default VrDialog;
