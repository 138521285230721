import {useState, useEffect} from "react";

export default function useWindowWidth(){
  const [width, setWidth] = useState(window.innerWidth);
  let lastWidth = window.innerWidth;

  const setWidthOver = interval => {
      let current = window.innerWidth
      let diff = current - lastWidth
      if(current < lastWidth) diff = -diff
      if(diff > interval){
        setWidth(current)
        lastWidth = current
      }
  }
  
  useEffect(() => {
    const handleResize = () => {setWidthOver(100)};
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[]);
  
  return width;
}