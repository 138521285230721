import React from "react";
import HeaderNav from "../HeaderNav_Ver2";
import CollectionMondrian from "../Collection/CategoryMondrian_Ver2";
import useWindowWidth from "../../components/useWindowWidth";

const getColumnSize = (offsetWidth) => {
  if (offsetWidth < 744) {
    return 4
  } else if (offsetWidth < 1024) {
    return 6
  } else if (offsetWidth < 1280) {
    return 9
  }
  return 16
}

const OtherCollections = (props) => {
  const {
    collections = {}
  } = props;
  
  const offsetWidth = useWindowWidth(100)
  const size = getColumnSize(offsetWidth);

  return (
    <>
      <div className="collections_divider"></div>
      <div className="collections_other_container">
        <HeaderNav 
          info={collections}
        />
        <CollectionMondrian
          collections={collections.contents}
        />
      </div>
    </>
  );
};

export default OtherCollections;
