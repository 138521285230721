import React from "react";
import "../WorkFromCollection.css";
import { Col, Row } from "antd";
// import CommonButton from "../../Button";
// import GridLayout from "../../CollectionLayout";
import { CollectionWorksArt } from "../Enum"
import { toUrl } from "../../../utilities/utilities"

function getImgAndLink(item) {
  return (
    <a key={item.id} href={toUrl(item.uri)} className={`artists-item-link`}>
      <div className="artists-item">
        <div className="artists-item-image">
          <img src={item.imageUrls[0].coverImageNormal} alt={item.name} />
        </div>
        <div className="artists-item-info">
          <div className="artists-item-name">{item.name}</div>
        </div>
      </div>
    </a>
  );
}
  // 滑动页面到指定位置(锚点功能)
  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

const ArtistsContainer = (props) => {
  const {artists} = props;

  const listData = artists.contents.slice(0, 5)
  const listData4 = [listData.slice(0, 2), listData.slice(2, 4)];
  return (
    <div className="home_nav">
      <div id="Artists-News" className="sectionNavs">
        <div className="left_nav">
          <div className="nav_group">
            <div className="nav_text">
              {CollectionWorksArt}
            </div>
            <ul className="nav_btn">
              <li onClick={() => scrollToAnchor("screenCollection")}>Collections</li>
              <li onClick={() => scrollToAnchor("screenWorks")}>Works</li>
              <li className="active" onClick={() => scrollToAnchor("screenArtists")}>Artists</li>
            </ul>
          </div>
        </div>
        <div className="right_content">
          <div className="content-item artists_centent">
            {listData.map((artist) => {
              return (
                <a key={artist.id} href={toUrl(artist.uri)} className="artists-link">
                  <div className="artists_group">
                    <div className="artists_group_image">
                      <img src={artist.imageUrls[0].coverImageNormal} alt={artist.name} />
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
          <div className="content-item artists-1024">
            {listData4.map((artist, index) => {
              return (
                <Row key={index} type="flex" justify="space-around" align="middle">
                  <Col className="pr20" span={12}>{getImgAndLink(artist[0])}</Col>
                  <Col className="pl20" span={12}>{getImgAndLink(artist[1])}</Col>
                </Row>
              );
            })}
          </div>
          <div className="more-btn">
            <div className="more_group">
              <a href={toUrl(artists.uri)}>
                <span>{artists.seeAll}</span>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M1.33333 7.85204C0.812275 7.85466 0.386689 7.48341 0.380859 7.02117C0.386689 6.55894 0.812275 6.18769 1.33333 6.19031L12.2785 6.19031L7.5901 1.78769C7.42262 1.63632 7.32658 1.42654 7.32395 1.20632C7.32395 0.99802 7.41267 0.790894 7.5901 0.582589C7.75289 0.423207 7.97615 0.333332 8.20928 0.333332C8.4424 0.333332 8.66566 0.423207 8.82845 0.582589L13.9593 5.40182C14.4017 5.85845 14.6666 6.39744 14.6666 7.02117C14.6666 7.02118 14.6666 7.02118 14.6666 7.02118C14.6666 7.60255 14.4017 8.18392 13.9593 8.59935L8.82845 13.4174C8.66566 13.5768 8.4424 13.6667 8.20928 13.6667C7.97615 13.6667 7.75289 13.5768 7.5901 13.4174C7.42079 13.2673 7.32459 13.0567 7.32459 12.836C7.32459 12.6154 7.42079 12.4048 7.5901 12.2547L12.2786 7.85204L1.33333 7.85204Z" fill="black" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ArtistsContainer;