import React, { useState } from "react";
// import "./WorkDetail.css";
import { PrintPDF } from "../../components/PrintPDF";
import { DownloadImage } from "../../components/DownloadImage";
import { DownLoadIconV2 } from "../../components/Icons";
import { forEach } from "lodash";
import { toUrl } from "../../utilities/utilities";

const editionCode = (edition, proof) => {
  if (edition !== "") {
    if (edition === 1) {
      return "Unique";
    }

    if (proof === 0) {
      return `Edition of ${edition}`;
    }

    return proof !== ""
      ? `Edition of ${edition}+${proof}AP`
      : `Edition of ${edition}`;
  } else return "";
};

const RenderWorkDetail = (props) => {
  const {
    artwork={},
    images=[]
  } = props;

  const artworkImageUrls = [];
  forEach(images, function(image) {
    artworkImageUrls.push(image.imageUrls[0].imageNormal);
  });
  const clickOnImg = (i) => {
    setSelectedImg(i);
  }

  const handleHiResOnClick = () => {
    DownloadImage(artworkImageUrls, artwork.name);
  };

  const [selectedImg, setSelectedImg] = useState(0);

  return (
    <React.Fragment>
      <div className="wk_warp_pc">
        <div className="wk_slick-slider-customize">
          <div className="wk_work-selected-image">
            {artworkImageUrls.map((image, index) => {
              if (index !== selectedImg) {
                return "";
              }
              return (
                <div key={index} id={index} onClick={() => clickOnImg(index)}>
                  <img
                    className="wk_slider_image"
                    src={image}
                    alt={`image${index}`}
                  />
                </div>
              );
            })}
          </div>
          <div className="wk_work-slider-outside-div ">
            <div className="wk_work-slider-div">
              {
                artworkImageUrls.map((image, index) => {
                  return (
                    <div key={index} className="wk_slider-img-div" id={index} onClick={() => clickOnImg(index)}>
                      <img
                        className="wk_slider_image"
                        src={image}
                        alt={`image${index}`}
                      />
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
        <div className="wk_renderWorkDetail_text">
          <a href={`http://${window.location.host}/artists/${artwork.artistId}`}>
            <div className="wk_workdetail_artist">{artwork.artistName}</div>
          </a>
          <div className="wk_workdetail_title">{artwork.name} </div>
          {
            artwork.collectionName &&
            <React.Fragment>
              <div className="wk_title_gray">Collection</div>
              <a href={toUrl(artwork.collectionUri)}>
                <div className="wk_workdetail_edition">{artwork.collectionName}</div>
              </a>
            </React.Fragment>
          }
          <div className="wk_workdetail_container">
            {editionCode(artwork.edition, artwork.proof) !== "" && artwork.edition != 0 && (
              <React.Fragment>
                <p className="title">Edition Size</p>
                <p className="data">{editionCode(artwork.edition, artwork.proof)}</p>
              </React.Fragment>
            )}
          </div>
          <div className="wk_workdetail_container">
            <p className="title">Dimension</p>
            <table className="data">
              <tbody>
                <tr>
                  <td>
                    <span>CM</span>
                  </td>
                  <td>
                    <span>W</span>
                    {(artwork.sizeWidth * 2.54).toFixed(1)}
                  </td>
                  <td>
                    <span>D</span>
                    {(artwork.sizeDepth * 2.54).toFixed(1)}
                  </td>
                  <td>
                    <span>H</span>
                    {(artwork.sizeHeight * 2.54).toFixed(1)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>IN</span>
                  </td>
                  <td>
                    <span>W</span>
                    {(artwork.sizeWidth).toFixed(1)}
                  </td>
                  <td>
                    <span>D</span>
                    {(artwork.sizeDepth).toFixed(1)}
                  </td>
                  <td>
                    <span>H</span>
                    {(artwork.sizeHeight).toFixed(1)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="wk_workdetail_container">
            <p className="title">Materials</p>
            <p className="data">{artwork.material}</p>
          </div>
          <div className="wk_ButtonWrap">
            <div className="btn-res">
              <div className="wk_button_pc" onClick={params => window.location.assign("mailto:info@galleryall.com")} >
                INQUIRE
              </div>
            </div>
            <div className="wk_downloadIconWrap">
              <div className="c__download">
                <div className={` pc__download`} onClick={PrintPDF}>
                  <span className="download_icon">
                    <DownLoadIconV2 />
                  </span>
                  <span className="download_text">PDF</span>
                </div>
                <div className={` pc__download`} onClick={handleHiResOnClick}>
                  <span className="download_icon">
                    <DownLoadIconV2 />
                  </span>
                  <span className="download_text">All Images</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};

export default RenderWorkDetail;
