import React from "react";
import Slider from "react-slick";
import { toUrl } from "../../../utilities/utilities";

const PressesContainer = (props) => {

  const {presses} = props;

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    arrow: true,
    focusOnSelect: true
  };

  return (
    <div>
      <div className="smallSliderHeader">
        <div className="work_title">{presses.name}</div>
        <div className="work_Slider"> </div>
      </div>
      <div className="slick-container">
        <Slider {...settings}>
          {presses.contents.map((press)=>{
            return (
              <a key={press.id} href={toUrl(press.uri)}>
                <div className="newsCard">
                  <div className="news-thumb">
                    <img src={press.imageUrls[0].coverImageNormal} alt={press.name} />
                  </div>
                  <div className="news-content">
                    <div className="news_title">{press.name}</div>
                    <div className="news_description">{press.description.substr(0, 300) + "..."}</div>
                    <div className="news_read_more">
                      <span>Read More</span>
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.52369 6.68161C1.10685 6.6837 0.766383 6.3867 0.761719 6.01692C0.766383 5.64713 1.10685 5.35013 1.52369 5.35223L10.2799 5.35223L6.52911 1.83013C6.39513 1.70903 6.3183 1.54121 6.31619 1.36504C6.31619 1.1984 6.38716 1.03269 6.52911 0.866052C6.65934 0.738546 6.83795 0.666646 7.02445 0.666646C7.21095 0.666646 7.38956 0.738546 7.51979 0.866052L11.6245 4.72144C11.9784 5.08674 12.1903 5.51794 12.1903 6.01692C12.1903 6.48202 11.9784 6.94712 11.6245 7.27946L7.51979 11.1339C7.38956 11.2614 7.21095 11.3333 7.02445 11.3333C6.83795 11.3333 6.65934 11.2614 6.52911 11.1339C6.39367 11.0138 6.3167 10.8453 6.3167 10.6688C6.3167 10.4923 6.39367 10.3238 6.52911 10.2037L10.2799 6.68161L1.52369 6.68161Z" fill="#979797" />
                      </svg>
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
        </Slider>
      </div>
    </div>
  )
}

export default PressesContainer;