import React from "react";
import Slider from "react-slick";
import "./HeroCarousel.css";

const settingSmall = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 8000
};

const HeroCarouselMobile = (props) => {
  const {
    images = []
  } = props;
  return (
    <div className="HeroCarouselMobile">
      <Slider {...settingSmall}>
        {images.map((image,index) => {
          return (
            <div className="carouselImg_works" key={index}>
              <img
                src={image}
                alt={`image${index}`}
                height="249px"
                width="100%"
              />
            </div>
          );
        })}
      </Slider>
    </div>
  )
}

export default HeroCarouselMobile;