import React, { useState } from "react";
import { Divider } from "antd";
import { PrintPDF } from "../../components/PrintPDF";
import { DownloadImage } from "../../components/DownloadImage";
import { DownLoadIconV2 } from "../../components/Icons";
import { IconDetailRArrow } from "../../images/indexSvg";
import HeroCarouselMobile from "./HeroCarouselMobile_Ver2";
import { forEach } from "lodash";
import { toUrl } from "../../utilities/utilities";

const editionCode = (edition, proof) => {
  if (edition !== "") {
    if (edition === 1) {
      return "Unique";
    }

    if (proof === 0) {
      return `Edition of ${edition}`;
    }

    return proof !== ""
      ? `Edition of ${edition}+${proof}AP`
      : `Edition of ${edition}`;
  } else return "";
};

function createTable(
  widthCM,
  depthCM,
  heightCM,
  widthIN,
  depthIN,
  heightIN,
  mClass=''
) {
  const wm_ctable_class = `wm_ctable ${mClass}`;
  return (
    <div className={wm_ctable_class}>
      <p className="title">Dimension</p>
      <table className="data">
        <tbody>
          <tr>
            <td>
              <span>CM</span>
            </td>
            <td>
              <span>W</span>
              {widthCM}
            </td>
            <td>
              <span>D</span>
              {depthCM}
            </td>
            <td>
              <span>H</span>
              {heightCM}
            </td>
          </tr>
          <tr>
            <td>
              <span>IN</span>
            </td>
            <td>
              <span>W</span>
              {widthIN}
            </td>
            <td>
              <span>D</span>
              {depthIN}
            </td>
            <td>
              <span>H</span>
              {heightIN}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

const RenderWorkDetailMobile = (props) => {
  const {
    artwork = {},
    images = []
  } = props;
  
  const [selectedImg, setSelectedImg] = useState(0);
  
  const artworkImageUrls = [];
  forEach(images, function(image) {
    artworkImageUrls.push(image.imageUrls[0].imageNormal);
  });

  const handleHiResOnClick = () => {
    DownloadImage(artworkImageUrls, artwork.name);
  };

  const clickOnImg = (i) => {
    setSelectedImg(i);
  };

  // const displayTotal = window.innerWidth >= 1440 ? 1 : images.length;

  // const settings = {
  //   customPaging: (i) => {
  //     return (
  //       <a>
  //         <img src={images[i].imageThumbnail.url} alt={images[i].objectId} />
  //       </a>
  //     );
  //   },
  //   dots: true,
  //   infinite: false,
  //   slidesToShow: displayTotal,
  //   slidesToScroll: 1,
  //   vertical: true,
  //   verticalSwiping: true,
  //   swipeToSlide: true,
  //   arrows: false,
  // };

  return (
    <React.Fragment>
      <div className={`wk_warp_mobile ${!artwork.collectionName !=='' ? "swDetail" : ""}`}>
        <div className="w_m_text">
          <a href={`http://${window.location.host}/artists/${artwork.artistId}`}>
            <div className="s_name">{artwork.artistName}</div>
          </a>
          <div className="s_title">{artwork.name} </div>
        </div>
        <div className="w_m_buttonWrap fiexd">
          <div
            className="w_button_div"
            onClick={(params) =>
              window.location.assign("mailto:info@galleryall.com")
            }
          >
            INQUIRE
          </div>
        </div>
        <div className="w_m_downloadWrap">
          <div className="c__download">
            <div className={` pc__download`} onClick={PrintPDF}>
              <span className="download_icon">
                <DownLoadIconV2 />
              </span>
              <span className="download_text">PDF</span>
            </div>
            <div className={` pc__download`} onClick={handleHiResOnClick}>
              <span className="download_icon">
                <DownLoadIconV2 />
              </span>
              <span className="download_text">All Images</span>
            </div>
          </div>
        </div>
        <HeroCarouselMobile images={artworkImageUrls} />
        <div className="wk_collections_warp">
          {artwork.collectionName && (
            <div className="wm_ctitle">
              <div className="title_gray">Collection</div>
              <a href={toUrl(artwork.collectionUri)}>
                <div className="workdetail_edition">
                  {artwork.collectionName}
                  <span className="icon">
                    <IconDetailRArrow />
                  </span>
                </div>
              </a>
            </div>
          )}
          {editionCode(artwork.edition, artwork.proof) !== "" && artwork.edition != 0 && (
            <div className="wm_csize">
              <p className="title">Edition Size</p>
              <p className="data">{editionCode(artwork.edition, artwork.proof)}</p>
            </div>
          )}
          {createTable(
            (artwork.sizeWidth * 2.54).toFixed(1),
            (artwork.sizeDepth * 2.54).toFixed(1),
            (artwork.sizeHeight * 2.54).toFixed(1),
            (artwork.sizeWidth).toFixed(1),
            (artwork.sizeDepth).toFixed(1),
            (artwork.sizeHeight).toFixed(1)
          )}
        </div>
        {createTable(
          (artwork.sizeWidth * 2.54).toFixed(1),
          (artwork.sizeDepth * 2.54).toFixed(1),
          (artwork.sizeHeight * 2.54).toFixed(1),
          (artwork.sizeWidth).toFixed(1),
          (artwork.sizeDepth).toFixed(1),
          (artwork.sizeHeight).toFixed(1),
          "wm_ctable2"
        )}
        <div className="w_Materials">
          <p className="title">Materials</p>
          <p className="data">{artwork.material}</p>
        </div>
      </div>
      <Divider />
    </React.Fragment>
  );
};

export default RenderWorkDetailMobile;
