import React, { useState, useEffect } from "react";
import Masonry from "react-masonry-component";
import _get from "lodash/get";
import { Link } from "react-router-dom";
import { LazyImg } from "../../components/LazyImg";
import { WhiteArrowSvg } from "../../images/indexSvg";
import "../Version2/CategoryMondrian.css";
import "../Version2/Wrapper.css";
import useObserver from "../ResizeObserver";
import { setWapperStyle } from "../../utilities/utils";

function getColNum(w) {
  const scoll = 17;
  if (w >= 1280 - scoll) return 4;
  if (w >= 1024 - scoll) return 3;
  if (w >= 743 - scoll) return 2;
  return 1;
}

/**
 *
 * @param {ids, names, images}, comes from filter_result of useFilterBar
 */
const CategoryMondrian = ({ link_route = "", data = [], realWidth = false }) => {
  const [size, SetSize] = useState(0);
  const measuredRef = React.useRef(null);

  useObserver({
    element: measuredRef,
    callback: (element) => {
      const target = element[0].target;
      const offsetWidth = target.offsetWidth;
      let cn = getColNum(offsetWidth);
      if (realWidth) {
        cn = 2
      }
      SetSize(cn);
    },
  });

  useEffect(() => {
    return () => { };
  }, [size]);

  // 数据展示
  const renderCards = () => {
    let output = [];
    data.forEach((item, id) => {
      const objectId = _get(item, "objectId");
      const name = _get(item, "title");
      const artitsName = _get(item, "artitsName");
      const image = _get(item, "coverImageNormal.url");

      let ui = (
        <div key={id} className="column_item">
          <Link to={
            {
              pathname: `/${link_route}/${objectId}`,
              state: { fromNavRandom: Math.round(Math.random() * 100), }
            }
          } >
            <LazyImg src={image} key={id} alt="" />
            <div className="popup_box">
              <div className="popup_body">
                <div className="author">{artitsName}</div>
                <div className="title">{name}</div>
                <div className="icon">
                  <WhiteArrowSvg />
                </div>
              </div>
            </div>
          </Link>
        </div >
      );

      output.push(ui);
    });
    return output;
  };

  // 获取数据
  const renderColumns = (nums, artists) => {
    const columns = [];
    for (let i = 0; i < nums; i++) {
      columns.push([]);
    }
    artists.map((item, id) => {
      const current = (id + nums) % nums;
      columns[current].push(item);
      return "finished";
    });

    const columnsSize = columns.length;

    return columns.map((column, id) => {
      let array = ["common_column"];
      array = setWapperStyle(array, id, columnsSize);
      const _className = array.join(" ");

      return (
        <div className={_className} key={id}>
          {column}
        </div>
      );
    });
  };

  const masonryOptions = {
    itemSelector: ".never",
    columnWidth: ".never",
    isOriginLeft: true,
  };

  return (
    <div className="common_wrapper worksItems_wrapper" ref={measuredRef}>
      <Masonry className="c_Masonry" options={masonryOptions}>
        {size > 0 && renderColumns(size, renderCards())}
      </Masonry>
    </div>
  );
};

export default CategoryMondrian;
