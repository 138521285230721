import React from "react";
import { Checkbox, Row, Col } from "antd";
const NameOptions = (props) => {
  const {
    info = [],
    marked = [],
    setMarked = () => {}
  } = props;

  const handleOnClick = (value) => {
    const checked = value.target.checked;
    const checkedValue = value.target.value.split(',');
    if (checked) {
      setMarked(checkedValue[0], true);
    } else {
      setMarked(checkedValue[0], false)
    }
  }

  return (
    info.map((artist) => {
      let checked = false;
      if (marked.includes(artist.id)){
        checked = true;
      }
      return (
        <Col className="c_StyledCol_ver" key={artist.id} xs={24} sm={24} lg={8} xl={8}>
          <Row className="c_StyledRow_ver" span={24}>
            <Col span={3}>
              <Checkbox 
                value={[artist.id]}
                onClick={(value) => handleOnClick(value)}
                checked={checked}
                style={{ textAlign: "left" }}
              />
            </Col>
            <Col span={21}>
              <span className="c_StyledText_span_ver">{artist.name}</span>
            </Col>
          </Row>
        </Col>
      )
    })
  )
};

export default NameOptions;