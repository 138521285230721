import React, { useState, useEffect } from "react";
import Axios from "axios";
import FilterBar from "../../views_components/Collection/FilterBar_Ver2";
import ImgBanner from "../../views_components/Ongoing/ImgBanner_Ver2";
import CategoryMondrian from "../../views_components/Collection/CategoryMondrian_Ver2";
import ScrollToTopBtn from "../../views_components/ScrollToTopBtn";
// import { StyledLoadMore, StyledLoadMoreButton} from "../../views_components/LoadMoreButton_Ver2";


const Collection = (props) => {
  const {
    location = {},
  } = props;
  let searchedArtistId = undefined;
  if (location.search.split('=')[0] === '?artistId') {
    searchedArtistId = location.search.split('=')[1];
  }
  const numberOfItem = 24;
  const [ArtistFilterbar, setArtistFilterbar] = useState([]);
  const [ArtistIds, setArtistIds] = useState(undefined);
  const [Collections, setCollections] = useState([]);
  const [MarkedArtists, setMarkedArtists] = useState([]);
  const [PreTitleArtist, setPreTitleArtist] = useState('All');
  const [HightlightCollections, setHightlightCollections] = useState([]);
  const [Result, setResult] = useState([]);
  const [LoadMore, setLoadMore] = useState(true);
  const [Token, setToken] = useState(undefined);
  // 懒加载
  const toggleLoadMore = () => {
    var scrollTop = document.documentElement.scrollTop;
    var clientHeight = document.documentElement.clientHeight;
    var scrollHeight = document.documentElement.scrollHeight;
    if (LoadMore) {
      if(scrollTop+clientHeight >= scrollHeight && Result.length !== 0){
        // 异步操作
        setToken(Result.collections.token)
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleLoadMore);
    return () => {
      window.removeEventListener("scroll", toggleLoadMore);
    }
  }, [Result]);

  // filter bar 
  const [needFilterbar, setneedFilterbar] = useState(true);

  const getCollections = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebCollections", 
      {
        artistIds: ArtistIds,
        includesFilter: needFilterbar,
        token: Token
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }  
  const getCollectionsFromArtist = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebCollections", 
      {
        artistIds: [searchedArtistId],
        includesFilter: false,
        token: Token
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }
  if (searchedArtistId !== undefined) {
    useEffect(() => {
      getCollectionsFromArtist().then(result => {
        if (Token === undefined) {
          setCollections(result.data.result.collections.contents);
          setHightlightCollections(result.data.result.collections.highlightContents)
        } else if (result.data.result.collections.contents.length > 0) {
          let tempCollections = [...Collections];
          result.data.result.collections.contents.forEach(item => {
            tempCollections.push(item);
          })
          setCollections(tempCollections);
          if (result.data.result.collections.contents.length < numberOfItem) {
            setLoadMore(false);
          }
        } else {
          setLoadMore(false);
        }
        setResult(result.data.result);
      })
    }, [Token]);
  } else {
    useEffect(() => {
      getCollections().then(result => {
        if (needFilterbar) {
          setArtistFilterbar(result.data.result.artistFilters);
          setneedFilterbar(false);
        }
        if (Token === undefined) {
          setCollections(result.data.result.collections.contents);
          setHightlightCollections(result.data.result.collections.highlightContents)
        } else if (result.data.result.collections.contents.length > 0) {
          let tempCollections = [...Collections];
          result.data.result.collections.contents.forEach(item => {
            tempCollections.push(item);
          })
          setCollections(tempCollections);
          if (result.data.result.collections.contents.length < numberOfItem) {
            setLoadMore(false);
          }
        } else {
          setLoadMore(false);
        }
        setResult(result.data.result);
      });
    }, [Token, ArtistIds])
  }
  
  // 数据请求
  

  // Helper function remove element from an array
  function arrayRemove(arr, value) { 
    return arr.filter(function(ele){ 
        return ele !== value; 
    });
  }

  const setMarkedArtist = (value, isMarked) => {
    let tempMarkedArtists = [...MarkedArtists];
    if (isMarked){
      tempMarkedArtists.push(value);
      setPreTitleArtist('Selected');
      setMarkedArtists(tempMarkedArtists);
    } else {
      tempMarkedArtists = arrayRemove(tempMarkedArtists, value);
      if (tempMarkedArtists.length === 0) {
        setPreTitleArtist('All');
      }
      setMarkedArtists(tempMarkedArtists);
    }
  };

  const handleReset = () => {
    if (MarkedArtists.length !== 0) {
      setMarkedArtists([]);
      setCollections([]);
      setToken(undefined);
      setLoadMore(true);
      setPreTitleArtist('All');
      setHightlightCollections([]);
      setArtistIds(undefined);
    }
  };

  const handleConfirm = () => {
    if (MarkedArtists.length !== 0) {
      setCollections([]);
      setToken(undefined);
      setLoadMore(true)
      setHightlightCollections([]);
      setArtistIds(MarkedArtists);
    }
  };
  // 决定title
  let title = 'Collections'
  if (searchedArtistId !== undefined) {
    if (HightlightCollections.length === 1) {
      title = `All Collection from ${HightlightCollections[0].artistName}`
    } else if (HightlightCollections.length > 0) {
      title = `All Collections from ${HightlightCollections[0].artistName}`
    }
  }

  return (
    <div className="c_page">
      <div className="header_box">
        <div className="common_title">{title}</div>
        {
          (ArtistFilterbar.length !== 0) &&
          <div className="filter_bar">
            <FilterBar 
              info={ArtistFilterbar}
              marked={MarkedArtists}
              setMarked={setMarkedArtist}
              handleReset={handleReset}
              handleConfirm={handleConfirm}
              preTitleArtist={PreTitleArtist}
            />
          </div>
        }
      </div>
      <ImgBanner items = {HightlightCollections} />
      <CategoryMondrian collections={Collections}/>
      {/* {
        LoadMore &&
          <StyledLoadMoreButton
            onClick={() => setPage(Page + 1)}
          >
            <StyledLoadMore>LOAD MORE</StyledLoadMore>
          </StyledLoadMoreButton>
      } */}
      <ScrollToTopBtn />
    </div>
  )
};

export default Collection;
