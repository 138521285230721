import React from "react";
import Slider from "react-slick";
import useObserver from "../../views_components/ResizeObserver";
import { toUrl } from "../../utilities/utilities";

const ExhibitionView = (props) => {
  
  const {exhibitions} = props;
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 8000,
  };

  const measuredRef = React.useRef(null);

  useObserver({
    element: measuredRef,
    callback: (element) => {
      const target = element[0].target;
      const offsetWidth = target.offsetWidth;
      const liList = document.querySelectorAll("#heroImage li");
      const imgSize = exhibitions.contents.length;
      const mrWidth = imgSize * 12 - 12;
      let contentBoxPadding = 140 * 2;
      if (offsetWidth > 1023 && offsetWidth < 1280) {
        contentBoxPadding = 60 * 2;
      } else if (offsetWidth > 743 && offsetWidth < 1024) {
        contentBoxPadding = 40 * 2;
      } else if (offsetWidth < 744) {
        contentBoxPadding = 24 * 2;
      }
      const liWidth = Math.floor((offsetWidth - mrWidth - contentBoxPadding) / imgSize);
      for (var i = 0; i < liList.length; i++) {
        var ele = liList[i];
        ele.style.width = `${liWidth}px`;
      }
    },
  });

  return (
    <div id="heroImage" ref={measuredRef}>
      <Slider className="galleryV2" {...settings}>
        {exhibitions.contents.map((item) => {
          return (
            <div key={item.id} className="ant_carousel">
              <div className="placeholder">
                <a href={item.uri}>
                  <img src={item.imageUrls[0].coverImageNormal} alt={item.name} />
                </a>
              </div>
              <div className="desc_modal">
                <div className="desc_group">
                  <div className="desc_category">
                    <a href={toUrl(item.uri)}>{exhibitions.name}</a>
                  </div>
                  <div className="desc_name">
                    <a href={toUrl(item.uri)}>{item.name}</a>
                  </div>
                  <div className="desc_description">
                    <a href={toUrl(item.uri)}>{item.description.split(".")[0]}</a>
                  </div>
                  <div className="desc_more">
                    <a href={toUrl(item.uri)}>
                      <span>{exhibitions.seeAll}</span>
                      <svg
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.856734 6.76683C0.387786 6.76919 0.0047586 6.43507 -0.000488281 6.01906C0.0047586 5.60305 0.387786 5.26892 0.856734 5.27128L10.7075 5.27128L6.48786 1.30892C6.33713 1.17269 6.2507 0.983887 6.24833 0.785692C6.24833 0.598219 6.32817 0.411804 6.48786 0.224331C6.63438 0.0808878 6.83531 0 7.04512 0C7.25494 0 7.45587 0.0808878 7.60238 0.224331L12.2201 4.56164C12.6183 4.9726 12.8567 5.4577 12.8567 6.01906C12.8567 6.5423 12.6183 7.06553 12.2201 7.43942L7.60238 11.7757C7.45587 11.9191 7.25494 12 7.04512 12C6.83531 12 6.63438 11.9191 6.48786 11.7757C6.33549 11.6406 6.2489 11.451 6.2489 11.2524C6.2489 11.0539 6.33549 10.8643 6.48786 10.7292L10.7075 6.76683L0.856734 6.76683Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  )
};

export default ExhibitionView;
