import React, { useEffect, useState } from "react";
import Axios from "axios";
import FilterBar from "../../views_components/Artists/FilterBar_Ver2";
import CategoryMondrian from "../../views_components/Artists/CategoryMondrian_Ver2";
import ScrollToTopBtn from "../../views_components/ScrollToTopBtn";
import { mobileEnum } from "./ArtistFunc";
import { IconArtDownArrow } from "../../images/indexSvg";
// import { StyledLoadMore, StyledLoadMoreButton} from "../../views_components/LoadMoreButton_Ver2";

const Artist = (props) => {
  const {
    location ={},
  } = props;
  let searchedExhibitionId = undefined;
  if (location.search.split('=')[0] === '?exhibitionId') {
    searchedExhibitionId = location.search.split('=')[1];
  }
  const [ArtistFilterbar, setArtistFilterbar] = useState([]);
  const [Artists, setArtists] = useState([]);
  const [ArtistIds, setArtistIds] = useState(undefined);
  const [mobileText, setMobileText] = useState("All Artists");
  const [userFilter, setUserFilter] = useState("");
  const [currentType, setCurrentType] = useState(0);
  const [MarkedArtists, setMarkedArtists] = useState([]);
  const [pre_title, setpre_title] = useState('All');
  const [VisualArtists, setVisualArtists] = useState([]);
  const [FunctionalArtists, setFunctionalArtists] = useState([]);
  const numberOfItem = 24;
  const [Page, setPage] = useState(0);
  const [LoadMore, setLoadMore] = useState(true);

  // filter bar 
  const [needFilterbar, setneedFilterbar] = useState(true);
  // 懒加载
  const toggleLoadMore = () => {
    var scrollTop = document.documentElement.scrollTop;
    var clientHeight = document.documentElement.clientHeight;
    var scrollHeight = document.documentElement.scrollHeight;
    if (LoadMore) {
      if(scrollTop+clientHeight >= scrollHeight){
        // 异步操作
        setTimeout(setPage(Page+1),0);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleLoadMore);
    return () => {
      window.removeEventListener("scroll", toggleLoadMore);
    }
  }, [Page]);

  const getArtists = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebArtists", 
      {
        artistIds: ArtistIds,
        includesFilter: needFilterbar,
        page: Page
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }
  
  const getArtistsFromExhibition = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebArtists", 
      {
        includesFilter: false,
        page: Page,
        exhibitionId: searchedExhibitionId
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }
  if (searchedExhibitionId !== undefined) {
    useEffect(() => {
      getArtistsFromExhibition().then(result => {
        if (result.data.result.artists.contents.length !== 0) {
          let tempArtists = [...Artists];
          let tempVisualArtists = [...VisualArtists];
          let tempFunctionalArtists = [...FunctionalArtists];
          result.data.result.artists.contents.forEach(item => {
            tempArtists.push(item);
            if (item.isVisualArt) {
              tempVisualArtists.push(item);
            }
            if (item.isFunctionalArt) {
              tempFunctionalArtists.push(item);
            }
          });
          setArtists(tempArtists);
          setVisualArtists(tempVisualArtists);
          setFunctionalArtists(tempFunctionalArtists);
          if (result.data.result.artists.contents.length < numberOfItem) {
            setLoadMore(false);
          }
        } else {
          setLoadMore(false);
        }
      })
    }, [Page])
  } else {
    useEffect(() => {
      getArtists().then(result => {
        if (needFilterbar){ 
          setArtistFilterbar(result.data.result.artistFilters);
          setneedFilterbar(false);
        }
        if (result.data.result.artists.contents.length !== 0) {
          let tempArtists = [...Artists];
          let tempVisualArtists = [...VisualArtists];
          let tempFunctionalArtists = [...FunctionalArtists];
          result.data.result.artists.contents.forEach(item => {
            tempArtists.push(item);
            if (item.isVisualArt) {
              tempVisualArtists.push(item);
            }
            if (item.isFunctionalArt) {
              tempFunctionalArtists.push(item);
            }
          })
          setArtists(tempArtists);
          setVisualArtists(tempVisualArtists);
          setFunctionalArtists(tempFunctionalArtists);
          if (result.data.result.artists.contents.length < numberOfItem) {
            setLoadMore(false);
          }
        } else {
          setLoadMore(false);
        }
      });
    }, [ArtistIds, Page]);
  }

  // Helper function remove element from an array
  function arrayRemove(arr, value) { 
    return arr.filter(function(ele){ 
        return ele != value; 
    });
  }

  // 筛选类型数据
  const onFilterType = (currentType) => {
    setCurrentType(currentType);
    setUserFilter(false);
    setMobileText(mobileEnum[currentType]);
  };

  const setMarkedArtist = (value, isMarked) => {
    let tempMarkedArtists = [...MarkedArtists];
    if (isMarked){
      tempMarkedArtists.push(value);
      setpre_title('Selected');
      setMarkedArtists(tempMarkedArtists);
    } else {
      tempMarkedArtists = arrayRemove(tempMarkedArtists, value);
      if (tempMarkedArtists.length === 0) {
        setpre_title('All');
      }
      setMarkedArtists(tempMarkedArtists);
    }
  }

  const handleReset = () => {
    if (MarkedArtists.length !== 0) {
      setMarkedArtists([]);
      setPage(0);
      setArtists([]);
      setVisualArtists([]);
      setFunctionalArtists([]);
      setLoadMore(true);
      setpre_title('All');
      setArtistIds(undefined);
    }
  };

  const handleConfirm = () => {
    if (MarkedArtists.length !== 0) {
      setArtists([]);
      setVisualArtists([]);
      setFunctionalArtists([]);
      setPage(0);
      setArtistIds(MarkedArtists);
      if (MarkedArtists.length <= numberOfItem) {
        setLoadMore(false);
      } else {
        setLoadMore(true);
      }
    }
  };

  return (
    <div className="c_page">
      <div className="header_box header_solid artist_header">
        <div className="common_title">Artists</div>
        <div
          className="filter_title"
          onClick={() => {
            setUserFilter(!userFilter);
          }}
        >
          {mobileText}
          <IconArtDownArrow />
        </div>
        <ul
          className="type_ul"
          style={{ display: userFilter ? "block" : "none" }}
        >
          <li className={currentType === 0 ? 'active' : ''} onClick={() => { onFilterType(0) }}>All Artists</li>
          <li className={currentType === 1 ? 'active' : ''} onClick={() => { onFilterType(1) }}>Visual Art</li>
          <li className={currentType === 2 ? 'active' : ''} onClick={() => { onFilterType(2) }}>Functional Art</li>
        </ul>
        {(ArtistFilterbar.length !== 0) &&
          <div className="fileterBarRow">
          <FilterBar 
            info={ArtistFilterbar}
            marked={MarkedArtists}
            setMarked={setMarkedArtist}
            handleReset={handleReset}
            handleConfirm={handleConfirm}
            pre_title={pre_title}
            currentType={currentType}
            setFilterType={onFilterType}
          />
          </div>}
      </div>
      {
        (Artists.length !== 0) &&
        <div>
        {(currentType === 0) && <CategoryMondrian artists={Artists}/>}
        {(currentType === 1) && <CategoryMondrian artists={VisualArtists}/>}
        {(currentType === 2) && <CategoryMondrian artists={FunctionalArtists}/>}
        </div>
      }
      {/* {
        LoadMore &&
          <StyledLoadMoreButton
            onClick={() => setPage(Page + 1)}
          >
            <StyledLoadMore>LOAD MORE</StyledLoadMore>
          </StyledLoadMoreButton>
      } */}
      <ScrollToTopBtn />
    </div>
  )
};

export default Artist;