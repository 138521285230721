import React from "react";
import { Checkbox, Row, Col } from "antd";

const NameOptions = (props) => {
  const {
    info = [],
    marked = [],
    setMarked = () => {}
  } = props;

  const handleOnClick = (value) => {
    const checked = value.target.checked;
    const checkedValue = Number(value.target.value);
    if (checked) {
      setMarked(checkedValue, true);
    } else {
      setMarked(checkedValue, false)
    }
  }

  return (
    info.map((item) => {
      let checked = false;
      if (marked.includes(item)){
        checked = true;
      }
      return (
        <Col className="c_StyledCol_ver" key={item} xs={24} sm={24} lg={12} xl={12}>
          <Row className="c_StyledRow_ver" span={24}>
            <Col span={3}>
              <Checkbox 
                value={item}
                onClick={(value) => handleOnClick(value)}
                checked={checked}
                style={{ textAlign: "left" }}
              />
            </Col>
            <Col span={21}>
              <span className="c_StyledText_span_ver">{item}</span>
            </Col>
          </Row>
        </Col>
      )
    })
  )
};

export default NameOptions;