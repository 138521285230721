import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";

import RenderWorkDetail from "../../views_components/WorkDetail/RenderWorkDetail_Ver2";
import RenderWorkDetailMobile from "../../views_components/WorkDetail/RenderWorkDetailMobile_Ver2";
import WorkDetailVideo from "../../views_components/WorkDetail/WorkDetailVideo_Ver2";
import WorkDetailArtist from "../../views_components/WorkDetail/WorkDetailArtist_Ver2";
import WorkDetailCollection from "../../views_components/WorkDetail/WorkDetailCollection_Ver2";
// import WorkDetailExhibition from "../../views_components/WorkDetail/WorkDetailExhibition_Ver2";

import "../../scss/WorkDetail.css";
import "../../scss/WorkDetailVer2.css";


const Workdetails = (props) => {

  const {
    match = {},
  } = props;
  const [ArtworkInfo, setArtworkInfo] = useState([]);
  const topPage = useRef(null);

  const getArtworkDetail = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebArtworkDetail",
      {
        artworkId: match.params.id
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }
  useEffect(() => {
    getArtworkDetail().then(result => {
      setArtworkInfo(result.data.result);
    });
  }, [match.params.id]);
  // console.log(ArtworkInfo)
  // 数据成功返回
  if (ArtworkInfo.length !== 0) {
    return (
      <div ref={topPage}>
        <div className="WorkDetailVer2">
          <div className="vw__Desktop">
            <RenderWorkDetail
              artwork={ArtworkInfo.artwork.content}
              images={ArtworkInfo.images.contents}
            />
          </div>
          <div className="vw__Mobile wMobile">
            <RenderWorkDetailMobile
              artwork={ArtworkInfo.artwork.content}
              images={ArtworkInfo.images.contents}
            />
          </div>
          <WorkDetailVideo
           artwork={ArtworkInfo.artwork.content}
          />
          <div className="vw__Desktop">
            <WorkDetailArtist
              artist={ArtworkInfo.artist}
              isPcClass={"wk_info_pc"}
            />
          </div>
          <div className="vw__Mobile wMobile">
            <WorkDetailArtist
              artist={ArtworkInfo.artist}
            />
          </div>
          {console.log()}
          {ArtworkInfo.artworksFromCollection.contents !== undefined
          && (ArtworkInfo.artworksFromCollection.contents.length !== 0 && (
            <WorkDetailCollection
              artworks={ArtworkInfo.artworksFromCollection}
            />
          ))}
          {/* {true && (
            <WorkDetailExhibition
            />
          )} */}
        </div>
      </div>
    );
  } else {
    return <div/>
  }
};

export default Workdetails;
