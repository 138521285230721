/**
 * 
 * @param {*} obj 
 */
function getObjRect(obj) {
    if ('string' == typeof (obj)) {
        obj = document.getElementById(obj);
    }
    if (!obj) return {};
    var docH = document.documentElement.clientHeight || document.body.clientHeight;
    var ro = obj.getBoundingClientRect();
    //document.documentElement.clientTop 在IE67中始终为2，其他高级点的浏览器为0
    var top = ro.top - document.documentElement.clientTop;
    // ro.top = top
    ro.topC = top
    //document.documentElement.clientLeft 在IE67中始终为2，其他高级点的浏览器为0
    // ro.left = ro.left - document.documentElement.clientLeft;
    ro.Width = ro.width || ro.Right - ro.Left;
    ro.Height = ro.height || ro.Bottom - ro.Top;
    ro.docH = docH
    if (!ro.originalTop) {
        ro.originalTop = top
    }
    return ro
}

function removeLi(point) {
    var liList = document.querySelectorAll("#nav_btn li")
    var size = liList.length
    for (var i = 0; i < liList.length; i++) {
        var ele = liList[i]
        ele.removeAttribute("class")
    }
    if (size > 0) {
        liList[point].className = "active"
    }
}

function initPage() {
    // 需要定位的容器
    var navs = document.getElementById("sectionNavs");
    if (!navs) return;
    var sectionNavs2 = document.getElementById("sectionNavs2");
    var artNews = document.getElementById("Artists-News");
    var navsRect = getObjRect("Collection");
    var navsRectY = navsRect.y;

    var endRect = getObjRect("News");
    var endRectY = endRect.y;
    var endRectDocH = endRect.docH;
    if (endRectY <= endRectDocH || navsRectY >= 0) {
        navs.style.position = ""
        navs.style.top = ""
        sectionNavs2.style.display = "none"
        // navs.style.left = ""
        removeLi(0)
        if (artNews) {
            artNews.className = "home_nav Artists-News"
        }
        return;
    }

    if (artNews) {
        artNews.className = "home_nav"
    }

    if (navsRectY <= 0) {
        navs.style.position = "fixed"
        // navs.style.inset = "0px auto auto 0px"
        navs.style.top = "0"
        sectionNavs2.style.display = "block"
        // navs.style.left = "0"
    }
    var artsRect = getObjRect("Artists");
    var artsRectY = artsRect.y;
    var artsRectDocH = artsRect.docH;
    // arts
    if (artsRectY <= artsRectDocH) {
        removeLi(2)
    } else {
        var workRect = getObjRect("Works");
        var workRectY = workRect.y;
        var workRectDocH = workRect.docH;

        if (workRectY < workRectDocH) {
            removeLi(1)
        }
    }
}
export { initPage }