import React from "react";
import { Icon } from "antd";
import SVG from "react-inlinesvg";

export const InstagramIcon = () => (
  <Icon
    component={() => (
      <a
        href="https://www.instagram.com/galleryallofficial/?hl=en"
        target="_blank"
        rel="noopener noreferrer"
      >
        <SVG src={"/images/instagram/instagram.svg"} cacheGetRequests />
      </a>
    )}
  />
);

export const FacebookIcon = () => (
  <Icon
    component={() => (
      <a
        href="https://www.facebook.com/galleryall/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <SVG src={"/images/facebook/facebook.svg"} cacheGetRequests />
      </a>
    )}
  />
);

export const EmailIcon = () => (
  <Icon
    component={() => (
      <a href="mailto:info@galleryall.com">
        <SVG src={"/images/miscellaneous/email.svg"} cacheGetRequests />
      </a>
    )}
  />
);

export const DownloadIcon = () => (
  <Icon
    component={() => (
      <SVG src={"/images/miscellaneous/icDownloadLight.svg"} cacheGetRequests />
    )}
  />
);

export const ArrowLeftIcon = () => (
  <Icon
    component={() => (
      <SVG
        src={"/images/miscellaneous/icon-chevron-left.svg"}
        cacheGetRequests
      />
    )}
  />
);

export const ArrowRightIcon = () => (
  <Icon
    component={() => (
      <SVG
        src={"/images/miscellaneous/icon-chevron-right.svg"}
        cacheGetRequests
      />
    )}
  />
);

export const ChevronDownIcon = () => (
  <Icon
    component={() => (
      <SVG
        className="iconChevronDown"
        src={"/images/miscellaneous/icon-chevron-down.svg"}
        cacheGetRequests
      />
    )}
  />
);

export const Gallerylogo = () => (
  <Icon
    component={() => (
      <SVG
        className="galleryAllLogo"
        src={"/images/miscellaneous/gallery-all-logo.svg"}
        cacheGetRequests
      />
    )}
  />
);

export const Menulogo = () => (
  <Icon
    component={() => (
      <SVG className="iconMenulogo" src={"/images/miscellaneous/menu.svg"} cacheGetRequests />
    )}
  />
);

export const DownLoadIconV2 = () => (
  <Icon
    component={() => (
      <SVG className="iconMenulogo" src={"/images/version2/download-icon.svg"} cacheGetRequests />
    )}
  />
);

export const GalleryallLogoBlk = () => (
  <Icon
    component={() => (
      <SVG className="iconMenulogo" src={"/images/version2/galleryall-logo-blk.svg"} cacheGetRequests />
    )}
  />
);

export const CloseIcon = () => (
  <Icon
    component={() => (
      <SVG className="iconMenulogo" src={"/images/version2/close-icon.svg"} cacheGetRequests />
    )}
  />
);
