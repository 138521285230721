import _get from "lodash/get";

const getTypeName = (type) => {
  if (type === 0) {
    return false;
  }
  return type === 1 ? "isVisualArt" : "isFunctionalArt";
}

/**
 * 
 * @param {*} artists 
 * @param {*} typeName 
 * @returns 
 */
export const loadTypeData = (artists, type) => {
  const typeName = getTypeName(type)
  const newNames = []
  const newArray = [];
  artists.forEach((item) => {
    if (typeName) {
      // 筛选类型
      if (item[typeName]) {
        newArray.push(item);
        newNames.push(item.name)
      }
    } else {
      newArray.push(item);
      newNames.push(item.name)
    }
  });
  return {
    newNames: newNames,
    newArray: newArray
  }
}

export const getUniqueItems = (items) => {
  const array = [...new Set(items)];
  const unEmpty = array.filter((item) => item !== "");
  return unEmpty;
};

export const setItemValue = (item, objectIds, images, names, biographys) => {
  const id = _get(item, "objectId", "");
  objectIds.push(id);
  const url = _get(item, "coverImageThumbnail.url", "");
  images.push(url);
  const name = _get(item, "name", "NULL");
  names.push(name);
  const biography = _get(item, "biography", "NULL");
  biographys.push(biography);
};

export const mobileEnum = {
  0: "All Artists",
  1: "Visual Art",
  2: "Functional Art"
}