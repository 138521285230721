import React, { useState } from "react";
import { Row, Col, Typography, Input, Button } from "antd";
import styled from "styled-components";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const { Title } = Typography;
const { Group } = Input;

// MailChimp Configuration:
const url =
  "https://gallery-all.us3.list-manage.com/subscribe/post?u=1d67dd05a16ab1867e5a45f8e&amp;id=cec8271c9a";

const StyledTitle = styled(Title)`
  && {
    height: 30px;
    font-family: MaisonNeue-Book;
    font-size: 21px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--dark);

    @media (max-width: 376px) {
      height: 30px;
      font-size: 18px;
      line-height: 1.67;
    }
  }
`;
const StyledButton = styled(Button)`
  && {
    width: 100%;
    height: 40px;
    font-family: MaisonNeue-Demi;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 2px;
    text-align: right;
    color: var(--dark);
    padding: 0px;
    background-color: rgba(255, 255, 255, 0);
    text-align: right;
    border: none;
    :hover {
      border: none;
    }
    :active {
      color: white;
    }
    :after {
      display: none;
    }

    @media (max-width: 376px) {
      font-size: 14px;
    }
  }
`;
const StyledInput = styled(Input)`
  padding: 0px;
  margin-left: 0px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  outline: none;
  margin: 0;
  :hover {
    border: none;
  }
  :focus {
    box-shadow: none;
    border: none;
  }
  color: var(--dark);
`;
const StyledGroup = styled(Group)`
  width: 100%;
  height: 40px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-bottom: 1px solid black;
  :hover {
    border: none;
    border-bottom: 1px solid black;
  }
  :focus {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
  }
  .ant-input-search-button {
    width: 10%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  color: var(--dark);
`;

const NewsLetter = () => {
  const [inputValue, setInputValue] = useState("");
  return (
    <div className="NewsLetter">
      <div className="lg_title">
        <StyledTitle> Newsletter </StyledTitle>
      </div>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <StyledGroup placeholder={"Enter Email Address"}>
            <Row>
              <Col xs={5} sm={18}>
                <StyledInput
                  placeholder={"Enter Email Address"}
                  onChange={params => setInputValue(params.target.value)}
                  className="InputEmailAddress"
                />
              </Col>
              <Col xs={5} sm={6} className="SignupButton">
                <StyledButton
                  onClick={() => {
                    subscribe({ EMAIL: inputValue });
                  }}
                >
                  SIGN UP
                </StyledButton>
              </Col>
            </Row>
            {status === "sending" && (
              <div style={{ color: "blue" }}>sending...</div>
            )}
            {status === "error" && (
              <div
                style={{ color: "red" }}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
            {status === "success" && (
              <div style={{ color: "green" }}>Subscribed !</div>
            )}
          </StyledGroup>
        )}
      />
    </div>
  );
};

export default NewsLetter;
