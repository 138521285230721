import React, { useState } from "react";
import { Typography } from "antd";
import { IconDownArrow, IconArtRightArrow } from "../../../images/indexSvg";
import "./SmallNav.css";
import { toUrl } from "../../../utilities/utilities";
const { Text } = Typography;

const NavWorksItems = (artworkTypes = [], handlerNavClick = () => {}) => {
  let artworkTypeList = artworkTypes.map((artworkType, index) => {
    return (
      <div key={index} className="menu-item-artist">
        <div >
          <a 
            href={toUrl(artworkType.uri)}
            onClick={() => handlerNavClick(false)}
          >
            {artworkType.name}
          </a>
        </div>
      </div>
    );
  });
  return (
    <div className="artistListContainer">
      <div className="artistListItems">{artworkTypeList}</div>
    </div>
  );
};

const NavArtistsItems = (artistName = [], handlerNavClick = () => {}) => {
  let artistList = artistName.map((artist, index) => {
    return (
      <div key={index} className="menu-item-artist">
        <a 
          href={toUrl(artist.uri)}
          onClick={() => handlerNavClick(false)}
        >
          {artist.name}
        </a>
      </div>
    );
  });
  return (
    <div className="artistListContainer">
      <div className="artistListItems">{artistList}</div>
    </div>
  );
};

const SmallNav = (props) => {
  const {
    id='',
    mode='',
    cNav='',
    changeHover = () => {},
    artworks = [],
    artists = [],
    restNavbar = []
  } = props;

  // style
  const [CurrentArt, setCurrentArt] = useState(1);
  const [ModalIsOpen, setModalIsOpen] = useState("none");
  const [ModalIsOpenArtists, setModalIsOpenArtists] = useState("none");

  // Mouse Over Works
  const handleMouseOverWorks = () => {
    setModalIsOpenArtists("none");
    setModalIsOpen("block");
    onOpenChange("block");
  };

  // Mouse Over Artists
  const handleMouseOverArtists = () => {
    setModalIsOpen("none");
    setModalIsOpenArtists("block");
    onOpenChange("block");
  };

  // Mouse Out Works
  const handleMouseOutWorks = () => {
    setModalIsOpen("none");
    onOpenChange("");
  };

  // Mouse Out Artists
  const handleMouseOutArtists = () => {
    setModalIsOpenArtists("none");
    onOpenChange("");
  };
  
  const onOpenChange = (event) => {
    changeHover(event);
  };

  // after click
  const handlerNavClick = (isTrue) => {
    setModalIsOpen("none");
    setModalIsOpenArtists("none");
  };

  const setArtistGroup = (art) => {
    setCurrentArt(art);
  };

  const unActive = "xsMenu";
  const inActive = "xsMenu actives";

  const navClass = {
    0: cNav === "works" ? inActive : unActive,
    1: cNav === "artists" ? inActive : unActive,
    2: cNav === "exhibitions" ? inActive : unActive,
    3: cNav === "collections" ? inActive : unActive,
    4: cNav === "presses" ? inActive : unActive,
    5: cNav === "about" ? inActive : unActive,
  };

  return (
    <ul id="nav" className="pc_nav_header">
      {/* Works */}
      <li
        className="pc_menu_item navWorks pc-submenu"
        onMouseOver={handleMouseOverWorks}
        onMouseLeave={handleMouseOutWorks}
      >
        <span className={navClass[0]}>
          {artworks.name} &nbsp;
          <IconDownArrow />
        </span>
        <div
          className="header_items pc_works_items"
          style={{ display: ModalIsOpen }}
        >
          {NavWorksItems(artworks.contents, handlerNavClick)}
        </div>
      </li>
      {/* Artists */}
      <li
        className="pc_menu_item navArtists pc-submenu"
        onMouseOver={handleMouseOverArtists}
        onMouseLeave={handleMouseOutArtists}
      >
        <span className={navClass[1]}>
          {artists.name} &nbsp;
          <IconDownArrow />
        </span>
        <div
          className="header_items pc_artists_item"
          style={{ display: ModalIsOpenArtists }}
        >
          <div className="art_item">
            {/* Visual Art && Functional Art */}
            <div className="art_list">
              <div
                  className={`art_item_name ${
                    CurrentArt === 2 ? "unactive" : "actives"
                  }`}
                  onMouseEnter={(e) => {
                    setArtistGroup(1);
                  }}
              >
                <span className="t_text">{artists.contents[0].name}</span>
                <IconArtRightArrow />
              </div>
              <div
                className={`art_item_name ${
                  CurrentArt === 2 ? "actives" : "unactive"
                }`}
                onMouseEnter={(e) => {
                  setArtistGroup(2);
                }}
              >
                <span className="t_text">{artists.contents[1].name}</span>
                <IconArtRightArrow />
              </div>
            </div>
            {/* Items in Visual Art && Functional Art */}
            <div className="art_lists">
              <div className={`art_items ${CurrentArt === 2 ? "hide" : "show"}`}>
                {NavArtistsItems(artists.contents[0].contents, handlerNavClick)}
              </div>
              <div className={`art_items ${CurrentArt === 2 ? "show" : "hide"}`}>
                {NavArtistsItems(artists.contents[1].contents, handlerNavClick)}
              </div>
              <div className="viewallbtn v_viewallbtn">
                <a
                  href={toUrl(artists.uri)}
                  onClick={() => handlerNavClick(false)}
                >
                  <Text className="ViewAllText">
                    <span className="t_text">{artists.seeAll}</span>
                      <span>
                        <svg
                          width="15"
                          height="14"
                          viewBox="0 0 15 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.33333 7.85191C0.812275 7.85453 0.386689 7.48328 0.380859 7.02105C0.386689 6.55882 0.812275 6.18757 1.33333 6.19019L12.2788 6.19019L7.59037 1.78753C7.42289 1.63616 7.32685 1.42638 7.32422 1.20616C7.32422 0.997858 7.41294 0.790731 7.59037 0.582427C7.75316 0.423046 7.97642 0.333171 8.20955 0.333171C8.44268 0.333171 8.66593 0.423046 8.82872 0.582427L13.9596 5.40166C14.4019 5.85828 14.6668 6.39729 14.6668 7.02102C14.6668 7.60239 14.4019 8.18376 13.9596 8.59919L8.82872 13.4172C8.66593 13.5766 8.44268 13.6665 8.20955 13.6665C7.97642 13.6665 7.75316 13.5766 7.59037 13.4172C7.42107 13.2672 7.32486 13.0565 7.32486 12.8359C7.32486 12.6153 7.42107 12.4046 7.59037 12.2545L12.2788 7.85191L1.33333 7.85191Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                  </Text>
                </a>
              </div>
            </div>
          </div>
        </div>
      </li>
      {/* Exhibitions, Collections, Presses, About Us */}
      {restNavbar.map((item, index) => 
        <li className="pc_menu_item" key={index}>
          <a 
            className={navClass[index+2]}
            href={toUrl(item.uri)}
          >
            {item.name}
          </a>
        </li>
      )}
    </ul>
  )
}

export default SmallNav;
