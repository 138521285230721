import React, { useState } from "react";
import { IconDownArrow } from "../../../images/indexSvg";
import { toUrl } from "../../../utilities/utilities"

const PhoneNav = (props) => {
  const {
    handler,
    visible = false,
    artists = [],
    artworks = [],
    restNavbar = []
  } = props;

  const [visibleWorks, setVisibleWorks] = useState("");
  const [visibleArtists, setVisibleArtists] = useState("");

  const _className = visible ? `xsShow` : "";

  const handleWorksChange = () => {
    setVisibleWorks(!visibleWorks ? "xsShow" : "");
    setVisibleArtists("");
  };

  const handleArtistsChange = () => {
    setVisibleWorks("");
    setVisibleArtists(!visibleArtists ? "xsShow" : "");
  };

  // After click anything in Navbar
  const handlerClick = () => {
    handler(false);
    setVisibleWorks("");
    setVisibleArtists("");
  };

  return (
    <div className={`xsNavVer2 xsHide ${_className}`}>
      {/* Works */}
      <div className="navItem">
        {/* 一级菜单 */}
        <div
          className="nTitle"
          onClick={(e) => { handleWorksChange(); }}
        >
          <span>{artworks.name}</span>
          <span className={`icon ${visibleWorks}`}>
            <IconDownArrow />
          </span>
        </div>
        {/* 二级菜单 */}
        <div className={`childrenList xsHide ${visibleWorks}`}>
          {artworks.contents.map((item, index) => (
            <div className="namesItem" key={index}>
              <a 
                href={toUrl(item.uri)}
                onClick={() => handlerClick(false)}
              >
                {item.name}
              </a>
            </div>
          ))}
        </div>
      </div>
      {/* Artists */}
      <div className="navItem">
        {/* 一级菜单 */}
        <div
          className="nTitle"
          onClick={(e) => { handleArtistsChange() }}
        >
          <span>{artists.name}</span>
          <span className={`icon ${visibleArtists}`}>
            <IconDownArrow />
          </span>
        </div>
        {/* 二级菜单 */}
        <div className={`artistsChild xsHide ${visibleArtists}`}>
          {/* View All Artists Link */}
          <a 
            href={toUrl(artists.uri)} 
            onClick={() => handlerClick(false)}
          >
            <div className="viewMore">
              <span>{artists.seeAll}</span>
              <span>
                {/* 向右箭头 */}
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.52418 6.68175C1.10734 6.68385 0.766871 6.38685 0.762207 6.01706C0.766871 5.64728 1.10734 5.35028 1.52418 5.35237L10.28 5.35237L6.52933 1.83032C6.39534 1.70922 6.31851 1.5414 6.31641 1.36522C6.31641 1.19858 6.38738 1.03288 6.52933 0.866235C6.65956 0.738729 6.83817 0.666829 7.02467 0.666829C7.21117 0.666829 7.38978 0.738729 7.52001 0.866235L11.6247 4.72162C11.9753 5.08359 12.1866 5.51027 12.1905 6.00349C12.1906 6.008 12.1907 6.01253 12.1908 6.01706C12.1908 6.02459 12.1906 6.03209 12.1903 6.03955C12.1837 6.49713 11.9729 6.95264 11.6247 7.27964L7.52001 11.1341C7.38978 11.2616 7.21117 11.3335 7.02467 11.3335C6.83817 11.3335 6.65956 11.2616 6.52933 11.1341C6.39388 11.014 6.31692 10.8455 6.31692 10.669C6.31692 10.4925 6.39388 10.324 6.52933 10.2039L10.2801 6.68175L1.52418 6.68175Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
          </a>
          <div className="artName">{artists.contents[0].name}</div>
          {/* 三级菜单 part1 */}
          <div className="sVisualArt">
            {artists.contents[0].contents.map((artist, index) => (
              <div className="namesItem" key={index}>
                <a 
                  href={toUrl(artist.uri)}
                  onClick={() => handlerClick(false)}
                  >
                  {artist.name}
                </a>
              </div>
            ))}
          </div>
          {/* 三级菜单 part2 */}
          <div className="artName">{artists.contents[1].name}</div>
          <div className="sFunctionalArt">
            {artists.contents[1].contents.map((artist, index) => (
              <div className="namesItem" key={index}>
                <a 
                  href={toUrl(artist.uri)}
                  onClick={() => handlerClick(false)}
                  >
                  {artist.name}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Exhibitions, Collections, Presses, About Us */}
      {restNavbar.map((item, index) => 
          <div className="linkItem" key = {index}>
            <a 
              className="xsMenuLink"
              href={toUrl(item.uri)}
              onClick={() => handlerClick(false)}
            >
              {item.name}
            </a>
      </div>
      )}
    </div>
  );
};

export default PhoneNav;
