import React from "react";
import { WhiteRightArrow, BlackRightArrow } from "../../images/indexSvg";
import "./banner.css";
import { toUrl } from "../../utilities/utilities";

const createBannerLink = (item) => {
  return (
    <div className="banner_info" key={item.id}>
      <a href={toUrl(item.uri)} key={item.id}>
        <div className="text_modal">
          <div className="subtitle">{item.artistName}</div>
          <h1 className="title">{item.name}</h1>
          {item.description && <div className="desc">{item.description}</div>}
          <div className="more">
            <span className="pc_article_arrow">
              <WhiteRightArrow />
            </span>
            <span className="mobile_article_arrow">
              <span className="mobile_article_text">Learn More</span>
              <BlackRightArrow />
            </span>
          </div>
        </div>
        <div className="thumb_box">
          <img src={item.imageUrls[0].imageNormal} alt={item.name} />
        </div>
      </a>
    </div>
  );
};

const ImgBanner = (props) => {
  const { items = []} = props;
  return (
    <div className="ImgBanner">
      {items.map((item) => {
        return createBannerLink(item);
      })}
    </div>
  );
};

export default ImgBanner;
