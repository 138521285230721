export const ADD_ARTICLE = "ADD_ARTICLE";

export const VIEW_ALL_WORKS_WITH_ARTISTS = "VIEW_ALL_WORKS_WITH_ARTISTS";
export const VIEW_ALL_WORKS_WITH_TYPES = "VIEW_ALL_WORKS_WITH_TYPES";
export const VIEW_ALL_ARTISTS_WITH_ARTISTS = "VIEW_ALL_ARTISTS_WITH_ARTISTS";
export const VIEW_ALL_COLLECTION_WITH_ARTISTS =
  "VIEW_ALL_COLLECTION_WITH_ARTISTS";
export const VIEW_ALL_EXHIBITIONS_WITH_YEARS =
  "VIEW_ALL_EXHIBITIONS_WITH_YEARS";
export const APP_CONTEXT_ARTISTS = "APP_CONTEXT_ARTISTS";
export const APP_CONTEXT_WORKS = "APP_CONTEXT_WORKS";
export const APP_CONTEXT_COLLECTION = "APP_CONTEXT_COLLECTION";
export const APP_CONTEXT_EXHIBITION = "APP_CONTEXT_EXHIBITION";
// 页面路径
export const SET_PATHNAME = "SET_PATHNAME";
export const GET_PATHNAME = "GET_PATHNAME";
// 分类，标签
export const SET_CATEGORIES = "SET_CATEGORIES";
export const GET_CATEGORIES = "GET_CATEGORIES";
//
export const SET_ONGOING_EXHIBITION = "SET_ONGOING_EXHIBITION";
export const SET_ONGOING_COLLECTION = "SET_ONGOING_COLLECTION";
// 
export const Set_Navigation = "Navigation";
