import React from "react";
import PropTypes from "prop-types";
import "./video_style.css"

const YoutubeEmbed = ({ embedId, key }) => {
  return (
    <div className="video" key={key}>
      <iframe
        title="WorkDetailVideo"
        width="100%"
        height="401"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed