import React, { useState, useEffect } from "react";
import Axios from "axios";
import _get from "lodash/get";
import ExhibitionsView from "./ExhibitionsView_Ver2";
import CollectionsView from "./CollectionsView_Ver2";
import WorksView from "./WorksView_Ver2";
import ArtistsView from "./ArtistsView_Ver2";
import PressesView from "./PressesView_Ver2";
import Instagram from "./InstagramVer2";
import "./Home.css";
import "./HomeStyleVer2.css";

const Home = () => {

  const [HomeData, setHomeData] = useState([]);
  
  const getHomepage = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebHomepage", {},
      {
        headers: {
          "X-Parse-Application-id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  };

  // 数据请求
  useEffect(() => {
    getHomepage().then(result => {
      setHomeData(result.data.result);
    })
  }, []);

  return (
    <div className="homeVer2">
    {(HomeData.length !== 0) && (
      <React.Fragment>
        {/* exhibition */}
        <ExhibitionsView exhibitions={HomeData.exhibitions} />
        {/* collection */}
        <span className="Anchor" id='screenCollection'>&nbsp;</span>
        <CollectionsView collections={HomeData.collections} />
        {/* work */}
        <span className="Anchor" id='screenWorks'>&nbsp;</span>
        <WorksView artworks={HomeData.artworks} />
        {/* artists */}
        <span className="Anchor" id='screenArtists'>&nbsp;</span>
        <ArtistsView artists={HomeData.artists} />
        {/*  presses */}
        <PressesView presses={HomeData.presses} />
        {/* Instagram */}
        <Instagram />
      </React.Fragment>
    )}
  </div>
  );
};

export default Home;
