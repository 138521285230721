import React from "react";
import { Row, Col } from "antd";
import PressesContainer from "../../views_components/ver2/Presses/PressesContainer_Ver2"
import SmallSlider from "../../views_components/ver2/Presses/SmallSlider_Ver2"

const PressesView = (props) => {
  const {presses} = props;

  return (
    <div id="News" className="v2-NewsOfGalleryAll">
      <Row>
        <Col xs={0} lg={24}>
          <PressesContainer presses={presses} />
        </Col>
        <Col xs={24} sm={24} lg={0}>
          <SmallSlider presses={presses} />
        </Col>
      </Row>
    </div>
  )
};

export default PressesView;