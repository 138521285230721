import React from "react";

export const SeparateParagraph = (str) => {
  let strList = (str || "").match(/[^\r\n]+/g);
  if (strList && strList.length > 0) {
    str = strList.map((element, index) => {
      return <p key={index}>{element}</p>
    });
  }
  return str;
}

export const TransferYoutubeLink = (link) => {
  let argumentStr = link.substring(link.indexOf("?") + 1);
  if (!argumentStr) {
    return link;
  }
  let list = argumentStr.split("&");
  let code = "";
  list.forEach(element => {
    if (element.indexOf("v=") >= -1) {
      code = element.split("=")[1];
    }
  });

  if (!code) {
    return link;
  }
  return `https://www.youtube.com/embed/${code}?feature=oembed&modestbranding=1&rel=0`;
}

export const validURL = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}


/**
 * Sort updatedat fields
 * @param {*} list 
 */
export const sortByUpdateAt = (list) => {
  const getTime = (param) => {
    return new Date(param).getTime()
  }
  return list.sort((a, b) => {
    return getTime(b.updatedAt) - getTime(a.updatedAt)
  })
}

export const toUrl = (uri) => {
  return `http://${window.location.host}${uri}`
}