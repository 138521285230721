import React from "react";
import "../WorkFromCollection.css";
import GridLayout from "./WorksGridLayout_Ver2";
import { CollectionWorksArt } from "../Enum"
import { toUrl } from "../../../utilities/utilities";

const WorksContainer = (props) => {

  const {artworks} = props;

  return (
    <div>
      <div className="home_nav">
        <div className="left_nav">
          <div className="nav_group">
            <div className="nav_text">
              {CollectionWorksArt}
            </div>
            <ul className="nav_btn">
              <li>Collections</li>
              <li className="active">Works</li>
              <li>Artists</li>
            </ul>
          </div>
        </div>
        <div className="right_content">
          <div className="work_content">
            <GridLayout artworks = {artworks.contents} />
          </div>
          <div className="more_group">
            <a href={toUrl(artworks.uri)}>
              <span>{artworks.seeAll}</span>
              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.33333 7.85204C0.812275 7.85466 0.386689 7.48341 0.380859 7.02117C0.386689 6.55894 0.812275 6.18769 1.33333 6.19031L12.2785 6.19031L7.5901 1.78769C7.42262 1.63632 7.32658 1.42654 7.32395 1.20632C7.32395 0.99802 7.41267 0.790894 7.5901 0.582589C7.75289 0.423207 7.97615 0.333332 8.20928 0.333332C8.4424 0.333332 8.66566 0.423207 8.82845 0.582589L13.9593 5.40182C14.4017 5.85845 14.6666 6.39744 14.6666 7.02117C14.6666 7.02118 14.6666 7.02118 14.6666 7.02118C14.6666 7.60255 14.4017 8.18392 13.9593 8.59935L8.82845 13.4174C8.66566 13.5768 8.4424 13.6667 8.20928 13.6667C7.97615 13.6667 7.75289 13.5768 7.5901 13.4174C7.42079 13.2673 7.32459 13.0567 7.32459 12.836C7.32459 12.6154 7.42079 12.4048 7.5901 12.2547L12.2786 7.85204L1.33333 7.85204Z" fill="black" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
};

export default WorksContainer;