import React from "react";
import { Row, Col } from "antd";
import WorksContainer from "../../views_components/ver2/work/WorksContainer_Ver2";
import SmallSlider from "../../views_components/ver2/work/SmallSlider_Ver2";

const WorksView = (props) => {

  const {artworks} = props;
  
  return (
    <div id="Works" className="v2-Works">
      <Row>
        <Col xs={0} lg={24}>
          <WorksContainer 
            artworks={artworks} 
          />
        </Col>
        <Col xs={24} sm={24} lg={0}>
          <SmallSlider 
            artworks={artworks} 
          />
        </Col>
      </Row>
    </div>
  )
};

export default WorksView;