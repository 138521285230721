import React from "react";
import { toUrl } from "../../utilities/utilities";
import HeaderNav from "../HeaderNav_Ver2";

const SmallSliderWorks = (props) => {
  const {
    artworks = {}
  } = props;
  return (
    <div className="horizontal_Wrap">
      {/* <div className="horizontal_header">
        <div className="title">{artworks.name}</div>
      </div> */}
      <HeaderNav 
        info={artworks} 
        prev_class="mini_header"
      />
      <div className="horizontal_slide">
        {artworks.contents.map(artwork => {
          return (
            <a href={toUrl(artwork.uri)} key={artwork.id}>
              <div className="small_card">
                <div className="small_image">
                  <img src={artwork.imageUrls[0].coverImageNormal} alt={artwork.name} />
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SmallSliderWorks;
