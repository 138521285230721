import React from "react";
import { Row, Col } from "antd";
// import "./Home.css";
import ArtistsContainer from "../../views_components/ver2/Artists/ArtistsContainer_Ver2";
import SmallSlider from "../../views_components/ver2/Artists/SmallSlider_Ver2";

const ArtistsView = (props) => {

  const {artists} = props;

  return (
    <div id="Artists" className="v2-Artists">
      <Row>
        <Col xs={0} lg={24}>
          <ArtistsContainer artists = {artists}/>
        </Col>
        <Col xs={24} sm={24} lg={0}>
          <SmallSlider artists = {artists} />
        </Col>
      </Row>
    </div>
  )
};

export default ArtistsView;