import React, { useState, useEffect, Fragment } from "react";
import Axios from "axios";
import "./PressStyle.css";

import ArtistSlider from "../../views_components/Columns/ArtistSlider_Ver2";
import ExhibitionSlider from "../../views_components/Columns/ExhibitionSlider_Ver2";
import HeroCarousel from "../../views_components/PressesDetail/HeroCarouselMobile_Ver2";
import HeroCarouselVer2 from "../../views_components/PressesDetail/HeroCarousel_Ver2";
import FeaturedWorks from "../../views_components/Works/CategoryMondrian_Ver2";
import FeaturedPresses from "../../views_components/Presses/CategoryMondrianRows_Ver2";
import HeaderNav from "../../views_components/HeaderNav_Ver2";

import useWindowWidth from "../../components/useWindowWidth";
import { DownloadFiles } from "../../components/DownloadImage_Ver2";
import { DownLoadIconV2 } from "../../components/Icons";
import { IconMoreRightArrow } from "../../images/indexSvg";
import { SeparateParagraph } from "../../utilities/utilities";

const PressDetail = (props) => {
  const {
    match = {}
  } = props;

  const [PressInfo, setPressInfo] = useState([]);

  const windowWidth = useWindowWidth(100);
  // const sizeRow =
  //   windowWidth > 1260
  //     ? 16
  //     : windowWidth > 1004
  //     ? 9
  //     : windowWidth > 744
  //     ? 6
  //     : 4;
  const getPressDetail = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebPressDetail",
      {
        pressId: match.params.id
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }
  // 数据请求
  useEffect(() => {
    getPressDetail().then(result => {
      setPressInfo(result.data.result);
    })
  }, [match.params.id]);

  const downloadFile = () => {
    DownloadFiles(PressInfo.files.contents, PressInfo.press.content.name);
  };
  if (PressInfo.length !== 0 && PressInfo !== 'Error') {
    const fileClass = (PressInfo.files.contents.length  && PressInfo.files.contents.length > 0) ? "downloadIcon" : "downloadIcon-disable";
  
    return (
      <Fragment>
        <div className="press_detail_wrap">
          {/* Desktop */}
          <div className="press_block pressDetail_flex">
            <div className="pressDetail_swiper">
              <HeroCarouselVer2 contents={PressInfo.images.contents} />
            </div>
            <div className="pressDetail_info">
              <h1 className="title">{PressInfo.press.content.name}</h1>
              <div className="mb_40 time">{PressInfo.press.content.date.iso.split('T')[0]}</div>
              <div className="mb_40 desc">{SeparateParagraph(PressInfo.press.content.description)}</div>
              {PressInfo.press.content.link && 
                <div className="mb_40 more">
                  <a href={PressInfo.press.content.link} target="_blank" rel="noopener noreferrer">
                    <span>Read the Full Article</span>
                    <IconMoreRightArrow />
                  </a>
                </div>
              }
              {PressInfo.files.contents.length > 0 && (
                <div className={`${fileClass} download`} onClick={downloadFile}>
                  <DownLoadIconV2 />
                  <span>PDF</span>
                </div>
              )}
            </div>
          </div>
          {/* Ipad */}
          <div className="press_block pressDetail_ipad">
            <div className="pressDetail_info">
              <h1 className="title">{PressInfo.press.content.name}</h1>
              <div className="mb_40 time">{PressInfo.press.content.date.iso.split('T')[0]}</div>
              <div className="mb_40 desc">{SeparateParagraph(PressInfo.press.content.description)}</div>
              {PressInfo.press.content.link && 
                <div className="mb_40 more">
                  <a href={PressInfo.press.content.link} target="_blank" rel="noopener noreferrer">
                    <span>Read the Full Article</span>
                    <IconMoreRightArrow />
                  </a>
                </div>
              }
              {PressInfo.files.contents.length > 0 && (
                <div
                  className={`${fileClass} mb_40 download`}
                  onClick={downloadFile}
                >
                  <DownLoadIconV2 />
                  <span>PDF</span>
                </div>
              )}
            </div>
            <HeroCarousel contents={PressInfo.images.contents} prevClass="pIpad" />
          </div>
          {/* Mobile */}
          <div className="press_block pressDetail_iphone">
            <HeroCarousel contents={PressInfo.images.contents}/>
            <div className="pressDetail_info">
              <h1 className="title">{PressInfo.press.content.name}</h1>
              <div className="mb_40 time">{PressInfo.press.content.date.iso.split('T')[0]}</div>
              <div className="mb_40 desc">{SeparateParagraph(PressInfo.press.content.description)}</div>
              {PressInfo.press.content.link && 
                  <div className="mb_40 more">
                    <a href={PressInfo.press.content.link} target="_blank" rel="noopener noreferrer">
                      <span>Read the Full Article</span>
                      <IconMoreRightArrow />
                    </a>
                  </div>
                }
              {PressInfo.files.contents.length > 0 && (
                <div
                  className={`${fileClass} mb_40 download`}
                  onClick={downloadFile}
                >
                  <DownLoadIconV2 />
                  <span>PDF</span>
                </div>
              )}
            </div>
          </div>
          {/* Exhibitions in the Article */}
          {PressInfo.exhibitions.contents.length > 0 && (
          <Fragment>
            <div className="press_block pressDetail_exhibtions">
              <HeaderNav
                info={PressInfo.exhibitions}
              />
              <ExhibitionSlider info={PressInfo.exhibitions} />
            </div>
            <div className="press-line"></div>
          </Fragment>
        )}
        {PressInfo.artists.contents.length > 0 && (
          <Fragment>
            <div className="press_block pressDetail_artists">
              <HeaderNav
                info={PressInfo.artists}
              />
              <ArtistSlider info={PressInfo.artists}/>
            </div>
            <div className="press-line"></div>
          </Fragment>
        )}
        </div>
        {PressInfo.artworks.contents.length > 0 && (
          <Fragment>
            <div className="c_page press_block press_works">
              <HeaderNav
                info={PressInfo.artworks}
              />
              <FeaturedWorks
                artworks={PressInfo.artworks.contents}
              />
            </div>
            {PressInfo.artworks.contents.length > 0 && <div className="press-line"></div>}
          </Fragment>
        )}
        {PressInfo.otherPresses.contents.length > 0 && (
          <Fragment>
            <div className="c_page press_block Other_Presses">
              <HeaderNav
                info={PressInfo.otherPresses}
              />
              <FeaturedPresses
                presses={PressInfo.otherPresses.contents}
              />
            </div>
            {/* <div className="press-line"></div> */}
          </Fragment>
        )}
      </Fragment>
    )
  } else {
    return <div/>
  }
};

export default PressDetail;
