import React from "react";
import { Icon } from "antd";
import SVG from "react-inlinesvg";

const WhiteArrowSvg = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.00009 7.89452C0.452987 7.89727 0.00612136 7.50745 0 7.02211C0.00612136 6.53677 0.452987 6.14696 1.00009 6.14971L12.4922 6.14971L7.5695 1.52708C7.39365 1.36814 7.29281 1.14787 7.29004 0.916641C7.29004 0.697922 7.38319 0.480439 7.5695 0.26172C7.74043 0.0943689 7.97485 0 8.21964 0C8.46442 0 8.69884 0.0943689 8.86977 0.26172L14.2572 5.32192C14.7188 5.79847 14.9964 6.36047 14.9998 7.01036C14.9998 7.01432 14.9998 7.01828 14.9998 7.02224C14.9998 7.02953 14.9998 7.03682 14.9997 7.04411C14.9931 7.64723 14.7161 8.24832 14.2572 8.67932L8.86977 13.7383C8.69884 13.9056 8.46442 14 8.21964 14C7.97485 14 7.74043 13.9056 7.5695 13.7383C7.39173 13.5807 7.29071 13.3595 7.29071 13.1278C7.29071 12.8962 7.39173 12.675 7.5695 12.5174L12.4925 7.89452L1.00009 7.89452Z"
        fill="white"
      />
    </svg>
  );
};

// 白色右箭头
const WhiteRightArrow = () => (
  <Icon
    component={() => (
      <SVG
        src={"/images/version2/white-right-arrow.svg"}
        cacheGetRequests
      />
    )}
  />
);

// 黑色右箭头
const BlackRightArrow = () => (
  <Icon
    component={() => (
      <SVG
        src={"/images/version2/black-right-arrow.svg"}
        cacheGetRequests
      />
    )}
  />
);
// 黑色上箭头
const IconUpArrow = () => (
  <Icon
    component={() => (
      <SVG
        src={"/images/version2/up-arrow.svg"}
        cacheGetRequests
      />
    )}
  />
);
// 黑色上箭头
const IconArtDownArrow = () => (
  <Icon
    component={() => (
      <SVG
        src={"/images/version2/icon-art-down.svg"}
        cacheGetRequests
      />
    )}
  />
);
// 黑色上箭头
const IconArtRightArrow = () => (
  <Icon
    component={() => (
      <SVG
        src={"/images/version2/artists-right-arrow.svg"}
        cacheGetRequests
      />
    )}
  />
);
// More All Right Arrow
const IconMoreRightArrow = () => (
  <Icon
    component={() => (
      <SVG
        src={"/images/version2/more-right-arrow.svg"}
        cacheGetRequests
      />
    )}
  />
);


const IconTime = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1201 5.71998V12.12H1.8801V5.71998H12.1201ZM10.8401 9.55998H9.5601V10.84H10.8401V9.55998ZM8.2801 9.55998H3.1601V10.84H8.2801V9.55998ZM4.4401 6.99998H3.1601V8.27998H4.4401V6.99998ZM10.8401 6.99998H5.7201V8.27998H10.8401V6.99998ZM12.1201 1.87998H10.8401V0.599976H9.5601V1.87998H4.4401V0.599976H3.1601V1.87998H1.8801C1.17354 1.87998 0.600098 2.45342 0.600098 3.15998V12.12C0.600098 12.8265 1.17354 13.4 1.8801 13.4H12.1201C12.8267 13.4 13.4001 12.8265 13.4001 12.12V3.15998C13.4001 2.45342 12.8267 1.87998 12.1201 1.87998Z"
        fill="black"
      />
    </svg>
  );
};

const IconAddress = () => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 8C5.172 8 4.5 7.328 4.5 6.5C4.5 5.672 5.172 5 6 5C6.828 5 7.5 5.672 7.5 6.5C7.5 7.328 6.828 8 6 8ZM6 0.5C2.6865 0.5 0 3.1865 0 6.5C0 11 6 15.5 6 15.5C6 15.5 12 11 12 6.5C12 3.1865 9.3135 0.5 6 0.5ZM6 9.5C7.6545 9.5 9 8.1545 9 6.5C9 4.8455 7.6545 3.5 6 3.5C4.3455 3.5 3 4.8455 3 6.5C3 8.1545 4.3455 9.5 6 9.5ZM6 2C8.48175 2 10.5 4.01825 10.5 6.5C10.5 9.06275 7.77075 12.0058 6 13.5613C4.2285 12.005 1.5 9.062 1.5 6.5C1.5 4.01825 3.51825 2 6 2Z"
        fill="black"
      />
    </svg>
  );
};

const IconMore = () => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.52418 6.68167C1.10734 6.68376 0.766871 6.38677 0.762207 6.01698C0.766871 5.6472 1.10734 5.3502 1.52418 5.35229L10.2803 5.35229L6.5296 1.8302C6.39562 1.7091 6.31879 1.54127 6.31668 1.3651C6.31668 1.19846 6.38765 1.03276 6.5296 0.866113C6.65983 0.738607 6.83844 0.666707 7.02494 0.666707C7.21144 0.666707 7.39005 0.738607 7.52028 0.866113L11.625 4.7215C11.9788 5.0868 12.1908 5.518 12.1908 6.01698C12.1908 6.48208 11.9788 6.94718 11.625 7.27952L7.52028 11.134C7.39005 11.2615 7.21144 11.3334 7.02494 11.3334C6.83844 11.3334 6.65983 11.2615 6.5296 11.134C6.39416 11.0139 6.31719 10.8454 6.31719 10.6689C6.31719 10.4924 6.39416 10.3239 6.5296 10.2038L10.2804 6.68167L1.52418 6.68167Z"
        fill="black"
      />
    </svg>
  );
};

const IconArticleArrow = () => (<SVG src={"/icon/icon-article-arrow.svg"} cacheGetRequests />)
const IconDownArrow = () => (<SVG src={"/icon/icon-down-arrow.svg"} cacheGetRequests />)
const IconDateTime = () => (<SVG src={"/icon/icon-date-time.svg"} cacheGetRequests />)
const IconLocation = () => (<SVG src={"/icon/icon-location.svg"} cacheGetRequests />)
const IconDetailRArrow = () => (<SVG src={"/icon/icon-detail-r-arrow.svg"} cacheGetRequests />)
const IconSliderRArrow = () => (<SVG src={"/icon/icon-slider-r-arrow.svg"} cacheGetRequests />)
const IconSliderMoreArrow = () => (<SVG src={"/icon/icon-slider-more-arrow.svg"} cacheGetRequests />)
// Footer
const IconIns = () => (<SVG src={"/icon/icon-ins.svg"} cacheGetRequests />)
const IconWeChat = () => (<SVG src={"/icon/icon-wechat.svg"} cacheGetRequests />)
const IconRedBook = () => (<SVG src={"/icon/icon-redbook.svg"} cacheGetRequests />)
const IconWhiteRArrow = () => (<SVG src={"/icon/icon-white-r-arrow.svg"} cacheGetRequests />)


export {
  WhiteArrowSvg,
  WhiteRightArrow,
  BlackRightArrow,
  IconUpArrow,
  IconArtRightArrow,
  IconArtDownArrow,
  IconMoreRightArrow,


  IconTime,
  IconAddress,
  IconMore,
  IconArticleArrow,
  IconDownArrow,

  IconDateTime,
  IconLocation,
  IconDetailRArrow,
  IconSliderRArrow,
  IconSliderMoreArrow,

  // 
  IconIns,
  IconWeChat,
  IconRedBook,
  // 
  IconWhiteRArrow
};
