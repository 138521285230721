import React from "react";
import { SeparateParagraph } from "../../utilities/utilities";

const RenderDesktopDescription = (props) => {
  const {
    exhibition = {}
  } = props;

  return (
    <div className="content" id="DescriptionPc">
      {SeparateParagraph(exhibition.content.description)}
      <div className="mb100" />
    </div>
  );
};

export default RenderDesktopDescription;
