import React, { useState, useEffect, useRef } from "react";
import useObserver from "../ResizeObserver";
import { setWapperStyle } from "../../utilities/utils";
import { toUrl } from "../../utilities/utilities";
import { IconMoreRightArrow } from "../../images/indexSvg"
function getColNum(w) {
  const scoll = 0;
  if (w >= 1200 - scoll) return 4;
  if (w >= 1024 - scoll) return 3;
  if (w >= 500 - scoll) return 2;
  return 1;
}

const RenderArtistsDesktop = (props) => {
  const {
    artists = {}
  } = props;
  const [size, SetSize] = useState(0);
  const measuredRef = useRef(null);

  useObserver({
    element: measuredRef,
    callback: (element) => {
      const target = element[0].target;
      const offsetWidth = target.offsetWidth;
      let cn = getColNum(offsetWidth);
      SetSize(cn);
    },
  });

  useEffect(() => {
    return () => { };
  }, [size]);

  const renderCards = () => {
    let output = [];
    artists.contents.forEach((artist, index) => {
      const ui = (
        <div className="all_group all_group_res" key={artist.id}>
          <div className="img_group">
            <a href={toUrl(artist.uri)}>
              <div className="name_group">
                <p>{artist.name}</p>
              </div>
              <img src={artist.imageUrls[0].coverImageNormal} style={{ width: "100%" }} alt={artist.name} />
            </a>
          </div>
        </div>
      );
      output.push(ui);
    });
    return output;
  };

  const renderColumns = (nums, array) => {
    const columns = [];
    for (let i = 0; i < nums; i++) {
      columns.push([]);
    }

    array.map((item, id) => {
      const current = (id + nums) % nums;
      columns[current].push(item);
      return "finished";
    });

    const columnsSize = columns.length;

    return columns.map((column, id) => {
      let array = ["common_column"];
      array = setWapperStyle(array, id, columnsSize);
      const _className = array.join(" ");

      return (
        <div className={_className} key={id}>
          {column}
        </div>
      );
    });
  };

  return (
    <div className="content" id="ArtistsPc" ref={measuredRef}>
      {artists.contents.length > 0 ? (
        <React.Fragment>
          <div className="pb100" />
          <div className="detail_card_list">
            {size > 0 && renderColumns(size, renderCards())}
          </div>

          <div className="icon_more_box">
            <a href={`http://${window.location.host}${artists.uri}`}>
              {artists.contents.length > 1 &&
              <span>{artists.seeAll}</span>
              }
              {artists.contents.length === 1 &&
              <span>{'View all Artist in the Exhibition'}</span>
              }
              <IconMoreRightArrow />
            </a>
          </div>
          <div className="mb100" />
        </React.Fragment>
      ) : null}
    </div>
    
  );
};

export default RenderArtistsDesktop;
