import {
  ADD_ARTICLE,
  VIEW_ALL_WORKS_WITH_ARTISTS,
  VIEW_ALL_WORKS_WITH_TYPES,
  VIEW_ALL_ARTISTS_WITH_ARTISTS,
  VIEW_ALL_COLLECTION_WITH_ARTISTS,
  VIEW_ALL_EXHIBITIONS_WITH_YEARS,
  APP_CONTEXT_WORKS,
  APP_CONTEXT_ARTISTS,
  APP_CONTEXT_COLLECTION,
  APP_CONTEXT_EXHIBITION,
  // ver2
  SET_PATHNAME,
  SET_CATEGORIES,
  SET_ONGOING_EXHIBITION,
  SET_ONGOING_COLLECTION,
  Set_Navigation
} from "../constants/action-types";

export function addArticle(payload) {
  return { type: ADD_ARTICLE, payload };
}

// Actions for updating Works page
export function viewALLWorkWithArtists(payload) {
  return { type: VIEW_ALL_WORKS_WITH_ARTISTS, payload };
}
export function viewALLWorkWithTypes(payload) {
  return { type: VIEW_ALL_WORKS_WITH_TYPES, payload };
}

// Actions for updating Artists page
export function viewALLArtistsWithArtists(payload) {
  return { type: VIEW_ALL_ARTISTS_WITH_ARTISTS, payload };
}

// Actions for updating Collection page
export function viewALLCollectionWithTypes(payload) {
  return { type: VIEW_ALL_COLLECTION_WITH_ARTISTS, payload };
}

// Actions for updating Exhibition page
export function viewALLExhibitionWithTypes(payload) {
  return { type: VIEW_ALL_EXHIBITIONS_WITH_YEARS, payload };
}

// Actions for updating the app context
export function updateAppContextWorks(payload) {
  return { type: APP_CONTEXT_WORKS, payload };
}
export function updateAppContextArtists(payload) {
  return { type: APP_CONTEXT_ARTISTS, payload };
}
export function updateAppContextCollection(payload) {
  return { type: APP_CONTEXT_COLLECTION, payload };
}
export function updateAppContextExhibition(payload) {
  return { type: APP_CONTEXT_EXHIBITION, payload };
}

// 更新当前页面路径
export function updateCurrentPathName(payload) {
  return { type: SET_PATHNAME, payload };
}

// 激活当前nav
export function updateNavigation(payload) {
  return { type: Set_Navigation, payload };
}

// 缓存分类，标签
export function updateAppCategories(payload) {
  return { type: SET_CATEGORIES, payload };
}
// 
export function updateOnExhibitions(payload) {
  return { type: SET_ONGOING_EXHIBITION, payload };
}
// 
export function updateOnCollections(payload) {
  return { type: SET_ONGOING_COLLECTION, payload };
}
