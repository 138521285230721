import React, { useState, useEffect } from "react";
import Axios from "axios";
import HeaderNav from "../../views_components/HeaderNav_Ver2";
import CategoryMondrian from "../../views_components/Works/CategoryMondrian_Ver2.js";
import "./about_style.css";


const AboutUs = () => {
  const [Artworks, setArtworks] = useState([]);

  const getHomepage = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebHomepage", {},
      {
        headers: {
          "X-Parse-Application-id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  };

  // 数据请求
  useEffect(() => {
    getHomepage().then(result => {
      setArtworks(result.data.result.artworks.contents);
    })
  }, []);
  console.log(Artworks)
  return (
    <div className="aboutusWrap">
      <div className="aboutHeader">
        <div className="v__mask"></div>
        <div className="aboutHeader_board">
          <div className="aboutHeader_title">About Us</div>
          <div className="aboutHeader_content">
          Launched in Los Angeles in 2014, Gallery All has earned international acclaim for its ever-evolving program of exhibitions and presentations for its most distinguished contemporary design and art collection.
          </div>
          <div className="aboutHeader_content chinese">
            Gallery All于2014年创立于洛杉矶，近年来以全球范围内代理的杰出艺术家和在顶级展会、合作项目中的不俗表现赢得国际赞誉。2021年画廊正式入驻上海，在余姚路66号的新空间中开启全新旅程。          </div>
        </div>
      </div>
      <div className="about_fixed"></div>
      <div className="scoll_body">
        <div className="v__image2">
          <img className="v_img" src="/images/aboutus2/image21.jpg" alt="" />
          <div className="about_tips">"First of All," exhibition at Gallery All Shanghai gallery, 2021. Photo credit: Wu Zhiyao.</div>
          <div className="about_tips_mobile">"First of All," exhibition at Gallery All Shanghai gallery, 2021.<br></br> Photo credit: Wu Zhiyao.</div>
        </div>
        <div className="about_middle_des">
          <div className="about_description">
            <div className="about_image_des">
              Founded by Yu Wang and Xiao Lu, Gallery All represents a group of contemporary artists and designers with in-depth research ability and creative potential in interdisciplinary fields, whose outstanding output has been presented at prominent art fairs and exhibitions around the world. With the establishment of the new space in Shanghai, which is positioned to be a "mini-museum", Gallery All will be always committed to increasing the visibility of international designers and artists in Asia, playing a role as an important platform for Asian designers and artists to enter the international vision.
            </div>
            <div className="about_image_des">
              自创立以来，Gallery All代理了一批在跨学科领域具备深度研究能力和创作潜力的艺术家和设计师，他们的研究和创作成果曾在全球各地的先锋艺术博览会和展览中呈现。创始人王愚和陆骁创立画廊的初心 —— “收藏级设计艺术的推手”，也将在未来继续成为画廊的核心，随着以“小型美术馆”定调的上海新空间的设立，Gallery All也将致力于将更多国际艺术家带入国人的视野。          </div>
            <div className="about_image">
              <img className="v_image_before_des" src="/images/aboutus2/image31.jpg" alt="" />
              <div className="about_tips">Yu Wang and Xiao Lu at Design Miami 2019. Photo credit: James Harris.</div>
              <div className="about_tips_mobile">Yu Wang and Xiao Lu at Design Miami 2019. <br></br>Photo credit: James Harris.</div>
            </div>
          </div>

          <div className="about_description">
            <div className="about_image_des about_image_des-haschild">
              By utilizing extensive fabrication resources in China, United States, and the Netherlands, Gallery All provides unique research and production capabilities to its represented artists, which enables them to master progressive manufacturing practices, facilitate expert craftsmanship, and discover new production approaches. The Gallery is committed to cultivate internationally-rising artists’ potentials and reinforce their careers, as well as to introduce new artistic perspectives to established artists and help them to break out of their traditional territories of expression.
            </div>
            <div className="about_image_des">
              With the combined efforts of the gallery, the artists, and designers, Gallery All  fosters mutual growth, providing a prolonged and historical basis for the creative careers of the artists and designers through systematic and in-depth documentation.          </div>
            <div className="about_image_des">
              Gallery Gallery All与合作的艺术家和设计师保持着紧密的协作关系和共同成长的目标。深耕于设计与艺术领域多年，画廊积累了全球范围内最尖端的资源，能够有效地帮助艺术家和设计师将创作灵感变为现实，同时以系统性、深入性的研究和梳理工作为所合作的艺术家和设计师的创作夯实基础。
            </div>
            <div className="about_image">
              <img className="v_image_before_des" src="/images/aboutus2/image41.jpg" alt="" />
              <div className="about_tips">Massless Collection by Todomuta Studio at Design Miami 2021. Photo credit: James Harris.</div>
              <div className="about_tips_mobile">Massless Collection by Todomuta Studio at Design Miami 2021. <br></br>Photo credit: James Harris.</div>
            </div>
          </div>

          <div className="about_description">
            <div className="about_image_des">
              A number of works represented by the Gallery have been placed into significant private collections and permanent collections at the most prestigious institutions, such as Museum of Art and Design in New York, High Museum of Art in Atlanta, M+ Museum in Hong Kong, National Gallery of Victoria in Melbourne, Centre Pompidou in Paris, and Long Museum in Shanghai.
            </div>
            <div className="about_image_des">
              画廊所代理的一些作品已被重要的私人收藏和著名机构纳入永久馆藏，包括纽约艺术设计博物馆、亚特兰大高等艺术博物馆、香港M+博物馆、墨尔本维多利亚国家美术馆、法国蓬皮杜艺术中心和上海龙美术馆等。
            </div>
            <div className="about_image">
              <img className="v_image_before_des" src="/images/aboutus2/image51.jpg" alt="" />
              <div className="about_tips">MAD Martian Collection by Ma Yansong at Design Miami 2017.  Photo credit: James Harris.</div>
              <div className="about_tips_mobile">MAD Martian Collection by Ma Yansong at Design Miami 2017.<br></br>Photo credit: James Harris.</div>
            </div>
          </div>

          <div className="about_description">
            <div className="about_image_des">
              Classic, avant-garde, historical, and experimental — Gallery All continues to present the most distinguished collections of works to a global audience, while   leading Asia's rapidly-escalating art and design market.
            </div>
            <div className="about_image_des">
              经典、前卫、历史性、实验性，是并存于画廊所呈现的作品和展览中的特质，我们在向全球观众展示杰出作品的同时，也引领了亚洲快速发展的艺术和设计市场的发展方向。Gallery All 将以独树一帜的模式继续代理并推广同时具备艺术学术性价值、理念创新性、工艺颠覆性的优秀作品，持续推动设计与艺术概念的融合，打破传统和当代、东方和西方的界限。
            </div>
            <div className="about_image">
              <img className="v_image_before_des" src="/images/aboutus2/image61.jpg" alt="" />
              <div className="about_tips">"First of All," exhibition at Gallery All Shanghai gallery, 2021. Photo credit: Wu Zhiyao.</div>
              <div className="about_tips_mobile">"First of All," exhibition at Gallery All Shanghai gallery, 2021. <br></br>Photo credit: Wu Zhiyao.</div>
            </div>
          </div>
        </div>
        {/* <div className="v__image3">
          <img className="v_img" src="/images/aboutus2/image71.jpg" alt="" />
          <div className="about_tips">Gallery All Shanghai gallery, 2021. Photo credit: Wu Zhiyao.</div>
          <div className="about_tips_mobile">Gallery All Shanghai gallery, 2021. Photo credit: Wu Zhiyao.</div>
        </div> */}

        <HeaderNav
          info={{
            'name': 'Featured New Works',
            'seeAll': 'View All Works',
            'uri': "/artworks"
          }}
          // prev_class="mini_header"
        />
        <div className="c_page">
          {Artworks.length > 0 && (
            <CategoryMondrian artworks={Artworks} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
