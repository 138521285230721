import React, { useState, useEffect } from "react";

import RenderDesktopDescription from "./RenderDesktopDescription_Ver2";
import RenderDesktopArtists from "./RenderDesktopArtists_Ver2";
import RenderDesktopWorks from "./RenderDesktopWorks_Ver2";
import RenderDesktopVideos from "./RenderDesktopVideos";
import RenderDesktopPresses from "./RenderDesktopPresses_Ver2";

import useWindowWidth from "../../components/useWindowWidth";
import { getElementRect, getClientHeight } from "../../utilities/utils";

// import { Controller, Scene } from 'react-scrollmagic';

const RenderContent = (props) => {
  const {
    info = {}
  } = props;
  const [currentType, setCurrentType] = useState(1);
  const [scrollingUp, setScrollingUp] = useState(false);
  const [showItem, setShowItem] = useState(false);
  const [showFixed, setShowFixed] = useState("");

  // 滑动页面到指定位置(锚点功能)
  const scrollToAnchor = (anchorName, pageNum) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: "smooth" });
        setCurrentType(pageNum);
      }
    }
  };

  const windowWidth = useWindowWidth(100);
  const windowHeight = getClientHeight();
  const minHeight = Math.ceil(windowHeight / 3);

  // 滚动判定
  const initScroll = (pageYOffset) => {
    if (window.IsMobile() && windowWidth < 1024) {
      return;
    }
    // 需要定位的容器
    var navs = document.getElementById("fixedNav");
    if (!navs) return;
    if (pageYOffset > minHeight) {
      setShowItem(true);
    } else {
      setShowItem(false);
    }
    var sectionNavs2 = document.getElementById("fixedNav2");
    var navsRect = getElementRect("startBox");
    var navsRectY = navsRect.y;
    // 结束定位
    var endRect = getElementRect("footerBox");
    var endRectY = endRect.y;
    var endRectDocH = endRect.docH;
    if (endRectY + 200 <= endRectDocH || navsRectY >= 5) {
    // if (endRectY <= endRectDocH || navsRectY > 0) {
      navs.style.position = "";
      navs.style.top = "";
      sectionNavs2.style.display = "none";
      // setShowItem(false);
      setShowFixed("");
      return;
    }

    if (navsRectY <= 0 && !showFixed) {
      setShowItem(true);
      setShowFixed("fixed");
      navs.style.position = "fixed";
      navs.style.top = "0";
      sectionNavs2.style.display = "block";
    }

    // var p1Rect = getElementRect("DescriptionPc");
    // var p1RectY = p1Rect.y;

    var p2 = getElementRect("ArtistsPc");
    var p2y = p2.y;
    var p2top = p2.top <= p2.docH / 2;
    if (p2y > 0) {
      if (p2top && currentType !== 2) {
        setCurrentType(2);
      } else {
        setCurrentType(1);
      }
    } else {
      var p3 = getElementRect("ExhibitionPc");
      var p3y = p3.y;
      var p3top = p3.top <= p3.docH / 2;
      if (p3y > 0) {
        if (p3top && currentType !== 3) {
          setCurrentType(3);
        } else {
          setCurrentType(2);
        }
      } else {
        var p4 = getElementRect("VideosPc");
        var p4y = p4.y;
        var p4top = p4.top <= p4.docH / 2;
        if (p4y > 0) {
          if (p4top && currentType !== 4) {
            setCurrentType(4);
          } else {
            setCurrentType(3);
          }
        } else {
          var p5 = getElementRect("SelectedPressPc");
          var p5y = p5.y;
          var p5top = p5.top <= p5.docH / 2;
          if (p5y > 0) {
            if (p5top && currentType !== 5) {
              setCurrentType(5);
            } else {
              setCurrentType(4);
            }
            // if(p5.top+p5.height< ){
            // }
          } else {
            // if (currentType != 4) {
            //setCurrentType(4);
            // }
          }
        }
      }
    }
  };
  
  const trackScrolling = () => {
    var pageYOffset = window.pageYOffset || document.documentElement.scrollTop;
    if (pageYOffset > 300) {
      setScrollingUp(!0);
    } else if (pageYOffset <= 0) {
      setScrollingUp(!1);
    }
    initScroll(pageYOffset);
  };

  useEffect(() => {
    window.addEventListener("scroll", trackScrolling);

    return () => {
      window.removeEventListener("scroll", trackScrolling);
    };
  }, [scrollingUp]);

  let youtubeLink = info.exhibition.content.linkVideo;
  const youtubeLinkArr = (youtubeLink && youtubeLink.split(" ")) || [];

  const liClass = {
    Overview: currentType === 1 ? "active" : "",
    Artists: currentType === 2 ? "active" : "",
    Works: currentType === 3 ? "active" : "",
    Videos: currentType === 4 ? "active" : "",
    Press: currentType === 5 ? "active" : "",
  };

  const liName = {
    Artists: `Artist${info.artists.contents.length > 1 ? "s" : ""}`,
    Works: `Work${info.artworks.contents.length > 1 ? "s" : ""} in the Exhibition`,
    Videos: `Video${youtubeLinkArr.length > 1 ? "s" : ""}`,
    Press: `Selected Press${info.presses.contents.length > 1 ? "es" : ""}`,
  };

  return (
    <React.Fragment>
      <div id="trigger1" className="spacer s0"></div>
      {/* <Controller> */}
      <div id="startBox" className={`work_module_tween ${showFixed}`}>
        <div className="home_nav">
          <div id="fixedNav2" className="left_nav"></div>
          <div id="fixedNav" className="left_nav">
            <div id="pin1" className="nav_group">
              <div
                className={`pc_subTitle pc_navTitle ${
                  showItem ? "active" : ""
                }`}
              >
                {info.exhibition.content.statusText}
              </div>
              <div
                className={`pc_title pc_navTitle ${showItem ? "active" : ""}`}
              >
                {info.exhibition.content.name}
              </div>
              <ul id="nav_btn" className="nav_btn">
                <li
                  className={liClass.Overview}
                  onClick={() => scrollToAnchor("page1", 1)}
                >
                  Overview
                </li>
                <li
                  className={liClass.Artists}
                  onClick={() => scrollToAnchor("page2", 2)}
                >
                  {liName.Artists}
                </li>
                <li
                  className={liClass.Works}
                  onClick={() => scrollToAnchor("page3", 3)}
                >
                  {liName.Works}
                </li>
                {/* {youtubeLinkArr.length !== 0 && */}
                  <li
                    className={liClass.Videos}
                    onClick={() => scrollToAnchor("page4", 4)}
                  >
                    {liName.Videos}
                  </li>
                {/* } */}
                <li
                  className={liClass.Press}
                  onClick={() => scrollToAnchor("page5", 5)}
                >
                  {liName.Press}
                </li>
              </ul>
            </div>
          </div>
          <div id="stateless" className="right_content">
            <span className="Anchor" id="page1">
              &nbsp;
            </span>
            <RenderDesktopDescription exhibition={info.exhibition} />
            <span className="Anchor" id="page2">
              &nbsp;
            </span>
            <RenderDesktopArtists artists={info.artists} />
            <span className="Anchor" id="page3">
              &nbsp;
            </span>
            <RenderDesktopWorks artworks={info.artworks} />
            <span className="Anchor" id="page4">
              &nbsp;
            </span>
            <RenderDesktopVideos youtubeLink={youtubeLink} />
            <span className="Anchor" id="page5">
              &nbsp;
            </span>
            <RenderDesktopPresses presses={info.presses} />
          </div>
        </div>
      </div>

    </React.Fragment>
  )
};

export default RenderContent;
