import React from "react";
import { Row, Col } from "antd";
import Slider from "react-slick";
import useWindowWidth from "../../components/useWindowWidth";

const HeroCarousel = (props) => {
  const { contents = [] } = props;
  const settingSmall = {
    arrows: false,
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 8000,
    variableWidth: true
  };

  const windowWidth = useWindowWidth(100);
  const imgHeight = (windowWidth < 744) ? "235px" : ((windowWidth < 1024) ? "440px" : "600px")

  return (
    <div className="heroCarousel_exhibitions" >
      {contents.length > 0 && (
        <Row>
          <Col>
            <Slider {...settingSmall}>
              {contents.map((image, index) => {
                return (
                  <div className="carouselImg" key={index}>
                    <img src={image.imageUrls[0].imageNormal} alt={`image${index}`} height={imgHeight} width="auto" />
                  </div>
                );
              })}
            </Slider>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default HeroCarousel;
