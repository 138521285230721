import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import FilterBar from "../../views_components/WorkItems/FilterBar.js";
import useFilterBar from "../../views_components/WorkItems/useFilterBar";
// 
import CategoryMondrian from "../../views_components/WorkItems/CategoryMondrian.js";
// import "../../views_components/Version2/Category.css";
// import "../../views_components/Version2/HeaderBox.css";
import ScrollToTopBtn from "../../views_components/ScrollToTopBtn";

import { loadFilterData, loadUserFilterData } from "./WorksFunc.js";

const WorksItems = ({ location = {}, match }) => {
  const { id } = _get(match, "params", {});
  const state = _get(location, "state", {});
  const {
    fromNavHeader = false,
    fromNavRandom = 0,
    formName = "",
    actionType = "",
    artistId = "",
    hideFilter = false,
    fromData = [],
    fromMiniNav = false
  } = state;

  const [filterItem, setFilterItem] = useState([]);
  const [filterType, setFilterType] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const artists = useSelector((state) => state.appContext.artists);
  const artworks = useSelector((state) => state.appContext.works);

  const artistIdNameMap = {};
  artists.forEach(item => {
    artistIdNameMap[item.objectId] = item.name
  })

  // Name
  const artistNameHook = useFilterBar("Artists", [], []);
  const markName = artistNameHook.markedItems;
  const markNameSize = markName.length;

  // Type
  const artistTypeHook = useFilterBar("Types", [], []);
  const markType = artistTypeHook.markedItems;
  const markTypeSize = markType.length;

  const optionData = { actionType, artistId, categoryId: id || null }

  // 初始化{获取对应分类数据}
  const initialization = () => {
    const viewDataArr = fromMiniNav ? fromData : artworks
    const { newData, typeData, nameData } = loadFilterData(viewDataArr, artistIdNameMap, optionData)
    setFilterType(typeData)
    setFilterItem(nameData)
    setFilterData(newData)
  }

  // 确定/重置筛选
  const onFilterConfirm = (isTrue) => {
    setFilterData([]);
    if (isTrue) {
      const viewDataArr = fromMiniNav ? fromData : artworks
      if ((markNameSize > 0 || markTypeSize > 0)) {
        let { newData } = loadUserFilterData(viewDataArr, artistIdNameMap, markName, markType, optionData)
        setFilterData(newData)
      } else {
        let { newData } = loadFilterData(viewDataArr, artistIdNameMap, optionData)
        setFilterData(newData)
      }
    }
  }

  useEffect(() => {
    if (fromNavHeader) {
      // setFilterData([]);
      artistNameHook.setMarkedItems([]);
      artistTypeHook.setMarkedItems([]);
      // initialization();
    }
  }, [fromNavRandom]);

  useEffect(() => {
    initialization();
  }, [artworks])
  // 点击筛选条件
  useEffect(() => { onFilterConfirm(true); }, [markNameSize])
  useEffect(() => { onFilterConfirm(true); }, [markTypeSize])

  return (
    <div className="c_page">
      <div className="header_box">
        <div className="common_title">{formName}</div>
        {
          !hideFilter && (
            <div className="filter_bar flex_header">
              <div className="names_hook">
                <FilterBar
                  filters={artistNameHook}
                  uniqueItems={filterItem}
                />
              </div>
              <div className="type_hook">
                <FilterBar
                  filters={artistTypeHook}
                  uniqueItems={filterType}
                />
              </div>
            </div>
          )
        }
      </div>
      <CategoryMondrian link_route="works" data={filterData} />
      <ScrollToTopBtn />
    </div>
  );
};

export default WorksItems;
