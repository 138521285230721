import React from "react";
import { Divider } from "antd";
import HeaderNav from "../HeaderNav_Ver2";
import { IconDetailRArrow } from "../../images/indexSvg"
import { SeparateParagraph } from "../../utilities/utilities";
import { toUrl } from "../../utilities/utilities";

const WorkDetailArtist = (props) => {
  const {
    artist = {},
    isPcClass = ""
  } = props;

  return (
    <React.Fragment>
      <div className="w_Artist wm_Artist">
        <HeaderNav info={artist}/>
        <div className={`wk_artist_container ${isPcClass}`}>
          <div className="info_left">
            <div className="info_img">
              <img src={artist.content.imageUrls[0].coverImageNormal} alt={artist.content.name} />
            </div>
            <div className="info_name">{artist.content.name}</div>
            {/* <Divider /> */}
            <div className="info_link p__show">
              <div className="m_a_t_Artist">
                <a href={toUrl(artist.content.uri)}>
                  <span className="text">More About the Artist</span>
                  <span className="icon">
                    <IconDetailRArrow />
                  </span>
                </a>
              </div>
              <div className="m_a_t_Artist">
                <a href={toUrl(artist.uriArtwork)}>
                  <span className="text">{artist.seeAllArtwork}</span>
                  <span className="icon">
                    <IconDetailRArrow />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="info_right">{SeparateParagraph(artist.content.biography)}</div>
        </div>
        {!isPcClass && <Divider />}
        <div className="info_link m__show">
          <div className="m_a_t_Artist">
            <a href={toUrl(artist.content.uri)}>
              <span className="text">More About the Artist</span>
              <span className="icon">
                <IconDetailRArrow />
              </span>
            </a>
          </div>
          <div className="m_a_t_Artist">
            <a href={toUrl(artist.uriArtwork)}>
              <span className="text">{artist.seeAllArtwork}</span>
              <span className="icon">
                <IconDetailRArrow />
              </span>
            </a>
          </div>
        </div>
      </div>
      {!isPcClass && <Divider />}
    </React.Fragment>
  )
};
export default WorkDetailArtist;
