import React from "react";
import { IconMoreRightArrow } from "../../images/indexSvg";
import { toUrl } from "../../utilities/utilities";
import "./style.css";

export const FeaturedCarouselType = {
  COLLECTION: "collection",
  ARTIST: "artist",
  EXHIBITION: "exhibition",
};

HeaderNav.FeaturedCarouselType = FeaturedCarouselType;

function HeaderNav(props) {
  const {
    info = {},
    prev_class = ""
  } = props;

  const _className = `cc_header ${prev_class}`;

  return (
    <div className={_className}>
      <div className="title">{info.name}</div>
      <div className="title_link">
        <a href={toUrl(info.uri)}>
          <span>{info.seeAll}</span>
          <IconMoreRightArrow />
        </a>
      </div>
    </div>
  );
}

export default HeaderNav;
