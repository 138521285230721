import React, { useState } from "react";
import NameOptions from "./NameOptions_Ver2";
import { IconUpArrow } from "../../images/indexSvg"


const DropDownMenu = (props) => {
  const {
    info=[],
    preTitle = "All",
    marked=[],
    setMarked = () => {},
    handleReset = () => {},
    handleConfirm = () => {}
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState("none")

  // Mouse in
  const handleMouseOver = () => {
    setModalIsOpen("block")
  }

  // Mouse out
  const handleMouseOut = () => {
    setModalIsOpen("none")
  }

  const onReset = () => {
    handleMouseOut();
    handleReset();
  }
  const onConfirm = () => {
    handleMouseOut();
    handleConfirm();
  }

  const setMarkedDrop = (value, isMarked) => {
    setMarked(value, isMarked);
  }
  const _className = `f_button_header${modalIsOpen !== "none" ? ' actives' : ''}`

  return (
    <div className="f_button">
      <div
        className={_className}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseOut}
      >
        <span className="f__text">{preTitle} Years</span>
        <IconUpArrow />
      </div>
      <div
        className="f_checked f_button_content exhibitions_menu"
        style={{ display: modalIsOpen }}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseOut}
      >
        <div className="f_filter_item">
          <NameOptions 
            info={info}
            marked={marked}
            setMarked={setMarkedDrop}
          />
        </div>
        <div className="f_button_footer">
          <div className="b_btn b_reset" onClick={() => { onReset() }}>Reset</div>
          <div className="b_btn b_confirm" onClick={() => { onConfirm() }}>Confirm</div>
        </div>
      </div>
    </div>
  )
};

export default DropDownMenu;