import React, { useState, Fragment } from "react";
import { IconArtDownArrow } from "../../images/indexSvg"
import DropDownMenu from "./DropDownMenu_Ver2";
import MobileFilter from "./MobileFilter_Ver2";

const FilterBar = (props) => {
  const {
    info = [],
    marked = [],
    setMarked = () => {},
    handleReset = () => {},
    handleConfirm = () => {},
    preTitle = 'All'
  } = props;
  
  const [visible, setVisible] = useState(false);

  const handleOpen = () => {
    setVisible(true)
  }

  const handleClose = () => {
    setVisible(false)
  }
  
  const setMarkedFilter = (value, isMarked) => {
    setMarked(value, isMarked);
  }

  const handleResetFilter = () => {
    handleReset();
  }

  const handleConfirmFilter = () => {
    handleConfirm();
  }

  return (
    <Fragment>
      <div className="Large_Screen_Frame">
        <div className="filter_select">
          <DropDownMenu
            info={info.contents}
            preTitle={preTitle}
            marked={marked}
            setMarked={setMarkedFilter}
            handleReset={handleResetFilter}
            handleConfirm={handleConfirmFilter} 
          />
        </div>  
      </div>
      <div className="Small_Screen_Frame">
        <div className="mobile_filter_btn" onClick={() => handleOpen()}>
        <span>{preTitle} Years</span>
          <IconArtDownArrow />
        </div>
      </div>
      <MobileFilter
        info={info}
        visible={visible}
        handleClose={handleClose}
        marked={marked}
        setMarked={setMarkedFilter}
        handleReset={handleResetFilter}
        handleConfirm={handleConfirmFilter} 
      />
    </Fragment>
  )
};

export default FilterBar;
