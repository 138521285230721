import React, { useState, useEffect } from "react";
import Masonry from "react-masonry-component";
import { Row, Col } from "antd";
import { LazyImg } from "../../components/LazyImg";
import "../Version2/CategoryMondrian.css";
import "../Version2/Wrapper.css";
import useObserver from "../ResizeObserver";
import { setWapperStyle } from "../../utilities/utils";
import { toUrl } from "../../utilities/utilities"

function getColNum(w) {
  const scoll = 16;
  if (w >= 1280 - scoll) return 4;
  if (w >= 1024 - scoll) return 3;
  if (w >= 743 - scoll) return 2;
  return 1;
}

/**
 *
 * @param {ids, names, images}, comes from filter_result of useFilterBar
 */
const CategoryMondrian = (props) => {
  const {
    presses = [],
    realWidth = false 
  } = props;
  const [size, SetSize] = useState(0);
  const measuredRef = React.useRef(null);

  useObserver({
    element: measuredRef,
    callback: (element) => {
      const target = element[0].target;
      const offsetWidth = target.offsetWidth;
      let cn = getColNum(offsetWidth);
      if (realWidth) {
        cn = 2
      }
      SetSize(cn);
    },
  });

  useEffect(() => {
    return () => { };
  }, [size]);

  function getImgAndLink(item) {

    return (
      <div key={item.id} className="column_item">
        <a href={toUrl(item.uri)}>
            {item.imageUrls[0]?
            <LazyImg className="presses_thumb" src={item.imageUrls[0].coverImageNormal} key={item.id} alt={item.name} />:
            ''
            }
        </a>
        <div className="c_text_div">
          <div className="card_name">{item.name}</div>
        </div>
      </div>
    );
  }

  const dataSize = presses.length;

  const renderColumns = (nums) => {
    const total = Math.ceil(dataSize / nums)
    const resArr = [];

    for (let i = 0; i < total; i++) {
      const startNum = i * nums;
      const endNum = i * nums + nums;
      const array = presses.slice(startNum, endNum)
      resArr.push(array)
    }

    return resArr.map((columns, index) => {
      const columnsSize = nums
      const spanNum = (24 / columnsSize)

      let ui = (
        <Row key={index} type="flex" justify="space-between" align="top">
          {
            columns.map((child, childIndex) => {
              let array = [""];
              array = setWapperStyle(array, childIndex, columnsSize);
              const _className = array.join(" ");
              return (<Col span={spanNum} key={childIndex} className={_className}>{getImgAndLink(child)}</Col>)
            })
          }
        </Row>
      )
      return ui
    })
  };

  const masonryOptions = {
    itemSelector: ".never",
    columnWidth: ".never",
    isOriginLeft: true,
  };

  return (
    <div className="common_wrapper presses_wrapper" ref={measuredRef}>
      <Masonry className="c_Masonry" options={masonryOptions}>
        {size > 0 && renderColumns(size)}
      </Masonry>
    </div>
  );
};

export default CategoryMondrian;
