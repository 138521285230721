import React from "react";
import {
  DownloadImage,
  DownloadFiles,
} from "../../components/DownloadImage_Ver2";
import { numberToMonth } from "../../utilities/utils";
import { DownLoadIconV2 } from "../../components/Icons";
import { IconDateTime, IconLocation } from "../../images/indexSvg";

const ExhibitionInfoDesktop = (props) => {
  const {
    exhibition = [],
    images = [],
    files = []
  } = props;

  var fileClass = files.length > 0 ? "downloadIcon" : "downloadIcon-disable";
  var imageClass = images.length > 0 ? "downloadIcon" : "downloadIcon-disable";

  const downloadImage = () => {
    DownloadImage(images, exhibition.content.name);
  };

  const downloadFile = () => {
    DownloadFiles(files, exhibition.content.name);
  };

  const startDate = exhibition.content.startDate.iso.split("T")[0];
  const endDate = exhibition.content.endDate.iso.split("T")[0];

  return (
    <div className="exhibitionDetailWrap">
      <div className="group">
        <div className="pc_subTitle">{exhibition.content.statusText}</div>
        <div className="pc_title">{exhibition.content.name}</div>
      </div>
      <div className="group">
        <div className="view_content">
          <div className="c__content">
            <span className="icon">
              <IconDateTime />
            </span>
            <span className="text">{numberToMonth(startDate, endDate)}</span>
          </div>
        </div>
        {exhibition.content.location ? (
          <div className="view_content">
            <div className="c__content">
              <span className="icon">
                <IconLocation />
              </span>
              <span className="text">{exhibition.content.location}</span>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="group">
        <div className="c__download">
          <div className={`${fileClass} pc__download`} onClick={downloadFile}>
            <span className="download_icon">
              <DownLoadIconV2 />
            </span>
            <span className="download_text">Press Release (PDF)</span>
          </div>
          <div className={`${imageClass} pc__download`} onClick={downloadImage}>
            <span className="download_icon">
              <DownLoadIconV2 />
            </span>
            <span className="download_text">All Images</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExhibitionInfoDesktop;
