import React from "react";
import { Divider } from "antd";

import RenderMobileDescription from "./RenderMobileDescription_Ver2";
import RenderMobileArtists from "./RenderMobileArtists_Ver2";
import RenderMobileWorks from "./RenderMobileWorks_Ver2";
import RenderMobileVideos from "./RenderMobileVideos_Ver2";
import RenderMobilePresses from "./RenderMobilePresses_Ver2";
// import { IconMoreRightArrow } from "../../images/indexSvg";
const RenderContentMobile = (props) => {
  const {
    info = {}
  } = props;

  return (
    <>
      <RenderMobileDescription exhibition={info.exhibition} />
      <Divider style={{ display: info.exhibition.description ? "block" : "none" }} />

      <RenderMobileArtists artists={info.artists} />
      <Divider style={{ display: info.artists.contents.length > 0 ? "block" : "none" }} />

      <RenderMobileWorks artworks={info.artworks} />
      <Divider style={{ display: info.artworks.contents.length > 0 ? "block" : "none" }} />

      <RenderMobileVideos exhibition={info.exhibition} />
      <Divider style={{ display: info.exhibition.content.linkVideo ? "block" : "none" }} />

      <RenderMobilePresses presses={info.presses} />
    </>
  );
};

export default RenderContentMobile;
