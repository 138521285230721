import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  Home,
  Artist,
  Collection,
  Exhibition,
  Presses,
  AboutUs,
  Layout,
  Work,
  WorkDetail,
  ArtistDetail,
  CollectionDetail,
  ExhibitionDetail,
  PressDetail,
  Privacy,
  WorksItems
} from "./views";
import { AppContextProvider } from "./AppContext.js";
import ScrollToTop from "./components/ScrollToTop.js";

class App extends Component {
  constructor(props) {
    super(props);
    this.changeState = (stateKey, stateValue) => {
      let temp = {};
      temp[stateKey] = stateValue;
      this.setState({
        ...this.state,
        ...temp
      });
    };
    this.state = {
      artworks: {},
      artists: {},
      collections: {},
      exhibitions: {},
      changeState: this.changeState
    };
  }
  

  render() {
    var newsProps  = {
      isNews: true,
      title: "News",
      route: "news",
    };

    const routers = [
      {
        path: "/artworks",
        component: internalProps => <Work {...internalProps} />
      },
      {
        path: "/artists",
        component: internalProps => <Artist {...internalProps} />
      },
      {
        path: "/collections",
        component: internalProps => <Collection {...internalProps} />
      },
      {
        path: "/exhibitions",
        component: internalProps => <Exhibition {...internalProps} />
      },
      {
        path: "/about",
        component: internalProps => <AboutUs {...internalProps} />
      },
      {
        path: "/presses",
        component: internalProps => <Presses {...internalProps} />
      },
      {
        path: "/news",
        component: internalProps => <Presses { ...newsProps} />
      },
      {
        path: "/worksItems",
        component: internalProps => <WorksItems {...internalProps} />
      },
      {
        path: "/worksItems/:id",
        component: internalProps => <WorksItems {...internalProps} />
      },
      {
        path: "/artworks/:id",
        component: internalProps => <WorkDetail {...internalProps} />
      },
      {
        path: "/artists/:id",
        component: internalProps => <ArtistDetail {...internalProps} />
      },
      {
        path: "/collections/:id",
        component: internalProps => <CollectionDetail {...internalProps} />
      },
      {
        path: "/exhibitions/:id",
        component: internalProps => <ExhibitionDetail {...internalProps} />
      },
      {
        path: "/presses/:id",
        component: internalProps => <PressDetail {...internalProps} />
      },
      {
        path: "/news/:id",
        component: internalProps => <PressDetail {...{
          ...internalProps,
          isNews: true,
          text: "VIEW ALL News",
          route: "/news"
        }}/>
      },
      {
        path: "/privacy",
        component: internalProps => <Privacy {...internalProps} />
      },
    ];
    return (
      <AppContextProvider>
        <BrowserRouter>
          <ScrollToTop>
            <Switch>
              <Layout>
                <Route exact path="/" component={Home} />
                {routers.map(({ path, component }, key) => (
                  <Route exact path={path} component={component} key={key} />
                ))}
              </Layout>
            </Switch>
          </ScrollToTop>
        </BrowserRouter>
      </AppContextProvider>
    );
  }
}

export default App;
