import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Row, Col } from "antd";

import ScrollToTopBtn from "../../views_components/ScrollToTopBtn";
import ExhibitionInfoDesktop from "../../views_components/ExhibitionDetail/ExhibitionInfoDesktop_Ver2";
import HeroCarouselDesktop from "../../views_components/ExhibitionDetail/HeroCarouselDesktop_Ver2";
import RenderContent from "../../views_components/ExhibitionDetail/RenderContent_Ver2";

import VrDialog from "../../views_components/ExhibitionDetail/VrDialog";
import ExhibitionInfoMobile from "../../views_components/ExhibitionDetail/ExhibitionInfoMobile_Ver2";
import HeroCarousel from "../../views_components/ExhibitionDetail/HeroCarousel_Ver2";
import RenderContentMobile from "../../views_components/ExhibitionDetail/RenderContentMobile_Ver2";
// import LinkPapers from "../../views_components/ExhibitionDetail/LinkPapers";

import "../../scss/ExhibitionDetail.css";

const ExhibitionDetail = (props) => {
  const {
    match = {},
  } = props;

  const [ExhibitionInfo, setExhibitionInfo] = useState([]);
  // const [prveSlide, setPrveSlide] = useState(null);
  // const [nextSlide, setNextSlide] = useState(null);

  // 数据请求
  const getExhibitionDetail = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebExhibitionDetail",
      {
        exhibitionId: match.params.id
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }
  useEffect(() => {
    getExhibitionDetail().then(result => {
      setExhibitionInfo(result.data.result);
    });
  }, [match.params.id]);
  // 数据成功返回
  if (ExhibitionInfo.length !== 0) {
    return (
      <div className="collectionDetailWrap VerWrap2">
        <div id="overlay">
          <Row>
            {/* Desktop */}
            <Col xs={0} lg={24}>
              <div className="pc">
                <ScrollToTopBtn />
                <ExhibitionInfoDesktop
                  exhibition={ExhibitionInfo.exhibition}
                  images={ExhibitionInfo.images.contents}
                  files={ExhibitionInfo.files.contents}
                />
                <HeroCarouselDesktop contents={ExhibitionInfo.images.contents} linkVr={ExhibitionInfo.exhibition.content.linkVr} />
                {/* <HeroCarouselDesktop contents={ExhibitionInfo.images.contents} linkVr='' /> */}
                <RenderContent
                  info={ExhibitionInfo}
                />
              </div>
            </Col>
            {/* Mobile */}
            <Col xs={24} sm={24} lg={0}>
              <div className="mobile">
                {ExhibitionInfo.exhibition.content.linkVr ? <VrDialog linkVr={ExhibitionInfo.exhibition.content.linkVr} /> : ""}
                <ExhibitionInfoMobile
                  exhibition={ExhibitionInfo.exhibition}
                  images={ExhibitionInfo.images.contents}
                  files={ExhibitionInfo.files.contents}
                />
                <HeroCarousel contents={ExhibitionInfo.images.contents} />
                <RenderContentMobile info={ExhibitionInfo} />
              </div>
            </Col>
          </Row>
        </div>
        {/* <LinkPapers prveSlide={prveSlide} nextSlide={nextSlide} /> */}
      </div>
    )
  } else {
    return <div/>
  }


};

export default ExhibitionDetail;
