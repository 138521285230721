import _get from "lodash/get";

export const getUniqueItems = (items) => {
  const array = [...new Set(items)];
  const unEmpty = array.filter((item) => item && item !== "");
  return unEmpty;
};

export const loadFilterData = (worksList, artistIdNameMap, option = {}) => {
  const { artistId, actionType, categoryId } = option
  const newData = [];
  const typeData = [];
  const nameData = [];
  worksList.forEach((item) => {
    const _categoryId = _get(item, "categoryId", "");
    const _artistId = _get(item, "artistId", "");
    const type = _get(item, "type", "");
    const authorName = artistIdNameMap[_artistId]
    const params = {
      ...item,
      artitsName: authorName
    }
    if (actionType) {
      if (actionType === "view") {
        if (_artistId === artistId) {
          newData.push(params)
          typeData.push(type)
          nameData.push(authorName)
        }
      }
      if (actionType === "single") {
        newData.push(params)
        typeData.push(type)
        nameData.push(authorName)
      }
    } else {
      if (_categoryId === categoryId) {
        newData.push(params)
        typeData.push(type)
        nameData.push(authorName)
      }
    }
  });
  const _type = getUniqueItems(typeData)
  const _name = getUniqueItems(nameData)
  const result = { newData, typeData: _type, nameData: _name }
  return result
}

export const loadUserFilterData = (worksList, artistIdNameMap, nameMark = [], typeMark = [], option) => {
  const { newData } = loadFilterData(worksList, artistIdNameMap, option)
  // 类型筛选
  const newListData = [];
  newData.forEach(item => {
    // 优先过滤作者作品
    if (nameMark.length > 0) {
      const artistsName = item.artitsName
      const isMark = nameMark.includes(artistsName)
      if (isMark) {
        newListData.push(item)
      }
    } else {
      newListData.push(item)
    }
  })
  const filterData = []
  newListData.forEach(item => {
    if (typeMark.length > 0) {
      const type = item.type
      const isMark = typeMark.includes(type)
      if (isMark) {
        filterData.push(item)
      }
    } else {
      filterData.push(item)
    }

  })
  return { newData: filterData }
}

export const loadCompressData = (artworks = [], artistIdNameMap = {},) => {
  const newData = [];
  const typeData = [];
  const nameData = [];

  artworks.forEach(item => {
    const _artistId = _get(item, "artistId", "");
    const type = _get(item, "type", "");
    const authorName = artistIdNameMap[_artistId]
    const params = {
      ...item,
      artitsName: authorName
    }
    newData.push(params)
    typeData.push(type)
    nameData.push(authorName)
  })

  const _type = getUniqueItems(typeData);
  const _name = getUniqueItems(nameData);
  const result = { newData, typeData: _type, nameData: _name };
  return result;
};
