import "bootstrap/dist/css/bootstrap.min.css";
// import "antd/dist/antd.css";
// 组件样式
import "nprogress/nprogress.css";
// import "./scss/commonStyle.css";
import "./views_components/Version2/SmallSlider.css";
import Style from "./views_components/CommonStyle"

import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./redux/store/index";
// 虚拟loading
import nprogress from "nprogress"
nprogress.configure({ showSpinner: false, Style })
React.NProgress = nprogress;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
