export const getStatusText = (startDate, endDate) => {
  let statusText = "";
  if (startDate && endDate) {
    const _nowDateTime = new Date().getTime();
    const _startDateTime = new Date(startDate).getTime();
    const _endDateTime = new Date(endDate).getTime();
    if (_startDateTime > _nowDateTime) {
      statusText = "Upcoming"
    }
    if (_endDateTime < _nowDateTime) {
      statusText = "Past"
    }
    if (_startDateTime < _nowDateTime && _endDateTime > _nowDateTime) {
      statusText = "Ongoing"
    }
  }
  return statusText
}