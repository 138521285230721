import React from "react";
import { toUrl } from "../../utilities/utilities";
import HeaderNav from "../HeaderNav_Ver2";

const SmallSlider = (props) => {
  const {
    artists = {}
  } = props;
  return (
    <div className="c__slide exhibition_slider">
      {/* <div className="horizontal_header">
        <div className="title">{artists.name}</div>
      </div>
      <div className="title_link">
        <a href={toUrl(artists.uri)}>
          <span>{artists.seeAll}</span>
          <IconMoreRightArrow />
        </a>
      </div> */}
      <HeaderNav 
        info={artists} 
        prev_class="mini_header"
      />
      <div className="horizontal_slide">
        {artists.contents.map(artist => {
          return (
            <a href={toUrl(artist.uri)} key={artist.id}>
              <div className="small_card">
                <div className="small_image">
                  <img src={artist.imageUrls[0].coverImageNormal} alt={artist.name} />
                </div>
                {/* <div className="smallSlider_name title">{typeArt}</div> */}
                <div className="small_art_name">{artist.name}</div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SmallSlider;
