import React from "react";
import { Checkbox, Row } from "antd";
import { CloseIcon } from "../../../components/Icons";
import "./style.css";

const MobileFilter = (props) => {
  const {
    info=[],
    visible = false,
    handleClose = () => {},
    marked = [],
    setMarked = () => {},
    handleReset = () => {},
    handleConfirm = () => {},
  } = props;
  const handleOnClick = (value) => {
    const checked = value.target.checked;
    const checkedValue = Number(value.target.value);
    if (checked) {
      setMarked(checkedValue, true);
    } else {
      setMarked(checkedValue, false)
    }
  }
  const onConfirm = () => {
    handleClose();
    handleConfirm();
  }
  const onReset = () => {
    handleClose();
    handleReset();
  }
  return (
    <div className="m_filter" style={{ display: visible ? 'block' : 'none' }}>
       <div className="f_top">
        <span className="f_title">{info.name} Filter</span>
        <span className="f_close" onClick={() => handleClose()}>
          <CloseIcon />
        </span>
      </div> 
      <div className="f_body">
        <div className="f_content">
          <Row className="f_checked m_filter_list" span={24}>
            {
              info.contents.map((item) => {
                let checked = false;
                if (marked.includes(item)){
                  checked = true;
                }
                return (
                  <div className="m_filter_item" key={item}>
                    <Checkbox
                      value={item}
                      onClick={(value) => handleOnClick(value)}
                      checked={checked}
                      style={{ textAlign: "left" }}
                    />
                    <span className="m_styled_span">{item}</span>
                  </div>
                );
              })
            }
          </Row>
        </div>
        <div className="f_btn_group">
          <div className="t__text reset" onClick={() => { onReset() }}>Reset</div>
          <div className="t__text confirm" onClick={() => { onConfirm() }}>Confirm</div>
        </div>
      </div>
    </div>
  )
}

export default MobileFilter;