import React, { useState, useEffect } from "react";
// import * as Axios from "axios";
// import "../AboutUs/about.css";
import "../AboutUs/about_style.css";
import "./privacy.css";
// import _get from "lodash/get";
//import SelectPress from "../../components/SelectPress";
// import FeaturedNewWork from "../Home/FeaturedNewWork";

const Privacy = () => {
  // const [newWork, setNewWork] = useState([]);
  const [mounted, setMounted] = useState(false);

  // const getClusters = () => {
  //   return Axios.post(
  //     "https://api.galleryall.com/parse/functions/getClusters",
  //     {
  //       identifier: "com.Yifan.ArtPod",
  //       isHome: true,
  //       token: "hzFLPibydFMgNAASee6IwgE8zDLLtZPK",
  //     },
  //     {
  //       headers: {
  //         "X-Parse-Application-id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo",
  //         "Content-Type": "application/json"
  //       }
  //     }
  //   )
  // }

  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 600)
    // getClusters().then(result => {
    //   setNewWork(_get(result, "data.result.clusters", []));
    // });
  }, []);

  return (
    <div className="aboutusWrap privacyVer2" style={{ display: mounted ? "block" : "none" }}>
      <div className="aboutHeader">
        <div className="aboutHeader_board privacy">
          <div className="aboutHeader_title privacy">Privacy Policy</div>
        </div>
        <div className="privacy_tips">Gallery All Shanghai gallery, 2021. Photo credit: Wu Zhiyao.</div>
      </div>
      <div className="privacy_tips privacy_tips_mobile">Gallery All Shanghai gallery, 2021.<br></br>Photo credit: Wu Zhiyao.</div>
      <div className="about_middle_des privacy">
        <p className="pb40">
          When we say "we," "us," “Gallery All,” and "ArtPod," we're referring to Gallery All Limited. When we say "you" or "Member," we're referring to the person or entity that's registered with us to use the Services.
        </p>
        <p className="pb40">
          We does not sell, lease or give your Personal Information, Site Usage Information or Files (collectively, "User Information") to any other company absent your consent.
        </p>
        <p className="mb0">We use User Information as follows:</p>
        <ul className="aboutHeader_content_list privacy pb40">
          <li>To provide and improve our Service.</li>
          <li>To support and administer your use of the Service.</li>
          <li>To provide or offer service updates and product announcements.</li>
          <li>
            To analyze user behavior as a measure of interest in, and use of, the Site and emails,
            both on an individual basis and in the aggregate.
          </li>
          <li>
            To monitor and analyze the use of the Service, for the Service's technical administration,
            to increase our Service's functionality and user-friendliness,
            and to verify users have the authorization needed for the Service to process their requests.
          </li>
        </ul>
        <p className="mb0">Users consent to the sharing of User Information with third parties in certain limited circumstances:</p>
        <ul className="aboutHeader_content_list privacy pb40">
          <li>
            Use of certain trusted third party companies and individuals to help us provide,
            analyze, and improve the Service (including but not limited to data storage, maintenance services,
            database management, web analytics, payment processing, and improvement of the Service's features).
            These third parties may have access to your User Information only for purposes of performing these
            tasks on our behalf and under obligations similar to those in this Privacy Policy. By using our Service,
            you consent to our sharing of your User Information with these trusted third party companies.
          </li>
          <li>To comply with laws and law enforcement requests and to protection of ArtCloud's rights.</li>
          <li>
            If we are involved in a merger, acquisition, or sale of all or a portion of our assets,
            you consent to the transfer of your User Information as part of that transaction,
            but we will notify you of any change in control or use of your User Information,
            or if your User Information become subject to a different Privacy Policy.
          </li>
          <li>
            We may disclose aggregate statistics using User Information,
            such as overall patterns or demographic reports that do not describe or identify any individual user.
          </li>
        </ul>
        <div className="privacy_subtitle">Data Controller</div>
        <div className="about_image_des privacy_subcontent">
          We do not own, control or direct the use of any of the Client Data stored or processed
          by a Client or User via the Service. Only the Client or Users are entitled to access,
          retrieve and direct the use of such Client Data. We are largely unaware of what Client
          Data is actually being stored or made available by a Client or User to the Service and
          does not directly access such Client Data except as authorized by the Client, or as necessary
          to provide Services to the Client and its Users.
        </div>
        <div className="privacy_subtitle">Data Security</div>
        <div className="about_image_des privacy_subcontent">
          We follow generally accepted industry standards to protect the information submitted to us,
          both during transmission and once we receive it. We maintain appropriate administrative,
          technical and physical safeguards to protect Personal Data against accidental or unlawful destruction,
          accidental loss, unauthorized alteration, unauthorized disclosure or access, misuse,
          and any other unlawful form of processing of the Personal Data in our possession.
          This includes, for example, firewalls, password protection and other access and authentication controls.
          We use SSL technology to encrypt data during transmission through public internet,
          and we also employ application-layer security features to further anonymize Personal Data.
          However, no method of transmission over the Internet, or method of electronic storage, is 100% secure.
          We cannot ensure or warrant the security of any information you transmit to us or store on the Service,
          and you do so at your own risk. We also cannot guarantee that such information may not be accessed,
          disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.
          If you believe your Personal Data has been compromised, please contact us as set forth in the “Contact Us” section.
          If we learn of a security systems breach, we will inform you and the authorities of the occurrence of the breach in accordance with applicable law.
        </div>
        <div className="privacy_subtitle">Changes and Updates to this Policy</div>
        <div className="about_image_des privacy_subcontent">
          Please revisit this page periodically to stay aware of any changes to this Policy,
          which we may update from time to time. If we modify the Policy, we will make it available through the Service,
          and indicate the date of the latest revision, and will comply with applicable law.
          Your continued use of the Service after the revised Policy has become effective indicates that you have read,
          understood and agreed to the current version of the Policy.
        </div>
      </div>
    </div>
  );
};

export default Privacy;
