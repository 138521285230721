import React from "react";
import CreateVideoBody from "../CreateYoutube/index";

const RenderVideoMobile = (props) => {
  const {
    exhibition = {}
  } = props;
  let youtubeLink = exhibition.content.linkVideo;

  const youtubeLinkArr = (youtubeLink && youtubeLink.split(" ")) || [];
  const isSize = youtubeLinkArr.length;

  const playArr = youtubeLinkArr.map((item) => {
    return item.replace("watch?", "embed/") || "";
  });

  return (
    <div className="render_videos_container">
      {isSize > 0 && (
        <React.Fragment>
          <div className="title">Video{isSize > 1 ? "s" : ""}</div>
          <CreateVideoBody videList={playArr} />
        </React.Fragment>
      )}
    </div>
  );
};

export default RenderVideoMobile;
