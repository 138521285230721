import React from "react";
import { Typography } from "antd";
import styled from "styled-components";

const { Title, Text } = Typography;

const StyledTitle = styled(Title)`
  && {
    height: 30px;
    font-family: MaisonNeue-Book;
    font-size: 21px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--dark);

    @media (max-width: 376px) {
      height: 30px;
      font-size: 18px;
      line-height: 1.67;
      color: var(--dark);
    }
  }
`;
const StyledEmail = styled(Text)`
  && {
    height: 24px;
    font-family: MaisonNeue-Book;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--dark);

    @media (max-width: 376px) {
      height: 20px;
      font-family: MaisonNeue-Book;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: var(--dark);
    }
  }
`;
const StyledText = styled(Text)`
  && {
    width: 151px;
    height: 24px;
    font-family: MaisonNeue-Book;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--dark);

    @media (max-width: 376px) {
      height: 20px;
      font-family: MaisonNeue-Book;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: var(--dark);
      margin-top: 8px;
    }
  }
`;

const Contact = () => {
  return (
    <div className="contact-group">
      <div className="lg_title">
        <StyledTitle> Contact </StyledTitle>
      </div>
      <div className="contact-item">
        <StyledEmail>
          <span className="icon">
            <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.2 0C12.9176 0 13.5 0.56 13.5 1.25V8.75C13.5 9.44 12.9176 10 12.2 10H1.8C1.0824 10 0.5 9.44 0.5 8.75V1.25C0.5 0.56 1.0824 0 1.8 0H12.2ZM11.5499 1.95319L7.32493 5.16069C7.13448 5.30444 6.86538 5.30444 6.67493 5.16069L2.44993 1.95319V3.20319L5.04213 5.17256L2.44993 6.95319V8.20319L5.90598 5.82944L6.08083 5.96194C6.58848 6.45006 7.41139 6.45006 7.91903 5.96194L8.09388 5.82944L11.5499 8.20319V6.95319L8.95774 5.17256L11.5499 3.20319V1.95319Z" fill="black"/>
            </svg>
          </span>
          <span>info@galleryall.com</span>
        </StyledEmail>
      </div>
      <div className="contact-item">
        <StyledText>
          <span className="icon">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.0001 8.99435L7.69706 8.09718L7.68506 8.10918L7.07871 8.71482L6.83871 8.95482L6.36083 9.43341C4.68083 8.67388 3.32977 7.32282 2.57095 5.64282L3.04812 5.16424L3.28812 4.92424L3.90859 4.30377L3.022 0.0550589L3.01142 0C2.62671 0 2.39589 0.0148236 2.32883 0.0197648C1.02718 0.122824 0.00012207 1.19929 0.00012207 2.52706C0.00012207 4.16894 0.41871 5.71341 1.15353 7.05953C2.026 8.65835 3.3453 9.97835 4.94412 10.8501C6.29024 11.5849 7.83471 12.0035 9.47659 12.0035C10.8044 12.0035 11.8787 10.9765 11.9839 9.67482C11.9959 9.51812 12.0001 9.33459 12.0001 8.99435Z" fill="black"/>
            </svg>
          </span>
          <span>+1 (213) 294-5564</span>
          </StyledText>
      </div>
      <div className="contact-item">
        <StyledText>
          <span className="icon">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.0001 8.99435L7.69706 8.09718L7.68506 8.10918L7.07871 8.71482L6.83871 8.95482L6.36083 9.43341C4.68083 8.67388 3.32977 7.32282 2.57095 5.64282L3.04812 5.16424L3.28812 4.92424L3.90859 4.30377L3.022 0.0550589L3.01142 0C2.62671 0 2.39589 0.0148236 2.32883 0.0197648C1.02718 0.122824 0.00012207 1.19929 0.00012207 2.52706C0.00012207 4.16894 0.41871 5.71341 1.15353 7.05953C2.026 8.65835 3.3453 9.97835 4.94412 10.8501C6.29024 11.5849 7.83471 12.0035 9.47659 12.0035C10.8044 12.0035 11.8787 10.9765 11.9839 9.67482C11.9959 9.51812 12.0001 9.33459 12.0001 8.99435Z" fill="black"/>
              </svg>
          </span>
          <span>+86 189-1029-1250 (中国)</span>
          </StyledText>
      </div>
    </div>
  );
};

export default Contact;
