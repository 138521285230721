import React, { useState, useEffect } from "react";
import FilterBar from "../../views_components/Exhibition/FilterBar_Ver2";
import ImgSwiper from "../../views_components/Ongoing/ImgSwiper_Ver2";
import CategoryMondrian from "../../views_components/Exhibition/CategoryMondrian_Ver2";
import ScrollToTopBtn from "../../views_components/ScrollToTopBtn";
import Axios from "axios";
import { result } from "lodash";
// import { StyledLoadMore, StyledLoadMoreButton} from "../../views_components/LoadMoreButton_Ver2";

const Exhibition = (props) => {
  const {
    location = {},
  } = props;
  let searchedArtistId = undefined;
  if (location.search.split('=')[0] === '?artistId') {
    searchedArtistId = location.search.split('=')[1];
  }

  const [Years, setYears] = useState(undefined);
  const [MarkedYears, setMarkedYears] = useState([]);
  const [HightLightExhibitions, setHightLightExhibitions] = useState([]);
  const [Exhibitions, setExhibitions] = useState([]);
  const [PreTitleYear, setPreTitleYear] = useState('All');
  
  const numberOfItem = 24;
  const [Result, setResult] = useState([]);
  const [LoadMore, setLoadMore] = useState(true);
  const [Token, setToken] = useState(undefined);

  // 懒加载
  const toggleLoadMore = () => {
    var scrollTop = document.documentElement.scrollTop;
    var clientHeight = document.documentElement.clientHeight;
    var scrollHeight = document.documentElement.scrollHeight;
    if (LoadMore) {
      if(scrollTop+clientHeight >= scrollHeight && Result.length !== 0){
        // 异步操作
        setToken(Result.exhibitions.token)
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleLoadMore);
    return () => {
      window.removeEventListener("scroll", toggleLoadMore);
    }
  }, [Result]);


  const getExhibitions = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebExhibitions",
      {
        years: Years,
        token: Token
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }  
  const getExhibitionsFromArtist = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebExhibitions",
      {
        token: Token,
        artistId: searchedArtistId
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }

  // 数据请求
  if (searchedArtistId !== undefined) {
    useEffect(() => {
      getExhibitionsFromArtist().then(result => {
        if (Token === undefined) {
          console.log(result.data.result.exhibitions.contents)
          setExhibitions(result.data.result.exhibitions.contents);
          setHightLightExhibitions(result.data.result.exhibitions.highlightContents);
        } else if (result.data.result.exhibitions.contents.length > 0) {
          let tempExhibitions = [...Exhibitions];
          result.data.result.exhibitions.contents.forEach(item => {
            tempExhibitions.push(item);
          })
          setExhibitions(tempExhibitions);
          if (result.data.result.exhibitions.contents.length < numberOfItem) {
            setLoadMore(false);
          }
        } else {
          setLoadMore(false);
        }
        setResult(result.data.result);
      });
    }, [Token])
  } else {
    useEffect(() => {
      getExhibitions().then(result => {
        if (Token === undefined) {
          setExhibitions(result.data.result.exhibitions.contents);
          setHightLightExhibitions(result.data.result.exhibitions.highlightContents);
        } else if (result.data.result.exhibitions.contents.length > 0) {
          let tempExhibitions = [...Exhibitions];
          result.data.result.exhibitions.contents.forEach(item => {
            tempExhibitions.push(item);
          })
          setExhibitions(tempExhibitions);
          if (result.data.result.exhibitions.contents.length < numberOfItem) {
            setLoadMore(false);
          }
        } else {
          setLoadMore(false);
        }
        setResult(result.data.result);
      })
    }, [Token, Years])

  }
  
  // years filter bar
  const today = new Date();
  const thisYear = today.getFullYear();
  const years = [];
  for(var i = thisYear + 1; i >= 2014; i--){
    years.push(i);
  }

  const YearFilterbar = {
    'name': 'Years',
    'contents': years
  }

  // Helper function remove element from an array
  function arrayRemove(arr, value) { 
    return arr.filter(function(ele){ 
        return ele !== value; 
    });
  }

  const setMarkedYear = (value, isMarked) => {
    let tempMarkedYears = [...MarkedYears];
    if (isMarked) {
      tempMarkedYears.push(value);
      setPreTitleYear('Selected');
      setMarkedYears(tempMarkedYears);
    } else {
      tempMarkedYears = arrayRemove(tempMarkedYears, value);
      if (tempMarkedYears.length === 0) {
        setPreTitleYear('All');
      }
      setMarkedYears(tempMarkedYears);
    }
  };

  const handleResetYear = () => {
    setMarkedYears([]);
    setExhibitions([]);
    setLoadMore(true);
    setPreTitleYear('All');
    setYears(undefined);
    // 重点展示
    setHightLightExhibitions([]);
    setToken(undefined);
    setResult([]);
  };

  const handleConfirmYear = () => {
    if (MarkedYears.length !== 0) {
      setExhibitions([]);
      setResult([]);
      setYears(MarkedYears);
      // 重点展示
      setHightLightExhibitions([]);
      setToken(undefined);
      setLoadMore(true);
    }
  };
  let filterBar = true;
  if (searchedArtistId !== undefined) {
    filterBar = false;
  }

  // 决定title
  let title = 'Exhibitions';
  // if (searchedArtistId !== undefined) {
  //   if (HightLightExhibitions.length !== 0) {
  //     title = `Exhibitions relevent to ${HightLightExhibitions[0].artistName}`
  //   } else if (Exhibitions.length !== 0) {
  //     title = `Exhibitions relevent to ${Exhibitions[0].artistName}`
  //   }
  // }

  return (
    <div className="c_page">
      <div className="header_box">
        <div className="common_title">{title}</div>
        <div className="filter_bar">
          {filterBar && 
            <FilterBar 
              info={YearFilterbar}
              marked={MarkedYears}
              setMarked={setMarkedYear}
              handleReset={handleResetYear}
              handleConfirm={handleConfirmYear}
              preTitle={PreTitleYear}
            />
          }
        </div>
      </div>
      {Token !== '0' && (
        <ImgSwiper exhibitions={HightLightExhibitions} />
      )}
      {(Exhibitions.length !== 0) && (
        <CategoryMondrian exhibitions={Exhibitions} />
      )}
      <ScrollToTopBtn />
    </div>
  )
};

export default Exhibition;
