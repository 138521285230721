import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateAppContextWorks,
  updateAppContextArtists,
  updateAppContextCollection,
  updateAppContextExhibition,
  // Version2
  updateAppCategories,
  updateOnCollections,
  updateOnExhibitions,
} from "./redux/actions/index";
import _get from "lodash/get";
import * as Axios from "axios";
import { sortByUpdateAt } from "./utilities/utilities";

/*
 * 注意：本来是想用Context来传递API call的response的值，但是突然发现好像在Axios的call里面更新state
 * 不会引起使用useContext的子节点的re-reender, 此处使用了额外一个visible的state来强制re-render，
 * 但是这个是hacky implementation，之后应该寻找一下更合适的方案。
 *
 * Update:
 * 现在改为AppCotext拿到networking call的数据之后，更新redux store的数据，引用redux store数据的
 * 子节点都会因此而更新
 */

// Create context
const initialState = {
  artworks: [],
  artists: [],
  collections: [],
  exhibitions: [],
  isDownloadError: false,
};
const AppContext = React.createContext(initialState);

// Create ContextProvider component
export const AppContextProvider = (props) => {
  // Setup all the prepareation work!
  // Send the downloading reaquest, return the Promise.
  const sendRequest = (param) => {
    const params = {
      identifier: "com.Yifan.ArtPod",
      isPublic: true,
      token: "hzFLPibydFMgNAASee6IwgE8zDLLtZPK",
    };
    const headers = {
      headers: {
        "X-Parse-Application-id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo",
        "Content-Type": "application/json",
      },
    };
    if (param === "getClusters") {
      params.isHome = true;
    }
    return Axios.post(
      `https://api.galleryall.com/parse/functions/${param}`,
      params,
      headers
    );
  };

  const setterDict = {
    getArtworks: "artworks",
    getArtists: "artists",
    getCollections: "collections",
    getExhibitions: "exhibitions",
    getCategories: "categories",
    getClusters: "clusters",
  };

  const taskArraylist = [
    // "getClusters",
    "getCategories",
    "getArtists",
    "getCollections",
    "getExhibitions",
    "getArtworks",
  ];

  // wrapperedResponse 还是可以临时存储数据，但是暂时没有使用了，改为使用redux store
  const [wrapperedResponse, setWrapperedResponse] = useState(initialState);

  var taskMarker = new Set(taskArraylist);
  var dataObject = wrapperedResponse;

  // Redux dispatch:
  const dispatch = useDispatch();

  // Request Sucess
  const reqSuccess = (dataType, response) => {
    // set state for that data item
    let dataContent = _get(response, `data.result.${dataType}`, []);
    // dispatch the data to the redux store:
    switch (dataType) {
      case "clusters":
        const collection = dataContent[2] || {};
        const exhibition = dataContent[3] || {};
        let arrayCol = [];
        if ("contents" in collection) {
          arrayCol = collection.contents.slice(0, 2);
          dispatch(updateOnCollections(arrayCol));
        }
        let arrayExh = [];
        if ("contents" in exhibition) {
          arrayExh = exhibition.contents.slice(0, 2);
          dispatch(updateOnExhibitions(arrayExh));
        }
        dataContent = { collection: arrayCol, exhibition: arrayExh };
        break;
      case "artworks":
        dataContent = sortByUpdateAt(dataContent);
        dispatch(updateAppContextWorks(dataContent));
        break;
      case "artists":
        dispatch(updateAppContextArtists(dataContent));
        break;
      case "collections":
        dataContent = sortByUpdateAt(dataContent);
        dispatch(updateAppContextCollection(dataContent));
        break;
      case "exhibitions":
        dispatch(updateAppContextExhibition(dataContent));
        break;
      default:
        break;
    }
    return dataContent;
  };

  // Loaded the required data once.
  useEffect(() => {
    taskArraylist.forEach((param) => {
      sendRequest(param)
        .then((response) => {
          // get data type in the state
          let dataType = setterDict[param];
          let dataContent = {};
          if (param !== "getCategories") {
            dataContent = reqSuccess(dataType, response);
          } else {
            let categories = _get(response, `data.result.categories`, []);
            let tags = _get(response, `data.result.tags`, []);
            dataContent = { categories, tags };
            dispatch(updateAppCategories(dataContent));
          }

          dataObject[dataType] = dataContent;
          taskMarker.delete(param);
          if (taskMarker.size === 0) {
            setWrapperedResponse(dataObject);
          }
        })
        .catch((err) => {
          dataObject.isDownloadError = true;
          setWrapperedResponse(dataObject);
          console.log(err);
        });
    });

    return () => { };
  }, []);

  return (
    <AppContext.Provider value={wrapperedResponse}>
      <div className="AppContextProviderWrapper">{props.children}</div>
    </AppContext.Provider>
  );
};
export default AppContext;
