import React, { useState, useEffect } from "react";
import FilterBar from "../../views_components/Works/FilterBar_Ver2"
import CategoryMondrian from "../../views_components/Works/CategoryMondrian_Ver2.js";
import ScrollToTopBtn from "../../views_components/ScrollToTopBtn";

import Axios from "axios";

const Work = (props) => {
  const {
    location = {},
  } = props;
  let searchedArtistId = undefined;
  let searchedCategoryId = undefined;
  let searchedCollectionId = undefined;
  let searchedPressId = undefined;
  let searchedExhibitionId = undefined;
  if (location.search.split('=')[0] === '?artistId') {
    searchedArtistId = location.search.split('=')[1];
  } else if (location.search.split('=')[0] === '?categoryId') {
    searchedCategoryId = location.search.split('=')[1];
  } else if (location.search.split('=')[0] === '?collectionId') {
    searchedCollectionId = location.search.split('=')[1];
  } else if (location.search.split('=')[0] === '?pressId') {
    searchedPressId = location.search.split('=')[1];
  } else if (location.search.split('=')[0] === '?exhibitionId') {
    searchedExhibitionId = location.search.split('=')[1];
  }
  // categoryId: location.search.split('=')[1]
  // 用来filter 更新
  const [pre_titleArtist, setpre_titleArtist] = useState('All');
  const [pre_titleType, setpre_titleType] = useState('All');
  const [ArtistIds, setArtistIds] = useState(undefined);
  const [MarkedArtists, setMarkedArtists] = useState([]);
  const [TagIds, setTagIds] = useState(undefined);
  const [MarkedTypes, setMarkedTypes] = useState([]);
  const [ArtistFilterbar, setArtistFilterbar] = useState([]);
  const [TypeFilterbar, setTypeFilterbar] = useState([]);
  const [Artworks, setArtworks] = useState([])
  const [Title, setTitle] = useState('Works');
  
  const numberOfItem = 24;
  // 分页
  const [Page, setPage] = useState(0);

  // 懒加载
  const toggleLoadMore = () => {
    var scrollTop = document.documentElement.scrollTop;
    var clientHeight = document.documentElement.clientHeight;
    var scrollHeight = document.documentElement.scrollHeight;
    if (LoadMore) {
      if(scrollTop+clientHeight >= scrollHeight){
        // 异步操作
        // setTimeout(setPage(Page + 1),0);
        setPage(Page + 1);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleLoadMore);
    return () => {
      window.removeEventListener("scroll", toggleLoadMore);
    }
  }, [Page]);
  const [LoadMore, setLoadMore] = useState(1);

  console.log(Artworks.length);
  console.log(Page)
  console.log(LoadMore)
  // filter bar
  const [needFilterbar, setneedFilterbar] = useState(true);
  
  const getArtworkFromArtist = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebArtworks", 
      {
        artistIds: [searchedArtistId],
        includesFilter: false,
        page: Page
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }
  const getArtworkFromCollection = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebArtworks", 
      {
        collectionId: searchedCollectionId,
        includesFilter: false,
        page: Page
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }  
  const getArtworkFromPress = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebArtworks", 
      {
        pressId: searchedPressId,
        includesFilter: false,
        page: Page
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }
  const getArtworkFromExhibition = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebArtworks", 
      {
        exhibitionId: searchedExhibitionId,
        includesFilter: false,
        page: Page
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }

  const getArtworks = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebArtworks", 
      {
        tagIds: TagIds,
        artistIds: ArtistIds,
        categoryId: searchedCategoryId,
        includesFilter: needFilterbar,
        page: Page
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }
  // 数据请求 Artworks
  if (searchedArtistId !== undefined) {
    useEffect(() => {
      getArtworkFromArtist().then(result => {
        if (result.data.result.artworks.contents.length > 0) {
          let tempArtworks = [...Artworks];
          result.data.result.artworks.contents.forEach(item => {
            tempArtworks.push(item);
          });
          setArtworks(tempArtworks);
          if (result.data.result.artworks.contents.length < numberOfItem) {
            setLoadMore(false);
          }
        }
      });
    }, [Page]);
  } else if (searchedCollectionId !== undefined) {
    useEffect(() => {
      getArtworkFromCollection().then(result => {
        if (result.data.result.artworks.contents.length > 0) {
          let tempArtworks = [...Artworks];
          result.data.result.artworks.contents.forEach(item => {
            tempArtworks.push(item);
          });
          setArtworks(tempArtworks);
          if (result.data.result.artworks.contents.length < numberOfItem) {
            setLoadMore(false);
          }
        }
      });
    }, [Page]);
  } else if (searchedPressId !== undefined) {
    useEffect(() => {
      getArtworkFromPress().then(result => {
        if (result.data.result.artworks.contents.length > 0) {
          let tempArtworks = [...Artworks];
          result.data.result.artworks.contents.forEach(item => {
            tempArtworks.push(item);
          });
          setArtworks(tempArtworks);
          if (result.data.result.artworks.contents.length < numberOfItem) {
            setLoadMore(false);
          }
        }
      });
    }, [Page]);
  } else if (searchedExhibitionId !== undefined) {
    useEffect(() => {
      getArtworkFromExhibition().then(result => {
        if (result.data.result.artworks.contents.length > 0) {
          let tempArtworks = [...Artworks];
          result.data.result.artworks.contents.forEach(item => {
            tempArtworks.push(item);
          });
          setArtworks(tempArtworks);
          if (result.data.result.artworks.contents.length < numberOfItem) {
            setLoadMore(false);
          }
        }
      })
    }, [Page])
  } else {
    useEffect(() => {
      getArtworks().then(result => {
        if (needFilterbar){ 
          setArtistFilterbar(result.data.result.artistFilters);
          setTypeFilterbar(result.data.result.tagFilters);
        }
        setTitle(result.data.result.artworks.name);
        setneedFilterbar(false);
        console.log(result.data.result)
        if (result.data.result.artworks.contents.length > 0) {
          let tempArtworks = [...Artworks];
          result.data.result.artworks.contents.forEach(item => {
            tempArtworks.push(item);
          });
          setArtworks(tempArtworks);
          if (result.data.result.artworks.contents.length < numberOfItem) {
            setLoadMore(false);
          }
        } else {
          setLoadMore(false);
        }
      });
    }, [ArtistIds, TagIds, Page]);
  }


  // Helper function remove element from an array
  function arrayRemove(arr, value) { 
    return arr.filter(function(ele){ 
        return ele !== value; 
    });
  }

  const handleConfirmArtist = () => {
    if (MarkedArtists.length !== 0) {
      setArtistIds(MarkedArtists);
      setArtworks([]);
      setPage(0);
    }
  }
  const handleConfirmType = () => {
    if (MarkedTypes.length !== 0) {
      setArtworks([]);
      setPage(0);
      setTagIds(MarkedTypes);
    }
  }
  const setMarkedArtist = (value, isMarked) => {
    let tempMarkedArtists = [...MarkedArtists];
    if (isMarked) {
      tempMarkedArtists.push(value);
      setpre_titleArtist('Selected');
      setMarkedArtists(tempMarkedArtists);
    } else {
      tempMarkedArtists = arrayRemove(tempMarkedArtists, value);
      if (tempMarkedArtists.length === 0) {
        setpre_titleArtist('All');
      }
      setMarkedArtists(tempMarkedArtists);
    }
  }
  const setMarkedType = (value, isMarked) => {
    let tempMarkedTypes = [...MarkedTypes];
    if (isMarked) {
      tempMarkedTypes.push(value);
      setpre_titleType('Selected');
      setMarkedTypes(tempMarkedTypes);
    } else {
      tempMarkedTypes = arrayRemove(tempMarkedTypes, value);
      if (tempMarkedTypes.length === 0) {
        setpre_titleType('All');
      }
      setMarkedTypes(tempMarkedTypes);
    }
  }
  const handleResetArtist = () => {
    if (MarkedArtists.length > 0) {
      setMarkedArtists([]);
      setPage(0);
      setpre_titleArtist('All');
      setArtworks([]);
      setLoadMore(true);
      setArtistIds(undefined);
    }
  }
  const handleResetType = () => {
    if (MarkedTypes.length > 0) {
      setMarkedTypes([]);
      setpre_titleType('All');
      setTagIds(undefined);
      setPage(0);
      setArtworks([]);
      setLoadMore(true);
    }
  }
  // 决定title
  if (searchedArtistId !== undefined) {
    if (Artworks.length > 0 && Title === 'Works') {
      setTitle(`Works from ${Artworks[0].artistName}`);
    }
  }
  return (
    <div className="c_page">
      {/* {console.log('render')} */}
      <div className="header_box">
        {
        }
        <div className="common_title">{Title}</div>
        <div className="filter_bar flex_header">
          {(ArtistFilterbar.length !== 0) &&(
            <div className="names_hook">
              <FilterBar 
                info={ArtistFilterbar}
                marked={MarkedArtists}
                setMarked={setMarkedArtist}
                handleReset={handleResetArtist}
                handleConfirm={handleConfirmArtist}
                pre_title={pre_titleArtist}
              />
            </div>
          )}
          {(TypeFilterbar.length !== 0) &&(
            <div className="type_hook">
              <FilterBar 
                info={TypeFilterbar}
                marked={MarkedTypes}
                setMarked={setMarkedType}
                handleReset={handleResetType}
                handleConfirm={handleConfirmType}
                pre_title={pre_titleType}
              />
            </div>
          )}
        </div>
      </div>
      {(Artworks.length > 0) && (
        <CategoryMondrian artworks={Artworks} />
      )}
      <ScrollToTopBtn />
    </div>
  )
};

export default Work;
