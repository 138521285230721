import React, { useState, useEffect, Fragment } from "react";
import Axios from "axios";
import { IconMoreRightArrow } from "../../images/indexSvg";
import { SeparateParagraph } from "../../utilities/utilities";
import { getElementRect } from "../../utilities/utils";
import useWindowWidth from "../../components/useWindowWidth";

import HeaderNav from "../../views_components/HeaderNav_Ver2";
import ExhibitionSlider from "../../views_components/Columns/ExhibitionSlider_Ver2";
import PressesSlider from "../../views_components/Columns/PressesSlider_Ver2";

import WorksSmallSlider from "../../views_components/ver2/work/SmallSlider_Ver2";
import CollectionSmallSlider from "../../views_components/ver2/collection/SmallSlider_Ver2";

import ArtDownload from "../../views_components/ArtistDetail/ArtDownload_Ver2";
import Exhibitions from "../../views_components/Exhibition/CategoryMondrian_Ver2";
import FeaturedWorks from "../../views_components/Works/CategoryMondrian_Ver2";
import FeaturedCollections from "../../views_components/Collection/CategoryMondrian_Ver2";
import FeaturedPresses from "../../views_components/Presses/CategoryMondrianRows_Ver2";

import YoutubeEmbed from "../../views_components/CreateYoutube_Ver2/index";
import { getStatusText } from "../../views_components/ExhibitionDetail/CommonFunc";

import "./ArtistDetail_style.css";

const ArtistDetail = (props) => {
  const {
    match = {},
    // location = {}
  } = props;
  // console.log(location.pathname.split("/")[2])
  const [ArtistInfo, setArtistInfo] = useState([]);
  const [currentType, setCurrentType] = useState(6);
  const [showFixed, setShowFixed] = useState("");
  const [scrollingUp, setScrollingUp] = useState(false);

  const getArtistDetail = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebArtistDetail",
      {
        artistId: match.params.id
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }
  useEffect(() => {
    getArtistDetail().then(result => {
      setArtistInfo(result.data.result);
    });
  }, [match.params.id]);

  const windowWidth = useWindowWidth(100);

  // 定位导航栏
  const initScroll = (pageYOffset) => {
    if (window.IsMobile() && windowWidth < 1024) {
      return;
    }
    // 需要定位的容器
    var navs = document.getElementById("fixedNav");
    if (!navs) return;
    // 启动容器
    var navsRect = getElementRect("startBox");
    var navsRectY = navsRect.y;
    // 结束定位
    var endRect = getElementRect("footerBox");
    var endRectY = endRect.y;
    var endRectDocH = endRect.docH;
    if (endRectY + 200 <= endRectDocH || navsRectY >= 5) {
      setShowFixed("");
      return;
    }
    // 设置定位
    if (navsRectY > 1 || endRectY + 200 > endRectDocH) {
      setShowFixed("fixed");
    }
    var artContent = getElementRect("page0");
    var artVideo = getElementRect("page1");
    var artExhibitions = getElementRect("page2");
    var artFeaturedWorks = getElementRect("page3");
    var artFeaturedCollections = getElementRect("page4");
    var artSelectedPress = getElementRect("page5");
    
    let num = -1;
    let p0y = artContent.y;
    if (p0y >= 0) {
      num = 6;
    }

    const minTop = artVideo.docH / 4;

    let p1y = artVideo.y;
    if (p1y >= 0 && p1y <= minTop) {
      num = 1;
    }

    let p2y = artExhibitions.y;
    if (p2y >= 0 && p2y <= minTop) {
      num = 2;
    }

    let p3y = artFeaturedWorks.y;
    if (p3y >= 0 && p3y <= minTop) {
      num = 3;
    }

    let p4y = artFeaturedCollections.y;
    if (p4y >= 0 && p4y <= minTop) {
      num = 4;
    }

    let p5y = artSelectedPress.y;
    if (p5y >= 0 && p5y <= minTop) {
      num = 5;
    }

    //  && currentType !== num
    if (num > -1) {
      setCurrentType(num);
    }
  };  
  const trackScrolling = () => {
    var pageYOffset = window.pageYOffset || document.documentElement.scrollTop;
    if (pageYOffset > 300) {
      setScrollingUp(!0);
    } else if (pageYOffset <= 0) {
      setScrollingUp(!1);
    }
    initScroll(pageYOffset);
  };  
  
  useEffect(() => {
    window.addEventListener("scroll", trackScrolling);
    return () => {
      window.removeEventListener("scroll", trackScrolling);
    };
  }, [scrollingUp]);

  // 数据成功返回
  if (ArtistInfo.length !== 0) {
    const {
      artist = {},
      artworks = {},
      collections = {},
      exhibitions = {},
      files = {},
      images = {},
      presses = {}
    } = ArtistInfo;
    // 处理 status (Upcoming Ongoing Past)
    exhibitions.contents.forEach((item) => {
      const startDate = item.startDate.iso;
      const endDate = item.endDate.iso;
      item['statusText'] = getStatusText(startDate, endDate);
    });
  
    // 得到 youtubeLink
    const youtubeLink = artist.content.linkVideo;
    const youtubeLinkArr = (youtubeLink && youtubeLink.split(" ")) || [];
    // console.log(youtubeLinkArr)
    const divCtrl = {
      video: youtubeLinkArr.length > 0 ? "block" : "none",
      exhib: exhibitions.contents.length > 0 ? "block" : "none",
      works: artworks.contents.length > 0 ? "block" : "none",
      colle: collections.contents.length > 0 ? "block" : "none",
      press: presses.contents.length > 0 ? "block" : "none",
      isOks: "block",
    };

    const liCtrl = {
      page0: currentType === 6 ? "active" : "",
      page1: currentType === 1 ? "active" : "",
      page2: currentType === 2 ? "active" : "",
      page3: currentType === 3 ? "active" : "",
      page4: currentType === 4 ? "active" : "",
      page5: currentType === 5 ? "active" : "",
    };
    
    const liName = {
      Videos: `Video${youtubeLinkArr.length > 1 ? "s" : ""}`,
      Exhibitions: `Exhibition${exhibitions.contents.length > 1 ? "s" : ""}`,
      Works: `Featured Work${artworks.contents.length > 1 ? "s" : ""}`,
      Collections: `Featured Collection${collections.contents.length > 1 ? "s" : ""}`,
      Presses: `Selected Press${presses.contents.length > 1 ? "es" : ""}`,
    };

    // 滑动页面到指定位置(锚点功能)
    const scrollToAnchor = (anchorName, pageNum) => {
      if (anchorName) {
        let anchorElement = document.getElementById(anchorName);
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: "smooth" });
          setCurrentType(pageNum);
        }
      }
    };

    const fixedNav = `L_nav ${showFixed}`;  
    
    const isPresses = !presses.contents.length;
    const isCollection = !collections.contents.length && isPresses;
    const isArtworks = !artworks.contents.length && isCollection;
    const isExhibition = !exhibitions.contents.length && isArtworks;
    const isLinkVideo = !youtubeLinkArr.length && isExhibition;

    return (
      <React.Fragment>
        {(ArtistInfo.length !== 0) &&
          <React.Fragment>
            <div id="startBox" className="artist_content">
              {/* 左边的Navbar */}
              <div id="fixedNav" className={fixedNav}>
                <div className="D_title">{artist.content.name}</div>
                <ArtDownload name={artist.content.name} images={images.contents} files={files.contents} />
                <ul className="L_ul">
                  <li className={liCtrl.page0} onClick={() => scrollToAnchor("page0", 6)}>Biography</li>
                  <li className={liCtrl.page1} style={{ display: divCtrl.video }} onClick={() => scrollToAnchor("page1", 1)}>{liName.Videos}</li>
                  <li className={liCtrl.page2} style={{ display: divCtrl.exhib }} onClick={() => scrollToAnchor("page2", 2)}>{liName.Exhibitions}</li>
                  <li className={liCtrl.page3} style={{ display: divCtrl.works }} onClick={() => scrollToAnchor("page3", 3)}>{liName.Works}</li>
                  <li className={liCtrl.page4} style={{ display: divCtrl.colle }} onClick={() => scrollToAnchor("page4", 4)}>{liName.Collections}</li>
                  <li className={liCtrl.page5} style={{ display: divCtrl.press }} onClick={() => scrollToAnchor("page5", 5)}>{liName.Presses}</li>
                </ul>
              </div>
              {/* 右边的展示页面 */}
              <div className="R_content">
                <div className="artImage">
                  <img src={artist.content.imageUrls[0].coverImageNormal} alt="Artist" />
                </div>
                <span className="Anchor" id="page0"></span>
                <div className="art_column_item artContent">{SeparateParagraph(artist.content.biography)}</div>
                {isLinkVideo && <div className="art_column_item"></div>}

                {/* Video */}
                <span className="Anchor" id="page1"></span>
                <div
                  className="art_column_item artVideo"
                  style={{ display: divCtrl.video }}
                >
                  {/* <CreateVideoBody videList={youtubeLinkArr} /> */}
                  {youtubeLinkArr.map((item, index)=> {
                    let id = ''
                    if (item.split('=')[1]) {
                      id = item.split('=')[1];
                    } else {
                      const arr = item.split('/');
                      id = arr[arr.length - 1];
                    }
                    return (
                      <div key={index}>
                        {index < 3 &&
                        <YoutubeEmbed embedId={id}/>
                        }
                      </div>
                    )
                  })}
                  
                </div>
                {!isLinkVideo && isExhibition && <div className="art_column_item"></div>}

                {/* Exhibitions */}
                <span className="Anchor" id="page2"></span>
                <div
                  className="art_column_item artExhibitions"
                  style={{ display: divCtrl.exhib }}
                >
                  <Exhibitions
                    exhibitions={exhibitions.contents}
                    realWidth={true}
                  />
                  <div className="icon_more_box">
                    <a href={`http://${window.location.host}${exhibitions.uri}`}>
                      <span>{exhibitions.seeAll}</span>
                      <IconMoreRightArrow />
                    </a>
                  </div>
                </div>
                {!isExhibition && isArtworks && <div className="art_column_item"></div>}

                {/* FeaturedWorks */}
                <span className="Anchor" id="page3"></span>
                <div
                  className="art_column_item artFeaturedWorks"
                  style={{ display: divCtrl.works }}
                >
                  <FeaturedWorks
                    artworks={artworks.contents}
                    realWidth={true}
                  />
                  <div className="icon_more_box">
                    <a href={`http://${window.location.host}${artworks.uri}`}>
                      <span>{artworks.seeAll}</span>
                      <IconMoreRightArrow />
                    </a>
                  </div>
                </div>
                {!isArtworks && isCollection && <div className="art_column_item"></div>}

                {/* FeaturedCollections */}
                <span className="Anchor" id="page4"></span>
                <div
                  className="art_column_item artFeaturedCollections"
                  style={{ display: divCtrl.colle }}
                >
                  <FeaturedCollections
                    collections={collections.contents}
                    realWidth={true}
                  />
                  <div className="icon_more_box">
                    <a href={`http://${window.location.host}${collections.uri}`}>
                      <span>{collections.seeAll}</span>
                      <IconMoreRightArrow />
                    </a>
                  </div>
                </div>
                {!isCollection && isPresses && <div className="art_column_item"></div>}

                {/* Selected Press */}
                <span className="Anchor" id="page5"></span>
                <div
                  className="art_column_item artSelectedPress"
                  style={{ display: divCtrl.press }}
                >
                  <FeaturedPresses
                    presses={presses.contents}
                    realWidth={true}
                  />
                  <div className="icon_more_box">
                    <a href={`http://${window.location.host}${presses.uri}`}>
                      <span>{presses.seeAll}</span>
                      <IconMoreRightArrow />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* 手机页面 */}
            <div className="artist_content_iphone">
              <div className="iphone_colums">
                <div className="D_title">{artist.content.name}</div>
                <ArtDownload name={artist.content.name} images={images.contents} files={files.contents} />
                <div className="artImage">
                  <img src={artist.content.imageUrls[0].coverImageNormal} alt="Artist" />
                </div>
                <div className="Title_iphone">Biography</div>
                <div className="artContent">{SeparateParagraph(artist.content.biography)}</div>
              </div>
              {youtubeLink && (
                <Fragment>
                  <div className="T_line"></div>
                  <div className="iphone_colums">
                    <div className="Title_iphone">{liName.Videos}</div>
                    <div className="artVideo">
                      {/* <CreateVideoBody videList={youtubeLinkArr} /> */}                  
                      {youtubeLinkArr.map((item, index)=> {
                        let id = ''
                        if (item.split('=')[1]) {
                          id = item.split('=')[1];
                        } else {
                          const arr = item.split('/');
                          id = arr[arr.length - 1];
                        }
                        return (
                          <div key={index}>
                            {index < 3 &&
                            <YoutubeEmbed embedId={id}/>
                            }
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </Fragment>
              )}
            
              {exhibitions.contents.length > 0 && (
                <Fragment>
                  <div className="T_line"></div>
                  <div className="iphone_colums"> 
                    <HeaderNav 
                      info={exhibitions} 
                      prev_class="mini_header"
                    />
                    <ExhibitionSlider info={exhibitions} />
                  </div>
               </Fragment>
              )}
              {artworks.contents.length > 0 && (
              <Fragment>
                <div className="T_line"></div>
                <div className="iphone_colums">
                  <WorksSmallSlider
                    artworks={artworks}
                  />
                </div>
              </Fragment>
            )}
            {collections.contents.length > 0 && (
              <Fragment>
                <div className="T_line"></div>
                <div className="iphone_colums">
                  <CollectionSmallSlider
                    collections={collections}
                  />
                </div>
              </Fragment>
            )}
            {presses.contents.length > 0 && (
              <Fragment>
                <div className="T_line"></div>
                <div className="iphone_colums">
                  <HeaderNav
                    info={presses}
                    prev_class="mini_header"
                  />
                  <PressesSlider
                    info={presses}
                  />
                </div>
              </Fragment>
            )}
            </div>
          </React.Fragment>
        }
      </React.Fragment>
    )
  } else {
    return (<div/>)
  }
};


export default ArtistDetail;
