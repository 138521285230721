import React from "react";
import "../SmallSlider.css";
import "../SmallSliderPublic.css";
import { IconMoreRightArrow } from "../../../images/indexSvg";
import { toUrl } from "../../../utilities/utilities";

const SmallSlider = (props) => {
  const {artworks} = props;
  return (
    <div className="public_v2 work_v2">
      <div className="v2Header">
        <div className="v2Title">
          <span>{artworks.name}</span>
        </div>
        <div>
          <p className="v2More">
            <a href = {toUrl(artworks.uri)}>
              <span>{artworks.seeAll}</span>
              <span className="icon">
                <IconMoreRightArrow />
              </span>
            </a>
          </p>
        </div>
        <div className="v2Slide">
          {artworks.contents.map((artwork) => {
            return (
              <a href={toUrl(artwork.uri)} key={artwork.id}>
                <div className="v2Card">
                  <div className="v2Image">
                    <img src={artwork.imageUrls[0].coverImageNormal} alt={artwork.name} />
                  </div>
                </div>
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
};

export default SmallSlider;