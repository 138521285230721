import React, { useState } from "react";
import { IconIns, IconWeChat, IconRedBook } from "../../../images/indexSvg"

const wechatPng = require("../../../images/wechat.png");
// const redbookPng = require("../../../images/redbook.png");

const Contact = () => {
  const [showCode, setShowCode] = useState(0);
  const redbookUrl = "https://www.xiaohongshu.com/user/profile/61dd04c4000000001000740e?xhsshare=CopyLink&appuid=6010fd7f000000000101de9c&apptime=1649773734";
  const useViewCode = (type) => {
    setShowCode(type)
  }

  return (
    <div className="followUs">
      <div className="lg_title">Follow Us</div>
      <div className="lg_content">
        <div className="icon_group pc_group">
          <span
            className="icon_item ins"
            onClick={params =>
              window.location.assign("https://www.instagram.com/galleryallofficial/")
            }
          >
            <IconIns />
          </span>
          <span className="icon_item wechat">
            <IconWeChat />
            <div className="code_item">
              <span>微信公众号</span>
              <img src={wechatPng} alt="wechatPng" />
            </div>
          </span>
          <span className="icon_item redbook">
            <a href={redbookUrl} target="_black">
              <IconRedBook />
            </a>
            {/* <div className="code_item">
              <span>小红书账号</span>
              <img src={redbookPng} alt="redbookPng" />
            </div> */}
          </span>
        </div>

        {/* 微信 */}
        <div className={`footer_code_dailog ${showCode === 1 ? 'active' : ''}`}>
          <div className="mask" onClick={event => useViewCode(0)}></div>
          <div className="footer_dailog_content">
            <img src={wechatPng} alt="wechatPng" />
          </div>
        </div>

        {/* 小红书 */}
        {/* <div className={`footer_code_dailog ${showCode===2?'active':''}`}>
          <div className="mask" onClick={event => useViewCode(0)}></div>
          <div className="footer_dailog_content">
            <img src={redbookPng} alt="redbookPng" />
          </div>
        </div> */}

        <div className="icon_group mobile_group">
          <span
            className="icon_item ins"
            onClick={params => window.location.assign("https://www.instagram.com/galleryallofficial/")}
          >
            <IconIns />
          </span>
          <span className="icon_item wechat" onClick={event => useViewCode(1)}>
            <IconWeChat />
          </span>
          <span className="icon_item redbook">
            <a href={redbookUrl} target="_black">
              <IconRedBook />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Contact;
