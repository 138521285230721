import React from "react";
import SmallSliderArtists from "./SmallSliderArtists_Ver2";

const RenderMobileArtists = (props) => {
  const {
    artists = {}
  } = props;
  return (
    <div className="render_artist_container" id="mb_works_artists">
      {artists.contents.length > 0 && (
        <SmallSliderArtists
          artists={artists}
        />
      )}
    </div>
  );
};

export default RenderMobileArtists;
