import React from "react";
import { Row, Col } from "antd";
import Slider from "react-slick";
import VrDialogDesktop from "./VrDialogDesktop";

const HeroCarouselDesktop = (props) => {
  const {
    contents = [],
    linkVr = ""
  } = props;
  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  return (
    <div className="heroCarousel" id="pc__heroCarousel">
      {contents.length > 0 && (
        <Row>
          <Col md={24} sm={0} xs={0}>
            {linkVr ? <VrDialogDesktop linkVr={linkVr} /> : ""}
            <Slider {...settings}>
              {contents.map((image, index) => {
                return (
                  <div className="carouselImgPc" key={index}>
                    <img
                      src={image.imageUrls[0].imageNormal}
                      alt={`image${index}`}
                      height="600px"
                      width="auto"
                    />
                  </div>
                );
              })}
            </Slider>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default HeroCarouselDesktop;
