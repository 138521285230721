import React, { useState, useEffect } from "react";
import Axios from "axios";
import HeroCarousel from "../../views_components/CollectionDetail/HeroCarousel_Ver2";
import CollectionInfoPc from "../../views_components/CollectionDetail/CollectionInfoPc_Ver2";
import CollectionInfoMobile from "../../views_components/CollectionDetail/CollectionInfoMobile_Ver2";
import CollectionVideo from "../../views_components/CollectionDetail/CollectionVideo_Ver2";
import CollectionArtist from "../../views_components/CollectionDetail/CollectionArtist_Ver2";
import CollectionArtistMobile from "../../views_components/CollectionDetail/CollectionArtistMobile_Ver2";
import CollectionMoreWork from "../../views_components/CollectionDetail/CollectionMoreWork_Ver2";
import OtherCollections from "../../views_components/CollectionDetail/OtherCollections_Ver2";
import "../../scss/CollectionDetail.css";

const CollectionDetail = (props) => {
  const {
    match = {},
  } = props;
  
  const [CollectionInfo, setCollectionInfo] = useState([]);

  const getCollectionDetail = () => {
    return Axios.post(
      "https://api.galleryall.com/parse/functions/getWebCollectionDetail",
      {
        collectionId: match.params.id
      },
      {
        headers: {
          "X-Parse-Application-Id": "N6v7X9jKVmfC5TBXRM80YLwAf5HIp4nx3daqgCTo"
        }
      }
    )
  }
  // 数据请求
  useEffect(() => {
    getCollectionDetail().then(result => {
      setCollectionInfo(result.data.result);
    });
  }, [match.params.id]);
  // 数据成功返回
  if (CollectionInfo.length !== 0) {
    return (
      <React.Fragment>
        <div className="c_DetailWrap">
          <div className="v__Desktop">
            <CollectionInfoPc
              collection={CollectionInfo.collection.content}
              images={CollectionInfo.images.contents}
            />
          </div>
          {/* Mobile */}
          <div className="v__Mobile">
            <CollectionInfoMobile
              collection={CollectionInfo.collection.content}
              images={CollectionInfo.images.contents}
            />
          </div>
          <HeroCarousel contents={CollectionInfo.collection.content.imageUrls} prevClass={"col_heroCarousel"} />
          {/* Overview */}
          {CollectionInfo.collection.content.description &&
            <>
            <div className="collections_divider lh100"></div>
            <div className="collections_overview">
              <h1>Overview</h1>
              <div className="overview_desc">{CollectionInfo.collection.content.description}</div>
            </div>
            </>
          }
          {/* Video */}
          {CollectionInfo.collection.content.linkVideo && (
            <>
              <div className="collections_divider"></div>
              <CollectionVideo linkVideo={CollectionInfo.collection.content.linkVideo} />
            </>
          )}
          {/* Artist */}
          {CollectionInfo.artist.content.length !== 0 &&
          <>
            <div className="v__Desktop">
              <CollectionArtist artist={CollectionInfo.artist}/>
            </div>
            <div className="v__Mobile">
              <CollectionArtistMobile  artist={CollectionInfo.artist}/>
            </div>
          </>
          }
          {/* Works From the Collection */}
          {CollectionInfo.artworks.contents.length !== 0 &&
            <CollectionMoreWork
              artworks={CollectionInfo.artworks}
            />
          }
          {/* Other Collections */}
          {CollectionInfo.otherCollections.contents.length !== 0 &&
          <div>
            <OtherCollections 
              collections={CollectionInfo.otherCollections}
            />
          </div>}
        </div>
      </React.Fragment>
    )
  } else {
    return (<div/>);
  }
};

export default CollectionDetail;
