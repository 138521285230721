import {
  ADD_ARTICLE,
  VIEW_ALL_WORKS_WITH_ARTISTS,
  VIEW_ALL_WORKS_WITH_TYPES,
  VIEW_ALL_ARTISTS_WITH_ARTISTS,
  VIEW_ALL_COLLECTION_WITH_ARTISTS,
  VIEW_ALL_EXHIBITIONS_WITH_YEARS,
  APP_CONTEXT_WORKS,
  APP_CONTEXT_ARTISTS,
  APP_CONTEXT_COLLECTION,
  APP_CONTEXT_EXHIBITION,
  // ver2
  SET_PATHNAME,
  SET_CATEGORIES,
  SET_ONGOING_EXHIBITION,
  SET_ONGOING_COLLECTION,
  Set_Navigation,
} from "../constants/action-types";

// 1 create initial state
const initialState = {
  articles: [],
  // view page
  viewAllWorks: {
    artists: [],
    types: [],
  },
  viewAllArtists: {
    artists: [],
  },
  viewAllCollection: {
    artists: [],
  },
  viewAllExhibition: {
    years: [],
  },
  appContext: {
    works: [],
    artists: [],
    collections: [],
    exhibitions: [],
  },
  // Ver2
  currentNav: "",
  currentPathName: false,
  viewCategories: {
    categories: [],
    tags: [],
  },
  onExhibitions: [],
  onCollections: [],
};

// 2. create the reducer for the store state and action
function rootReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ARTICLE:
      state.articles.push(action.payload);
      break;
    // View all pages update
    case VIEW_ALL_WORKS_WITH_ARTISTS:
      state.viewAllWorks.artists = action.payload;
      break;
    case VIEW_ALL_WORKS_WITH_TYPES:
      state.viewAllWorks.types = action.payload;
      break;
    case VIEW_ALL_ARTISTS_WITH_ARTISTS:
      state.viewAllArtists.artists = action.payload;
      break;
    case VIEW_ALL_COLLECTION_WITH_ARTISTS:
      state.viewAllCollection.artists = action.payload;
      break;
    case VIEW_ALL_EXHIBITIONS_WITH_YEARS:
      state.viewAllExhibition.years = action.payload;
      break;
    // App context update
    case APP_CONTEXT_WORKS:
      state.appContext.works = action.payload;
      break;
    case APP_CONTEXT_ARTISTS:
      state.appContext.artists = action.payload;
      break;
    case APP_CONTEXT_COLLECTION:
      state.appContext.collections = action.payload;
      break;
    case APP_CONTEXT_EXHIBITION:
      state.appContext.exhibitions = action.payload;
      break;
    case SET_PATHNAME:
      state.currentPathName = action.payload;
      break;
    case SET_CATEGORIES:
      state.viewCategories = action.payload;
      break;
    case SET_ONGOING_EXHIBITION:
      state.onExhibitions = action.payload;
      break;
    case SET_ONGOING_COLLECTION:
      state.onCollections = action.payload;
      break;
      case Set_Navigation:
        state.currentNav = action.payload;
        break;
    default:
      break;
  }
  return state;
}

export default rootReducer;
